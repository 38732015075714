import { graphqlVBillClient, graphqlVBillSuggestionsClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillVBillCopyInvoiceMutationVariables,
  IVBillVBillInvoiceQuery,
  IVBillVBillInvoiceQueryVariables,
  IVBillVBillPushInvoiceMutationVariables,
  IVBillVBillSettingsQuery,
  IVBillVBillSettingsQueryVariables,
  IVBillVBillStartApprovalForInvoiceMutationVariables,
  IVBillVBillUpdateInvoiceAndMappingsMutationVariables,
  IVBillVBillUpdateInvoiceCompanyMutationVariables,
} from "generated/sdk.vbill";
import { getSdk as getSdkVBillSuggestions } from "generated/sdk.vbill-suggestions";
import { action, makeObservable, observable, runInAction } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";
import {
  IVBillVBillInvoiceSuggestionsQuery,
  IVBillVBillInvoiceSuggestionsQueryVariables,
} from "../generated/sdk.vbill-suggestions";

const {
  VBillInvoice,
  VBillUpdateInvoiceAndMappings,
  VBillUpdateInvoiceCompany,
  VBillSettings,
  VBillStartApprovalForInvoice,
  VBillCopyInvoice,
  VBillPushInvoice,
} = getSdk(graphqlVBillClient);

const { VBillInvoiceSuggestions } = getSdkVBillSuggestions(graphqlVBillSuggestionsClient);

export class VBillStore extends StoreBase {
  constructor() {
    super();
    makeObservable(this, {
      showVBillRequestErrorToast: observable,
      setShowVBillRequestErrorToast: action,
      incomingLineItemHighlightedId: observable,
      setIncomingLineItemHighlightedId: action,
    });
  }

  showVBillRequestErrorToast: boolean = false;
  incomingLineItemHighlightedId: null | number = null;
  invoice = createObservableContainer<NonNullable<IVBillVBillInvoiceQuery["invoice"]>>();
  settings = createObservableContainer<NonNullable<IVBillVBillSettingsQuery["settings"]>>();
  invoiceSuggestions =
    createObservableContainer<NonNullable<IVBillVBillInvoiceSuggestionsQuery["invoiceSuggestions"]>>();

  async loadInvoice(data: IVBillVBillInvoiceQueryVariables, force?: boolean) {
    return this._preGqlReqWithErrorToast(() =>
      this.invoice.cachedLoad(async () => (await VBillInvoice(data)).invoice, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  loadSettings(data: IVBillVBillSettingsQueryVariables, force?: boolean) {
    this._preGqlReqWithErrorToast(() =>
      this.settings.cachedLoad(async () => (await VBillSettings(data)).settings, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  loadInvoiceSuggestions(data: IVBillVBillInvoiceSuggestionsQueryVariables, force?: boolean) {
    this._preGqlReqWithErrorToast(() =>
      this.invoiceSuggestions.cachedLoad(async () => (await VBillInvoiceSuggestions(data)).invoiceSuggestions, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  async updateInvoiceAndMappings(variables: IVBillVBillUpdateInvoiceAndMappingsMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillUpdateInvoiceAndMappings(variables));

    if (resp?.updateInvoiceAndMappings) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.updateInvoiceAndMappings;
      });

      return resp.updateInvoiceAndMappings;
    }
  }

  async copyInvoice(variables: IVBillVBillCopyInvoiceMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillCopyInvoice(variables));

    if (resp?.copyInvoice) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.copyInvoice;
      });
    }
  }

  async pushInvoice(variables: IVBillVBillPushInvoiceMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillPushInvoice(variables));

    if (resp?.pushInvoice) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.pushInvoice;
      });
    }
  }

  async updateInvoiceCompany(variables: IVBillVBillUpdateInvoiceCompanyMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillUpdateInvoiceCompany(variables));

    if (resp?.updateInvoiceCompany) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.updateInvoiceCompany;
      });

      return resp.updateInvoiceCompany;
    }
  }

  async startApprovalForInvoice(variables: IVBillVBillStartApprovalForInvoiceMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillStartApprovalForInvoice(variables));

    if (resp?.startApprovalForInvoice) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.startApprovalForInvoice;
      });

      return resp.startApprovalForInvoice;
    }
  }

  setIncomingLineItemHighlightedId = (id: number | null) => {
    this.incomingLineItemHighlightedId = id;
  };

  setShowVBillRequestErrorToast = (value: boolean) => {
    this.showVBillRequestErrorToast = value;
  };

  _preGqlReqWithErrorToast = async <T>(promiseFunc: () => Promise<T>) => {
    try {
      return await promiseFunc();
    } catch (_e) {
      this.setShowVBillRequestErrorToast(true);

      return Promise.resolve(undefined);
    }
  };
}
