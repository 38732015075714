import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type IVBillAdditionalMappingInput = {
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  label2?: InputMaybe<Scalars["String"]["input"]>;
  parentLabel?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillStoreIoDataType;
  value: Scalars["String"]["input"];
};

export type IVBillApprovalStatusInput = {
  approvedBy: Array<Scalars["String"]["input"]>;
  declinedBy: Array<Scalars["String"]["input"]>;
  isApproved: Scalars["Boolean"]["input"];
  potentialApprovers: Array<Scalars["String"]["input"]>;
  potentialReviewers: Array<Scalars["String"]["input"]>;
  reviewedBy: Array<Scalars["String"]["input"]>;
};

export enum IVBillBankAccountType {
  Checking = "CHECKING",
  Savings = "SAVINGS",
}

export type IVBillCompanyInput = {
  companyId: Scalars["String"]["input"];
  emailHash: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  orgId: Scalars["String"]["input"];
  orgName: Scalars["String"]["input"];
  organization?: InputMaybe<IVBillOrganizationInput>;
};

export type IVBillErGlMappingInput = {
  fields: IVBillErInfoInput;
  mappings: IVBillErInfoInput;
};

export type IVBillErInfoCustomInput = {
  fields: Array<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
};

export type IVBillErInfoInput = {
  custom?: InputMaybe<IVBillErInfoCustomInput>;
  required: Array<Scalars["String"]["input"]>;
};

export type IVBillErInvoiceInput = {
  fields: IVBillErInfoInput;
  mappings: IVBillErInfoInput;
};

export type IVBillErLineItemInput = {
  fields: IVBillErInfoInput;
  glMapping: IVBillErGlMappingInput;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillErrorInput = {
  codes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message: Scalars["String"]["input"];
  type: IVBillGqlErrorTypes;
};

export type IVBillExpenseAdditionalField = {
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  type: Scalars["String"]["input"];
  typeOfValue: IVBillTypeOfValue;
  value: Scalars["String"]["input"];
};

export type IVBillExpensePayeeInput = {
  addressCity?: InputMaybe<Scalars["String"]["input"]>;
  addressPostalCode?: InputMaybe<Scalars["String"]["input"]>;
  addressState?: InputMaybe<Scalars["String"]["input"]>;
  addressStreet?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountType?: InputMaybe<IVBillBankAccountType>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  bankRoutingNumber?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IVBillExpensePaymentMethod {
  AchDirect = "ach_direct",
  Print = "print",
}

export type IVBillExpenseReportInput = {
  additionalFields?: InputMaybe<Array<IVBillExpenseAdditionalField>>;
  amount: Scalars["String"]["input"];
  attachmentKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  expenseDate: Scalars["String"]["input"];
  payee: IVBillExpensePayeeInput;
  paymentMethod: IVBillExpensePaymentMethod;
  reasonForExpense?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IVBillExpenseType {
  Bill = "BILL",
  Reimbursement = "REIMBURSEMENT",
}

export type IVBillFileInfoInput = {
  arrivedAt?: InputMaybe<Scalars["String"]["input"]>;
  cloudS3Key?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  fileOcrState: IVBillKFileOcrState;
  id: Scalars["Int"]["input"];
  invoiceId?: InputMaybe<Scalars["Float"]["input"]>;
  isEncrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  ocrText?: InputMaybe<Scalars["String"]["input"]>;
  ocrTextItems?: InputMaybe<Array<IVBillTextItemInput>>;
  origText?: InputMaybe<Scalars["String"]["input"]>;
  origTextItems?: InputMaybe<Array<IVBillTextItemInput>>;
  pageCount?: InputMaybe<Scalars["Float"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  parentId?: InputMaybe<Scalars["Int"]["input"]>;
  parentRelation?: InputMaybe<IVBillKFileParentRelation>;
  parents?: InputMaybe<Array<Scalars["String"]["input"]>>;
  processType?: InputMaybe<IVBillKFileProcessType>;
  sha1sum?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IVBillFileMetadataInput = {
  AOCR?: InputMaybe<IVBillInvoiceSuggestionsMetadataInput>;
  GDAI?: InputMaybe<IVBillInvoiceSuggestionsMetadataInput>;
  OPENAI?: InputMaybe<IVBillInvoiceSuggestionsMetadataInput>;
};

export enum IVBillGqlErrorTypes {
  Generic = "GENERIC",
  NotFound = "NOT_FOUND",
  Unauthenticated = "UNAUTHENTICATED",
  Unauthorized = "UNAUTHORIZED",
  Validation = "VALIDATION",
}

export enum IVBillIShtarActionLogMetaContentBlockMessageType {
  Message = "MESSAGE",
  Table = "TABLE",
}

export enum IVBillIShtarActionLogMetaContentBlockTableIcon {
  IconRight = "ICON_RIGHT",
}

export enum IVBillIShtarActionLogObjectType {
  Account = "account",
  Bankaccount = "bankaccount",
  Bill = "bill",
  Billbatch = "billbatch",
  Check = "check",
  Checkbatch = "checkbatch",
  Company = "company",
  Department = "department",
  Organization = "organization",
  Payment = "payment",
}

export type IVBillInvoiceActionLogChangedByInput = {
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationUserId?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillInvoiceActionLogInput = {
  action: Scalars["String"]["input"];
  changedBy: IVBillInvoiceActionLogChangedByInput;
  createdAt: Scalars["String"]["input"];
  id: Scalars["Float"]["input"];
  initiator: Scalars["String"]["input"];
  key: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
  meta?: InputMaybe<IVBillInvoiceActionLogMetaInput>;
  path?: InputMaybe<Array<IVBillInvoiceActionLogPathOrRelatedInput>>;
  related?: InputMaybe<Array<IVBillInvoiceActionLogPathOrRelatedInput>>;
  scope: Scalars["String"]["input"];
  type: IVBillIShtarActionLogObjectType;
};

export type IVBillInvoiceActionLogMetaAdditionalContentBlockInput = {
  bindings?: InputMaybe<Array<IVBillInvoiceActionLogMetaTemplateBindingsInput>>;
  columns?: InputMaybe<Array<IVBillInvoiceActionLogMetaContentBlockTableCellInput>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  related?: InputMaybe<Array<IVBillInvoiceActionLogMetaContentBlockMessageRelatedInput>>;
  rows?: InputMaybe<Array<IVBillInvoiceActionLogMetaContentBlockTableRowInput>>;
  template?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillIShtarActionLogMetaContentBlockMessageType;
};

export type IVBillInvoiceActionLogMetaContentBlockMessageInput = {
  bindings?: InputMaybe<Array<IVBillInvoiceActionLogMetaTemplateBindingsInput>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  related?: InputMaybe<Array<IVBillInvoiceActionLogMetaContentBlockMessageRelatedInput>>;
  template?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillIShtarActionLogMetaContentBlockMessageType;
};

export type IVBillInvoiceActionLogMetaContentBlockMessageRelatedInput = {
  key: Scalars["String"]["input"];
  related: IVBillInvoiceActionLogPathOrRelatedInput;
};

export type IVBillInvoiceActionLogMetaContentBlockTableCellInput = {
  bindings?: InputMaybe<Array<IVBillInvoiceActionLogMetaTemplateBindingsInput>>;
  icon?: InputMaybe<IVBillIShtarActionLogMetaContentBlockTableIcon>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  template?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillInvoiceActionLogMetaContentBlockTableRowInput = {
  cells?: InputMaybe<Array<IVBillInvoiceActionLogMetaContentBlockTableCellInput>>;
};

export type IVBillInvoiceActionLogMetaInput = {
  additionalContent?: InputMaybe<Array<IVBillInvoiceActionLogMetaAdditionalContentBlockInput>>;
  content?: InputMaybe<IVBillInvoiceActionLogMetaContentBlockMessageInput>;
};

export type IVBillInvoiceActionLogMetaTemplateBindingsInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type IVBillInvoiceActionLogPathOrRelatedInput = {
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillIShtarActionLogObjectType;
};

export enum IVBillInvoiceActionRequiredStatus {
  IntegrationSyncError = "INTEGRATION_SYNC_ERROR",
  None = "NONE",
  PendingApprovalExpired = "PENDING_APPROVAL_EXPIRED",
  StartApprovalError = "START_APPROVAL_ERROR",
}

export type IVBillInvoiceAmountInBatchDetailInput = {
  batchAmountForInvoice: Scalars["String"]["input"];
  batchId: Scalars["Int"]["input"];
  invoiceId: Scalars["Int"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillInvoiceAmountsInput = {
  amount?: InputMaybe<Scalars["String"]["input"]>;
  amountFreeToPay?: InputMaybe<Scalars["String"]["input"]>;
  amountInBatches?: InputMaybe<Scalars["String"]["input"]>;
  amountInBatchesBreakdown?: InputMaybe<Array<IVBillInvoiceAmountInBatchDetailInput>>;
  amountInPayments?: InputMaybe<Scalars["String"]["input"]>;
  paymentsStats?: InputMaybe<IVBillInvoicePaymentsStatsInput>;
};

export type IVBillInvoiceApprovalInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  invoiceId: Scalars["Int"]["input"];
  orgUserId: Scalars["String"]["input"];
  status: IVBillInvoiceApprovalStatus;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IVBillInvoiceApprovalStatus {
  Revoked = "REVOKED",
  Valid = "VALID",
}

export type IVBillInvoiceFileInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  fileIsEncrypted?: InputMaybe<Scalars["Boolean"]["input"]>;
  fileOcrState?: InputMaybe<IVBillKFileOcrState>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  invoiceId?: InputMaybe<Scalars["Int"]["input"]>;
  isInvoiceMainFile?: InputMaybe<Scalars["Boolean"]["input"]>;
  metadata?: InputMaybe<IVBillFileMetadataInput>;
  movedToInvoice?: InputMaybe<IVBillInvoiceInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pageCount?: InputMaybe<Scalars["Int"]["input"]>;
  pages?: InputMaybe<Array<IVBillPageFileInput>>;
  sha1sum?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  storageKey?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IVBillInvoiceIncomingStatus {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Deleted = "DELETED",
  Duplicate = "DUPLICATE",
  History = "HISTORY",
  Mapped = "MAPPED",
  New = "NEW",
  PendingApproval = "PENDING_APPROVAL",
  Queued = "QUEUED",
}

export type IVBillInvoiceInput = {
  actionRequiredStatus?: InputMaybe<IVBillInvoiceActionRequiredStatus>;
  actions?: InputMaybe<Array<IVBillInvoiceActionLogInput>>;
  additionalMappings?: InputMaybe<Array<IVBillAdditionalMappingInput>>;
  amount?: InputMaybe<Scalars["String"]["input"]>;
  amountToBePaid?: InputMaybe<Scalars["String"]["input"]>;
  amounts?: InputMaybe<IVBillInvoiceAmountsInput>;
  approvalCount?: InputMaybe<Scalars["Int"]["input"]>;
  approvalStatus?: InputMaybe<IVBillApprovalStatusInput>;
  approvals?: InputMaybe<Array<IVBillInvoiceApprovalInput>>;
  canBePaid?: InputMaybe<Scalars["Boolean"]["input"]>;
  company?: InputMaybe<IVBillCompanyInput>;
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  contactExternalRef?: InputMaybe<Scalars["String"]["input"]>;
  coreOrgId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  displayError?: InputMaybe<IVBillErrorInput>;
  emailAttachmentUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  emailBodyText?: InputMaybe<Scalars["String"]["input"]>;
  emailDate?: InputMaybe<Scalars["String"]["input"]>;
  emailSubject?: InputMaybe<Scalars["String"]["input"]>;
  emailUrl?: InputMaybe<Scalars["String"]["input"]>;
  expenseReport?: InputMaybe<IVBillExpenseReportInput>;
  expenseType?: InputMaybe<IVBillExpenseType>;
  externalRef?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<IVBillInvoiceFileInput>>;
  filesOcrState?: InputMaybe<IVBillKInvoiceFilesOcrState>;
  hasLineItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  hashedId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  initialSenderEmail?: InputMaybe<Scalars["String"]["input"]>;
  initialSenderName?: InputMaybe<Scalars["String"]["input"]>;
  integrationRef?: InputMaybe<Scalars["String"]["input"]>;
  integrationStatus?: InputMaybe<IVBillInvoiceIntegrationStatus>;
  invoiceDate?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateDue?: InputMaybe<Scalars["String"]["input"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  isPotentialDuplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  lineItems?: InputMaybe<Array<IVBillLineItemInput>>;
  mainFile?: InputMaybe<IVBillFileInfoInput>;
  mainFileId?: InputMaybe<Scalars["String"]["input"]>;
  nextUserActions?: InputMaybe<Array<IVBillInvoiceUserActionInput>>;
  payments?: InputMaybe<Array<IVBillInvoicePaymentInput>>;
  pushStatus?: InputMaybe<IVBillInvoicePushStatus>;
  recipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmail?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  recipientName?: InputMaybe<Scalars["String"]["input"]>;
  recipientPhone?: InputMaybe<Scalars["String"]["input"]>;
  senderAddress?: InputMaybe<Scalars["String"]["input"]>;
  senderEmail?: InputMaybe<Scalars["String"]["input"]>;
  senderEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  senderName?: InputMaybe<Scalars["String"]["input"]>;
  senderPhone?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<IVBillInvoiceSource>;
  status?: InputMaybe<IVBillInvoiceIncomingStatus>;
  statusFlags?: InputMaybe<IVBillInvoiceStatusFlagsInput>;
  suggestedMainFileId?: InputMaybe<Scalars["String"]["input"]>;
  syncError?: InputMaybe<IVBillErrorInput>;
  validationErrors?: InputMaybe<IVBillValidationErrorsInput>;
};

export enum IVBillInvoiceIntegrationStatus {
  InvoiceApproved = "INVOICE_APPROVED",
  InvoiceDraft = "INVOICE_DRAFT",
  InvoicePaid = "INVOICE_PAID",
  InvoicePartialPaid = "INVOICE_PARTIAL_PAID",
  InvoicePaymentPending = "INVOICE_PAYMENT_PENDING",
  InvoiceVoided = "INVOICE_VOIDED",
}

export type IVBillInvoiceLineItemMetaInput = {
  amount?: InputMaybe<IVBillNumberWithConfidenceInput>;
  confidence: Scalars["Float"]["input"];
  description?: InputMaybe<IVBillStringWithConfidenceInput>;
  qty?: InputMaybe<IVBillNumberWithConfidenceInput>;
  rate?: InputMaybe<IVBillNumberWithConfidenceInput>;
  type?: InputMaybe<IVBillLineItemType>;
  uid: Scalars["String"]["input"];
  um?: InputMaybe<IVBillStringWithConfidenceInput>;
};

export type IVBillInvoicePaymentInput = {
  batchId?: InputMaybe<Scalars["Int"]["input"]>;
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId?: InputMaybe<Scalars["Int"]["input"]>;
  invoiceRelationType?: InputMaybe<Scalars["String"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  paymentAmount?: InputMaybe<Scalars["String"]["input"]>;
  paymentDate?: InputMaybe<Scalars["String"]["input"]>;
  paymentError?: InputMaybe<IVBillErrorInput>;
  paymentExternalRef?: InputMaybe<Scalars["String"]["input"]>;
  paymentExternalUrl?: InputMaybe<Scalars["String"]["input"]>;
  paymentFromGlAccount?: InputMaybe<IVBillPaymentAccountInput>;
  paymentId?: InputMaybe<Scalars["Int"]["input"]>;
  paymentIntegrationStatus?: InputMaybe<IVBillPaymentIntegrationStatus>;
  paymentPushStatus?: InputMaybe<IVBillPaymentPushStatus>;
  paymentStatus?: InputMaybe<IVBillPaymentStatus>;
  paymentToVendorAccount?: InputMaybe<IVBillPaymentAccountInput>;
  submittedByUserAccount?: InputMaybe<IVBillPaymentSubmittedByUserAccountInput>;
  vCheckId?: InputMaybe<Scalars["String"]["input"]>;
  vCheckNumber?: InputMaybe<Scalars["String"]["input"]>;
  vCheckPaymentType?: InputMaybe<IVBillVCheckPaymentType>;
  vCheckState?: InputMaybe<IVBillVCheckState>;
};

export type IVBillInvoicePaymentsStatsInput = {
  failed?: InputMaybe<IVBillPaymentsStatsInput>;
  pending?: InputMaybe<IVBillPaymentsStatsInput>;
  successful?: InputMaybe<IVBillPaymentsStatsInput>;
};

export enum IVBillInvoicePushStatus {
  Done = "DONE",
  Error = "ERROR",
  Queued = "QUEUED",
}

export enum IVBillInvoiceSource {
  Email = "email",
  GuestExpense = "guestExpense",
  InternalImport = "internalImport",
  InternalIntegrationImport = "internalIntegrationImport",
  UserUpload = "userUpload",
}

export type IVBillInvoiceStatusFlagsInput = {
  canBeEdited: Scalars["Boolean"]["input"];
  isCompanyMappingComplete: Scalars["Boolean"]["input"];
  isMappingComplete: Scalars["Boolean"]["input"];
  isOCRComplete: Scalars["Boolean"]["input"];
};

export type IVBillInvoiceSuggestionsMetadataInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  invoiceDate?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateDue?: InputMaybe<Scalars["String"]["input"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  lineItems: Array<IVBillInvoiceLineItemMetaInput>;
  recipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmail?: InputMaybe<Scalars["String"]["input"]>;
  recipientName?: InputMaybe<Scalars["String"]["input"]>;
  recipientPhone?: InputMaybe<Scalars["String"]["input"]>;
  senderAddress?: InputMaybe<Scalars["String"]["input"]>;
  senderEmail?: InputMaybe<Scalars["String"]["input"]>;
  senderName?: InputMaybe<Scalars["String"]["input"]>;
  senderPhone?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillInvoiceSuggestionsOutput = {
  __typename?: "InvoiceSuggestionsOutput";
  suggestions: Array<IVBillSuggestionOutput>;
};

export type IVBillInvoiceUserActionInput = {
  description: Scalars["String"]["input"];
  displayName: Scalars["String"]["input"];
  showIn: IVBillInvoiceUserActionShowIn;
  type: IVBillInvoiceUserActionType;
};

export enum IVBillInvoiceUserActionShowIn {
  InvoiceDetails = "INVOICE_DETAILS",
  InvoiceListing = "INVOICE_LISTING",
}

export enum IVBillInvoiceUserActionType {
  Approve = "APPROVE",
  CompleteCoding = "COMPLETE_CODING",
  CompleteOcr = "COMPLETE_OCR",
  DelegateApproval = "DELEGATE_APPROVAL",
  DeleteInvoice = "DELETE_INVOICE",
  Reject = "REJECT",
  SaveDraft = "SAVE_DRAFT",
  StartApproval = "START_APPROVAL",
  StartCoding = "START_CODING",
  StartOcr = "START_OCR",
}

export enum IVBillKAutoocrContentProcessor {
  Autoocr = "AUTOOCR",
  Googledocumentai = "GOOGLEDOCUMENTAI",
  Graphicsmagick = "GRAPHICSMAGICK",
  Pdfjs = "PDFJS",
  Pdflib = "PDFLIB",
  Tesseract = "TESSERACT",
  Unknown = "UNKNOWN",
}

export enum IVBillKFileOcrState {
  OcrDoneOk = "OCR_DONE_OK",
  OcrError = "OCR_ERROR",
  OcrStartTriggered = "OCR_START_TRIGGERED",
  PendingStartProcessing = "PENDING_START_PROCESSING",
  Unknown = "UNKNOWN",
}

export enum IVBillKFileParentRelation {
  Attachment = "ATTACHMENT",
  Content = "CONTENT",
  None = "NONE",
  Page = "PAGE",
  Transform = "TRANSFORM",
  Version = "VERSION",
}

export enum IVBillKFileProcessType {
  Bind = "BIND",
  Extract = "EXTRACT",
  None = "NONE",
  Processed = "PROCESSED",
  Unknown = "UNKNOWN",
}

export enum IVBillKInvoiceFilesOcrState {
  OcrProcessingDone = "OCR_PROCESSING_DONE",
  OcrProcessingDoneError = "OCR_PROCESSING_DONE_ERROR",
  OcrProcessingDoneOk = "OCR_PROCESSING_DONE_OK",
  Pending = "PENDING",
}

export type IVBillLineItemGlMappingInput = {
  additionalMapping?: InputMaybe<Array<IVBillAdditionalMappingInput>>;
  glAccountExternalRef?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Float"]["input"]>;
  lineItemId?: InputMaybe<Scalars["Float"]["input"]>;
  qty?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IVBillLineItemInput = {
  amount: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  glMappings?: InputMaybe<Array<IVBillLineItemGlMappingInput>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  invoiceId?: InputMaybe<Scalars["Int"]["input"]>;
  orderIndex?: InputMaybe<Scalars["Int"]["input"]>;
  qty?: InputMaybe<Scalars["String"]["input"]>;
  rate?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<IVBillLineItemType>;
  uid?: InputMaybe<Scalars["String"]["input"]>;
  um?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IVBillLineItemType {
  Freight = "FREIGHT",
  Normal = "NORMAL",
  Tax = "TAX",
}

export type IVBillMutation = {
  __typename?: "Mutation";
  pingMutation: IVBillPong;
};

export type IVBillMutationPingMutationArgs = {
  input: IVBillPing;
};

export type IVBillNullableInvoiceInput = {
  additionalMapping?: InputMaybe<Array<IVBillAdditionalMappingInput>>;
  amount?: InputMaybe<Scalars["String"]["input"]>;
  emailAttachmentUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  emailHash?: InputMaybe<Scalars["String"]["input"]>;
  emailUrl?: InputMaybe<Scalars["String"]["input"]>;
  hasLineItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  invoiceDate?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateDue?: InputMaybe<Scalars["String"]["input"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  lineItems?: InputMaybe<Array<IVBillLineItemInput>>;
  mainFileId?: InputMaybe<Scalars["Float"]["input"]>;
  recipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmail?: InputMaybe<Scalars["String"]["input"]>;
  recipientName?: InputMaybe<Scalars["String"]["input"]>;
  recipientPhone?: InputMaybe<Scalars["String"]["input"]>;
  senderAddress?: InputMaybe<Scalars["String"]["input"]>;
  senderEmail?: InputMaybe<Scalars["String"]["input"]>;
  senderName?: InputMaybe<Scalars["String"]["input"]>;
  senderPhone?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillNumberWithConfidenceInput = {
  value: Scalars["Float"]["input"];
};

export type IVBillOrganizationInput = {
  coreOrgId: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  organizationEmailHash: Scalars["String"]["input"];
  organizationName: Scalars["String"]["input"];
};

export type IVBillPageFileInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  movedToInvoiceId?: InputMaybe<Scalars["Int"]["input"]>;
  ocrText?: InputMaybe<Scalars["String"]["input"]>;
  pageCount?: InputMaybe<Scalars["Int"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IVBillPaymentAccountInput = {
  coreCompanyId?: InputMaybe<Scalars["String"]["input"]>;
  coreOrgId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  label2?: InputMaybe<Scalars["String"]["input"]>;
  parentLabel?: InputMaybe<Scalars["String"]["input"]>;
  settingsKey: Scalars["String"]["input"];
  source?: InputMaybe<Scalars["String"]["input"]>;
  storeType: IVBillStoreIoDataType;
};

export enum IVBillPaymentIntegrationStatus {
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Pending = "PENDING",
  Unknown = "UNKNOWN",
  Voided = "VOIDED",
}

export enum IVBillPaymentPushStatus {
  Pending = "PENDING",
  SubmittedError = "SUBMITTED_ERROR",
  SubmittedOk = "SUBMITTED_OK",
}

export enum IVBillPaymentStatus {
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Error = "ERROR",
  Pending = "PENDING",
  Voided = "VOIDED",
}

export type IVBillPaymentSubmittedByUserAccountInput = {
  organizationUserId: Scalars["String"]["input"];
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
  userName?: InputMaybe<Scalars["String"]["input"]>;
  userPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillPaymentsStatsInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  maxDate?: InputMaybe<Scalars["String"]["input"]>;
  minDate?: InputMaybe<Scalars["String"]["input"]>;
  sum?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillQuery = {
  __typename?: "Query";
  invoiceSuggestions: IVBillInvoiceSuggestionsOutput;
  pingQuery: IVBillPong;
  /** @deprecated use invoiceSuggestions instead */
  suggestions: IVBillInvoiceSuggestionsOutput;
};

export type IVBillQueryInvoiceSuggestionsArgs = {
  invoiceData?: InputMaybe<IVBillInvoiceInput>;
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillQuerySuggestionsArgs = {
  input: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
};

export enum IVBillStoreIoDataType {
  Contact = "contact",
  Employee = "employee",
  FakeCategory = "fakeCategory",
  FakeLocation = "fakeLocation",
  GlAccount = "glAccount",
  PeoplesoftBankAccount = "peoplesoftBankAccount",
  PeoplesoftCustomField1 = "peoplesoftCustomField1",
  PeoplesoftCustomField2 = "peoplesoftCustomField2",
  PeoplesoftCustomField3 = "peoplesoftCustomField3",
  PeoplesoftCustomField4 = "peoplesoftCustomField4",
  PeoplesoftPayGroup = "peoplesoftPayGroup",
  PeoplesoftPayTerm = "peoplesoftPayTerm",
  QbdCustomer = "qbdCustomer",
  QbdSalesTerm = "qbdSalesTerm",
  QboCustomer = "qboCustomer",
  QboProduct = "qboProduct",
  QboSalesTerm = "qboSalesTerm",
  SageAccountAllocation = "sageAccountAllocation",
  SageAllocation = "sageAllocation",
  SageApTerm = "sageApTerm",
  SageBankAccount = "sageBankAccount",
  SageDepartment = "sageDepartment",
  SageEntity = "sageEntity",
  SageGlAllocation = "sageGlAllocation",
  SageLocation = "sageLocation",
  SagePaymentMethod = "sagePaymentMethod",
  SageUserInfo = "sageUserInfo",
}

export type IVBillStringWithConfidenceInput = {
  value: Scalars["String"]["input"];
};

export type IVBillSuggestionIndicatorOutput = {
  __typename?: "SuggestionIndicatorOutput";
  confidence: Scalars["Float"]["output"];
  label: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillSuggestionOutput = {
  __typename?: "SuggestionOutput";
  confidence: Scalars["Float"]["output"];
  indicators: Array<IVBillSuggestionIndicatorOutput>;
  key: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type IVBillTextItemBBoxInput = {
  x0: Scalars["Float"]["input"];
  x1: Scalars["Float"]["input"];
  y0: Scalars["Float"]["input"];
  y1: Scalars["Float"]["input"];
};

export type IVBillTextItemBaseLineInput = {
  hasBaseLine: Scalars["Boolean"]["input"];
  x0: Scalars["Float"]["input"];
  x1: Scalars["Float"]["input"];
  y0: Scalars["Float"]["input"];
  y1: Scalars["Float"]["input"];
};

export type IVBillTextItemInput = {
  bBox?: InputMaybe<IVBillTextItemBBoxInput>;
  baseLine?: InputMaybe<IVBillTextItemBaseLineInput>;
  direction?: InputMaybe<Scalars["String"]["input"]>;
  fontName?: InputMaybe<Scalars["String"]["input"]>;
  fontSize?: InputMaybe<Scalars["Float"]["input"]>;
  hasEOL?: InputMaybe<Scalars["Boolean"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  isBold?: InputMaybe<Scalars["Boolean"]["input"]>;
  isItalic?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMonospace?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNumeric?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSerif?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSmallcaps?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUnderlined?: InputMaybe<Scalars["Boolean"]["input"]>;
  processor?: InputMaybe<IVBillKAutoocrContentProcessor>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  transform?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum IVBillTypeOfValue {
  Boolean = "boolean",
  Datetime = "datetime",
  Json = "json",
  Numeric = "numeric",
  Text = "text",
}

export enum IVBillVCheckPaymentType {
  Ach = "ACH",
  Card = "CARD",
  Print = "PRINT",
}

export enum IVBillVCheckState {
  Blocked = "BLOCKED",
  Cancelled = "CANCELLED",
  Deleted = "DELETED",
  Deposited = "DEPOSITED",
  PendingSignature = "PENDING_SIGNATURE",
  Processing = "PROCESSING",
  Signed = "SIGNED",
}

export type IVBillValidationErrorsInput = {
  invoice: IVBillErInvoiceInput;
  lineItems: Array<IVBillErLineItemInput>;
};

export type IVBillPing = {
  ping?: InputMaybe<Scalars["Boolean"]["input"]>;
  pong?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IVBillPong = {
  __typename?: "pong";
  ping?: Maybe<Scalars["Boolean"]["output"]>;
  pong?: Maybe<Scalars["Boolean"]["output"]>;
};

export type IVBillPingMutationMutationVariables = Exact<{
  input: IVBillPing;
}>;

export type IVBillPingMutationMutation = {
  __typename?: "Mutation";
  pingMutation: { __typename?: "pong"; ping?: boolean | null; pong?: boolean | null };
};

export type IVBillPingQueryQueryVariables = Exact<{ [key: string]: never }>;

export type IVBillPingQueryQuery = {
  __typename?: "Query";
  pingQuery: { __typename?: "pong"; ping?: boolean | null; pong?: boolean | null };
};

export type IVBillVBillInvoiceSuggestionsQueryVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
  invoiceData?: InputMaybe<IVBillInvoiceInput>;
}>;

export type IVBillVBillInvoiceSuggestionsQuery = {
  __typename?: "Query";
  invoiceSuggestions: {
    __typename?: "InvoiceSuggestionsOutput";
    suggestions: Array<{
      __typename?: "SuggestionOutput";
      type: string;
      key: string;
      value: string;
      confidence: number;
      indicators: Array<{
        __typename?: "SuggestionIndicatorOutput";
        label: string;
        confidence: number;
        value?: string | null;
      }>;
    }>;
  };
};

export type IVBillVBillSuggestionsQueryVariables = Exact<{
  input: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillSuggestionsQuery = {
  __typename?: "Query";
  suggestions: {
    __typename?: "InvoiceSuggestionsOutput";
    suggestions: Array<{
      __typename?: "SuggestionOutput";
      type: string;
      key: string;
      indicators: Array<{
        __typename?: "SuggestionIndicatorOutput";
        label: string;
        confidence: number;
        value?: string | null;
      }>;
    }>;
  };
};

export const PingMutationDocument = gql`
  mutation PingMutation($input: ping!) {
    pingMutation(input: $input) {
      ping
      pong
    }
  }
`;
export const PingQueryDocument = gql`
  query PingQuery {
    pingQuery {
      ping
      pong
    }
  }
`;
export const VBillInvoiceSuggestionsDocument = gql`
  query VBillInvoiceSuggestions($invoiceId: Int!, $invoiceData: InvoiceInput) {
    invoiceSuggestions(invoiceId: $invoiceId, invoiceData: $invoiceData) {
      suggestions {
        type
        key
        value
        confidence
        indicators {
          label
          confidence
          value
        }
      }
    }
  }
`;
export const VBillSuggestionsDocument = gql`
  query VBillSuggestions($input: NullableInvoiceInput!, $invoiceId: Int!) {
    suggestions(input: $input, invoiceId: $invoiceId) {
      suggestions {
        type
        key
        indicators {
          label
          confidence
          value
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    PingMutation(
      variables: IVBillPingMutationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillPingMutationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillPingMutationMutation>(PingMutationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "PingMutation",
        "mutation",
      );
    },
    PingQuery(
      variables?: IVBillPingQueryQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillPingQueryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillPingQueryQuery>(PingQueryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "PingQuery",
        "query",
      );
    },
    VBillInvoiceSuggestions(
      variables: IVBillVBillInvoiceSuggestionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillInvoiceSuggestionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillInvoiceSuggestionsQuery>(VBillInvoiceSuggestionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillInvoiceSuggestions",
        "query",
      );
    },
    VBillSuggestions(
      variables: IVBillVBillSuggestionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillSuggestionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillSuggestionsQuery>(VBillSuggestionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillSuggestions",
        "query",
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
