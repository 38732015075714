import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any };
};

export type IVBillAbstractExpenseAdditionalField = {
  __typename?: "AbstractExpenseAdditionalField";
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  type: Scalars["String"]["output"];
  typeOfValue: IVBillTypeOfValue;
  value: Scalars["String"]["output"];
};

export type IVBillAbstractExpensePayeeOutput = {
  __typename?: "AbstractExpensePayeeOutput";
  addressCity?: Maybe<Scalars["String"]["output"]>;
  addressPostalCode?: Maybe<Scalars["String"]["output"]>;
  addressState?: Maybe<Scalars["String"]["output"]>;
  addressStreet?: Maybe<Scalars["String"]["output"]>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  bankAccountType?: Maybe<IVBillBankAccountType>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  bankRoutingNumber?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillAbstractExpenseReportOutput = {
  __typename?: "AbstractExpenseReportOutput";
  additionalFields?: Maybe<Array<IVBillAbstractExpenseAdditionalField>>;
  amount: Scalars["String"]["output"];
  attachmentKeys?: Maybe<Array<Scalars["String"]["output"]>>;
  employeeId?: Maybe<Scalars["String"]["output"]>;
  expenseDate: Scalars["String"]["output"];
  payee: IVBillAbstractExpensePayeeOutput;
  paymentMethod: IVBillExpensePaymentMethod;
  reasonForExpense?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillAbstractPageFileOutput = {
  __typename?: "AbstractPageFileOutput";
  contentType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  movedToInvoiceId?: Maybe<Scalars["Int"]["output"]>;
  ocrText?: Maybe<Scalars["String"]["output"]>;
  pageCount?: Maybe<Scalars["Int"]["output"]>;
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
};

export type IVBillAdditionalMappingInput = {
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  label2?: InputMaybe<Scalars["String"]["input"]>;
  parentLabel?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillStoreIoDataType;
  value: Scalars["String"]["input"];
};

export type IVBillAdditionalMappingOutput = {
  __typename?: "AdditionalMappingOutput";
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  label2?: Maybe<Scalars["String"]["output"]>;
  parentLabel?: Maybe<Scalars["String"]["output"]>;
  type: IVBillStoreIoDataType;
  value: Scalars["String"]["output"];
};

export type IVBillAdditionalMappingSchema = {
  __typename?: "AdditionalMappingSchema";
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  label2?: Maybe<Scalars["String"]["output"]>;
  parentLabel?: Maybe<Scalars["String"]["output"]>;
  type: IVBillStoreIoDataType;
  value: Scalars["String"]["output"];
};

export type IVBillApprovalStatusSchema = {
  __typename?: "ApprovalStatusSchema";
  approvedBy: Array<Scalars["String"]["output"]>;
  declinedBy: Array<Scalars["String"]["output"]>;
  isApproved: Scalars["Boolean"]["output"];
  potentialApprovers: Array<Scalars["String"]["output"]>;
  potentialReviewers: Array<Scalars["String"]["output"]>;
  reviewedBy: Array<Scalars["String"]["output"]>;
};

export enum IVBillBankAccountType {
  Checking = "CHECKING",
  Savings = "SAVINGS",
}

export type IVBillBatchCompanyListPaginatedResponse = {
  __typename?: "BatchCompanyListPaginatedResponse";
  items: Array<IVBillBatchCompanySchema>;
};

export type IVBillBatchCompanyListStatusResponse = {
  __typename?: "BatchCompanyListStatusResponse";
  data?: Maybe<IVBillBatchCompanyListPaginatedResponse>;
  error?: Maybe<Scalars["String"]["output"]>;
  status: IVBillBatchResponseStatus;
};

export type IVBillBatchCompanySchema = {
  __typename?: "BatchCompanySchema";
  batchId: Scalars["Float"]["output"];
  coreId: Scalars["String"]["output"];
  createdByAccountId?: Maybe<Scalars["String"]["output"]>;
  createdByOrgUserId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
};

export enum IVBillBatchDateUnitType {
  Day = "DAY",
  Month = "MONTH",
  Week = "WEEK",
  Year = "YEAR",
}

export type IVBillBatchGeneralStatsOutput = {
  __typename?: "BatchGeneralStatsOutput";
  amount: Scalars["String"]["output"];
  count: Scalars["String"]["output"];
};

export type IVBillBatchGrouingValueOutput = {
  __typename?: "BatchGrouingValueOutput";
  ALL?: Maybe<Scalars["String"]["output"]>;
  BATCH_PAYMENTS_STATUS?: Maybe<IVBillPaymentsGroupingStatus>;
  BATCH_STATUS?: Maybe<IVBillBatchStatus>;
};

export type IVBillBatchGroupStatsOutput = {
  __typename?: "BatchGroupStatsOutput";
  amount: Scalars["String"]["output"];
  count: Scalars["String"]["output"];
  groupingType?: Maybe<IVBillBatchGroupingType>;
  maxDate?: Maybe<Scalars["String"]["output"]>;
  maxDateDue?: Maybe<Scalars["String"]["output"]>;
  minDate?: Maybe<Scalars["String"]["output"]>;
  minDateDue?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<IVBillBatchGrouingValueOutput>;
};

export enum IVBillBatchGroupingType {
  All = "ALL",
  BatchBillsStatus = "BATCH_BILLS_STATUS",
  BatchPaymentsStatus = "BATCH_PAYMENTS_STATUS",
  BatchStatus = "BATCH_STATUS",
}

export type IVBillBatchInvoiceInput = {
  batchAmountForInvoice?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["Float"]["input"];
  memo?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillBatchListFiltersInput = {
  batchIds?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  batch_path_contains?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  batch_path_sort?: InputMaybe<IVBillSortOrderType>;
  budget_from?: InputMaybe<Scalars["String"]["input"]>;
  budget_sort?: InputMaybe<IVBillSortOrderType>;
  budget_to?: InputMaybe<Scalars["String"]["input"]>;
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_due_from?: InputMaybe<Scalars["String"]["input"]>;
  date_due_to?: InputMaybe<Scalars["String"]["input"]>;
  date_from?: InputMaybe<Scalars["String"]["input"]>;
  date_to?: InputMaybe<Scalars["String"]["input"]>;
  includeSubBatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId: Scalars["String"]["input"];
  parentBatchId?: InputMaybe<Scalars["Float"]["input"]>;
  reaction_sort?: InputMaybe<IVBillSortOrderType>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<IVBillBatchStatus>;
  vbill_batch_number_sort?: InputMaybe<IVBillSortOrderType>;
};

export type IVBillBatchListPaginatedResponse = {
  __typename?: "BatchListPaginatedResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillBatchOutput>;
  settings: IVBillBatchSettingsOutput;
  total: Scalars["Int"]["output"];
};

export type IVBillBatchListResponse = {
  __typename?: "BatchListResponse";
  data?: Maybe<IVBillBatchListPaginatedResponse>;
  error?: Maybe<Scalars["String"]["output"]>;
  status: IVBillBatchResponseStatus;
};

export type IVBillBatchListStatsGroupingInput = {
  limit?: InputMaybe<IVBillBatchListStatsGroupingLimitInput>;
  type: IVBillBatchGroupingType;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillBatchListStatsGroupingLimitInput = {
  sort?: InputMaybe<Array<IVBillBatchListStatsGroupingLimitSort>>;
  top: Scalars["Int"]["input"];
};

export type IVBillBatchListStatsGroupingLimitSort = {
  field: IVBillBatchListStatsGroupingLimitSortField;
  order: IVBillSortOrderType;
};

export enum IVBillBatchListStatsGroupingLimitSortField {
  Amount = "AMOUNT",
  Count = "COUNT",
}

export type IVBillBatchMemberListResponse = {
  __typename?: "BatchMemberListResponse";
  items: Array<IVBillBatchMemberSchema>;
};

export type IVBillBatchMemberListStatusResponse = {
  __typename?: "BatchMemberListStatusResponse";
  data?: Maybe<IVBillBatchMemberListResponse>;
  error?: Maybe<Scalars["String"]["output"]>;
  status: IVBillBatchResponseStatus;
};

export type IVBillBatchMemberSchema = {
  __typename?: "BatchMemberSchema";
  batchId: Scalars["Float"]["output"];
  coreOrgUserId: Scalars["String"]["output"];
  createdByAccountId?: Maybe<Scalars["String"]["output"]>;
  createdByOrgUserId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
};

export type IVBillBatchOutput = {
  __typename?: "BatchOutput";
  batchDate?: Maybe<Scalars["String"]["output"]>;
  batchDateDue?: Maybe<Scalars["String"]["output"]>;
  batchFilters?: Maybe<IVBillBillsFiltersOutput>;
  batchPath: Array<Scalars["Int"]["output"]>;
  batchPathDetails: Array<IVBillBatchPathDetailsItem>;
  batchPayments: Array<IVBillBatchPaymentOutput>;
  batchTreeDetails?: Maybe<IVBillBatchTreeDetails>;
  budget?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdByAccountId?: Maybe<Scalars["String"]["output"]>;
  createdByEmail?: Maybe<Scalars["String"]["output"]>;
  createdByName?: Maybe<Scalars["String"]["output"]>;
  createdByOrgUserId?: Maybe<Scalars["String"]["output"]>;
  createdByPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  invoicesAmount?: Maybe<Scalars["String"]["output"]>;
  invoicesAmountInSubBatches?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  organizationId: Scalars["String"]["output"];
  parentBatchId?: Maybe<Scalars["Int"]["output"]>;
  status: IVBillBatchStatus;
};

export type IVBillBatchPaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IVBillBatchPathDetailsItem = {
  __typename?: "BatchPathDetailsItem";
  hasAccess: Scalars["Boolean"]["output"];
  id: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
};

export type IVBillBatchPaymentErrorOutput = {
  __typename?: "BatchPaymentErrorOutput";
  messages: Array<Scalars["String"]["output"]>;
  type: IVBillBatchPaymentErrorType;
};

export enum IVBillBatchPaymentErrorType {
  Push = "PUSH",
  Submit = "SUBMIT",
  Validation = "VALIDATION",
}

export type IVBillBatchPaymentOutput = {
  __typename?: "BatchPaymentOutput";
  batchId: Scalars["Float"]["output"];
  invoiceId: Scalars["Float"]["output"];
  paymentAmountForBillInBatch: Scalars["String"]["output"];
  paymentErrors: Array<IVBillBatchPaymentErrorOutput>;
  paymentId: Scalars["Float"]["output"];
  paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
  paymentPushStatus: IVBillPaymentPushStatus;
  paymentStatus: IVBillPaymentStatus;
};

export type IVBillBatchPaymentsOutput = {
  __typename?: "BatchPaymentsOutput";
  batch: IVBillBatchOutput;
  payments: Array<IVBillInvoicePaymentOutput>;
  success: Scalars["Boolean"]["output"];
};

export type IVBillBatchResponse = {
  __typename?: "BatchResponse";
  data?: Maybe<IVBillBatchOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
  status: IVBillBatchResponseStatus;
};

export enum IVBillBatchResponseStatus {
  Error = "ERROR",
  Success = "SUCCESS",
}

export type IVBillBatchSettingsOutput = {
  __typename?: "BatchSettingsOutput";
  BATCH_SUBBATCHES_ALLOWED: Scalars["Boolean"]["output"];
};

export type IVBillBatchStatsOutput = {
  __typename?: "BatchStatsOutput";
  generalStats: IVBillBatchGeneralStatsOutput;
  groupStats: Array<IVBillBatchGroupStatsOutput>;
};

export type IVBillBatchStatsResponse = {
  __typename?: "BatchStatsResponse";
  data?: Maybe<IVBillBatchStatsOutput>;
  error?: Maybe<Scalars["String"]["output"]>;
  status: IVBillBatchResponseStatus;
};

export enum IVBillBatchStatus {
  Closed = "CLOSED",
  Deleted = "DELETED",
  Open = "OPEN",
}

export type IVBillBatchTreeCommonStats = {
  __typename?: "BatchTreeCommonStats";
  batchesCount?: Maybe<Scalars["Int"]["output"]>;
};

export type IVBillBatchTreeDetails = {
  __typename?: "BatchTreeDetails";
  batchIds: Array<Scalars["Float"]["output"]>;
  commonStats: IVBillBatchTreeCommonStats;
  paymentsStats: Array<IVBillPaymentsGroupStatsOutput>;
};

export enum IVBillBillDateSortType {
  BillDateAsc = "BILL_DATE_ASC",
  BillDateDesc = "BILL_DATE_DESC",
  BillDueAsc = "BILL_DUE_ASC",
  BillDueDesc = "BILL_DUE_DESC",
  CreateDateAsc = "CREATE_DATE_ASC",
  CreateDateDesc = "CREATE_DATE_DESC",
}

export type IVBillBillsFiltersInput = {
  amount_from?: InputMaybe<Scalars["String"]["input"]>;
  amount_sort?: InputMaybe<IVBillSortOrderType>;
  amount_to?: InputMaybe<Scalars["String"]["input"]>;
  batch_id_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** Mutually exclusive with organizationId, but one must be specified */
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  contact_external_ref_ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_due_from?: InputMaybe<Scalars["String"]["input"]>;
  date_due_sort?: InputMaybe<IVBillSortOrderType>;
  date_due_to?: InputMaybe<Scalars["String"]["input"]>;
  date_sort?: InputMaybe<IVBillSortOrderType>;
  date_to?: InputMaybe<Scalars["String"]["input"]>;
  /** Value to be used for relative time */
  date_unit_from?: InputMaybe<Scalars["Int"]["input"]>;
  /** Value to be used for relative time */
  date_unit_now?: InputMaybe<Scalars["String"]["input"]>;
  /** Value to be used for relative time */
  date_unit_to?: InputMaybe<Scalars["Int"]["input"]>;
  date_unit_type?: InputMaybe<IVBillBatchDateUnitType>;
  free_amount_from?: InputMaybe<Scalars["String"]["input"]>;
  free_amount_to?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  integrationStatus?: InputMaybe<IVBillInvoiceIntegrationStatus>;
  mappings?: InputMaybe<Array<IVBillBillsFiltersMappingInput>>;
  /** Mutually exclusive with companyId, but one must be specified */
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
  pushStatus?: InputMaybe<IVBillInvoicePushStatus>;
  related_to_invoice_id?: InputMaybe<Scalars["Int"]["input"]>;
  relativeDateDueFrom?: InputMaybe<IVBillBillsFiltersRelativeDateInput>;
  relativeDateDueTo?: InputMaybe<IVBillBillsFiltersRelativeDateInput>;
  relativeDateFrom?: InputMaybe<IVBillBillsFiltersRelativeDateInput>;
  relativeDateTo?: InputMaybe<IVBillBillsFiltersRelativeDateInput>;
  /** This is mandatory if one of relative date filters provided */
  relativeReferenceDate?: InputMaybe<IVBillBillsFiltersReferenceDateInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<IVBillInvoiceIncomingStatus>;
  status_sort?: InputMaybe<IVBillSortOrderType>;
  vbill_number_sort?: InputMaybe<IVBillSortOrderType>;
};

export type IVBillBillsFiltersMappingInput = {
  key: Scalars["String"]["input"];
  valuesIn: Array<IVBillBillsFiltersMappingValueInput>;
};

export type IVBillBillsFiltersMappingOutput = {
  __typename?: "BillsFiltersMappingOutput";
  key: Scalars["String"]["output"];
  valuesIn: Array<IVBillBillsFiltersMappingValueOutput>;
};

export type IVBillBillsFiltersMappingValueInput = {
  source?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillBillsFiltersMappingValueOutput = {
  __typename?: "BillsFiltersMappingValueOutput";
  source?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillBillsFiltersOutput = {
  __typename?: "BillsFiltersOutput";
  amount_from?: Maybe<Scalars["String"]["output"]>;
  amount_sort?: Maybe<IVBillSortOrderType>;
  amount_to?: Maybe<Scalars["String"]["output"]>;
  batch_id_in?: Maybe<Array<Scalars["Int"]["output"]>>;
  /** Mutually exclusive with organizationId, but one must be specified */
  companyIds?: Maybe<Array<Scalars["String"]["output"]>>;
  contact_external_ref_ids?: Maybe<Array<Scalars["String"]["output"]>>;
  date?: Maybe<Scalars["String"]["output"]>;
  date_due_from?: Maybe<Scalars["String"]["output"]>;
  date_due_sort?: Maybe<IVBillSortOrderType>;
  date_due_to?: Maybe<Scalars["String"]["output"]>;
  date_sort?: Maybe<IVBillSortOrderType>;
  date_to?: Maybe<Scalars["String"]["output"]>;
  /**
   * Value to be used for relative time
   * @deprecated Use relative date field
   */
  date_unit_from?: Maybe<Scalars["Int"]["output"]>;
  /**
   * Value to be used for relative time
   * @deprecated Use relative date field
   */
  date_unit_now?: Maybe<Scalars["String"]["output"]>;
  /**
   * Value to be used for relative time
   * @deprecated Use relative date field
   */
  date_unit_to?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use relative date field */
  date_unit_type?: Maybe<IVBillBatchDateUnitType>;
  free_amount_from?: Maybe<Scalars["String"]["output"]>;
  free_amount_to?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Scalars["Int"]["output"]>>;
  integrationStatus?: Maybe<IVBillInvoiceIntegrationStatus>;
  mappings?: Maybe<Array<IVBillBillsFiltersMappingOutput>>;
  /** Mutually exclusive with companyId, but one must be specified */
  organizationId?: Maybe<Scalars["String"]["output"]>;
  pushStatus?: Maybe<IVBillInvoicePushStatus>;
  related_to_invoice_id?: Maybe<Scalars["Int"]["output"]>;
  relativeDateDueFrom?: Maybe<IVBillBillsFiltersRelativeDateOutput>;
  relativeDateDueTo?: Maybe<IVBillBillsFiltersRelativeDateOutput>;
  relativeDateFrom?: Maybe<IVBillBillsFiltersRelativeDateOutput>;
  relativeDateTo?: Maybe<IVBillBillsFiltersRelativeDateOutput>;
  /** This is mandatory if one of relative date filters provided */
  relativeReferenceDate?: Maybe<IVBillBillsFiltersReferenceDateOutput>;
  search?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<IVBillInvoiceIncomingStatus>;
  status_sort?: Maybe<IVBillSortOrderType>;
  vbill_number_sort?: Maybe<IVBillSortOrderType>;
};

export type IVBillBillsFiltersReferenceDateInput = {
  batchDate?: InputMaybe<Scalars["Boolean"]["input"]>;
  customDate?: InputMaybe<Scalars["String"]["input"]>;
  now?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IVBillBillsFiltersReferenceDateOutput = {
  __typename?: "BillsFiltersReferenceDateOutput";
  batchDate?: Maybe<Scalars["Boolean"]["output"]>;
  customDate?: Maybe<Scalars["String"]["output"]>;
  now?: Maybe<Scalars["Boolean"]["output"]>;
};

export type IVBillBillsFiltersRelativeDateInput = {
  temporality: IVBillTemporality;
  unit: IVBillBatchDateUnitType;
  value: Scalars["Float"]["input"];
};

export type IVBillBillsFiltersRelativeDateOutput = {
  __typename?: "BillsFiltersRelativeDateOutput";
  temporality: IVBillTemporality;
  unit: IVBillBatchDateUnitType;
  value: Scalars["Float"]["output"];
};

export type IVBillBillsGeneralStatsOutput = {
  __typename?: "BillsGeneralStatsOutput";
  amount: Scalars["String"]["output"];
  balance: Scalars["String"]["output"];
  count: Scalars["Float"]["output"];
};

export type IVBillBillsGroupStatsOutput = {
  __typename?: "BillsGroupStatsOutput";
  amount: Scalars["String"]["output"];
  billsGroupingType?: Maybe<IVBillBillsGroupingType>;
  count: Scalars["Float"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  maxDate?: Maybe<Scalars["String"]["output"]>;
  maxDateDue?: Maybe<Scalars["String"]["output"]>;
  minDate?: Maybe<Scalars["String"]["output"]>;
  minDateDue?: Maybe<Scalars["String"]["output"]>;
  settingsKey?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum IVBillBillsGroupingType {
  CompanyId = "COMPANY_ID",
  IntegrationStatus = "INTEGRATION_STATUS",
  MappingKey = "MAPPING_KEY",
}

export type IVBillBillsResponse = {
  __typename?: "BillsResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillInvoiceLightSchema>;
  total: Scalars["Int"]["output"];
};

export type IVBillBillsStatsGroupingInput = {
  limit?: InputMaybe<IVBillBillsStatsGroupingLimitInput>;
  type: IVBillBillsGroupingType;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillBillsStatsGroupingLimitInput = {
  sort?: InputMaybe<Array<IVBillBillsStatsGroupingLimitSort>>;
  top: Scalars["Int"]["input"];
};

export type IVBillBillsStatsGroupingLimitSort = {
  field: IVBillBillsStatsGroupingLimitSortField;
  order: IVBillSortOrderType;
};

export enum IVBillBillsStatsGroupingLimitSortField {
  Amount = "AMOUNT",
  Count = "COUNT",
}

export type IVBillBillsStatsOutput = {
  __typename?: "BillsStatsOutput";
  generalStats: IVBillBillsGeneralStatsOutput;
  groupStats: Array<IVBillBillsGroupStatsOutput>;
};

export type IVBillCompaniesResponse = {
  __typename?: "CompaniesResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillCompanySchema>;
  total: Scalars["Int"]["output"];
};

export type IVBillCompanySchema = {
  __typename?: "CompanySchema";
  companyId: Scalars["String"]["output"];
  emailHash: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  orgId: Scalars["String"]["output"];
  orgName: Scalars["String"]["output"];
  organization?: Maybe<IVBillOrganizationOutput>;
};

export type IVBillErGlMapping = {
  __typename?: "ErGLMapping";
  fields: IVBillErInfo;
  mappings: IVBillErInfo;
};

export type IVBillErInfo = {
  __typename?: "ErInfo";
  custom?: Maybe<IVBillErInfoCustom>;
  required: Array<Scalars["String"]["output"]>;
};

export type IVBillErInfoCustom = {
  __typename?: "ErInfoCustom";
  fields: Array<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
};

export type IVBillErInvoice = {
  __typename?: "ErInvoice";
  fields: IVBillErInfo;
  mappings: IVBillErInfo;
};

export type IVBillErLineItem = {
  __typename?: "ErLineItem";
  fields: IVBillErInfo;
  glMapping: IVBillErGlMapping;
  index?: Maybe<Scalars["Int"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillErrorOutput = {
  __typename?: "ErrorOutput";
  codes?: Maybe<Array<Scalars["String"]["output"]>>;
  message: Scalars["String"]["output"];
  type: IVBillGqlErrorTypes;
};

export type IVBillExpenseAdditionalField = {
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  type: Scalars["String"]["input"];
  typeOfValue: IVBillTypeOfValue;
  value: Scalars["String"]["input"];
};

export type IVBillExpenseDisplayInfoOutput = {
  __typename?: "ExpenseDisplayInfoOutput";
  company?: Maybe<IVBillExpenseEntityInfoOutput>;
  organization: IVBillExpenseEntityInfoOutput;
};

export type IVBillExpenseDisplayInfoParamsInput = {
  token: Scalars["String"]["input"];
};

export type IVBillExpenseEntityInfoOutput = {
  __typename?: "ExpenseEntityInfoOutput";
  expensePublicUrl?: Maybe<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  token?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type IVBillExpensePayeeInput = {
  addressCity?: InputMaybe<Scalars["String"]["input"]>;
  addressPostalCode?: InputMaybe<Scalars["String"]["input"]>;
  addressState?: InputMaybe<Scalars["String"]["input"]>;
  addressStreet?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountType?: InputMaybe<IVBillBankAccountType>;
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  bankRoutingNumber?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillExpensePayeeOutput = {
  __typename?: "ExpensePayeeOutput";
  addressCity?: Maybe<Scalars["String"]["output"]>;
  addressPostalCode?: Maybe<Scalars["String"]["output"]>;
  addressState?: Maybe<Scalars["String"]["output"]>;
  addressStreet?: Maybe<Scalars["String"]["output"]>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  bankAccountType?: Maybe<IVBillBankAccountType>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  bankRoutingNumber?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  uid: Scalars["String"]["output"];
};

export enum IVBillExpensePaymentMethod {
  AchDirect = "ach_direct",
  Print = "print",
}

export type IVBillExpenseReportInput = {
  additionalFields?: InputMaybe<Array<IVBillExpenseAdditionalField>>;
  amount: Scalars["String"]["input"];
  attachmentKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  employeeId?: InputMaybe<Scalars["String"]["input"]>;
  expenseDate: Scalars["String"]["input"];
  payee: IVBillExpensePayeeInput;
  paymentMethod: IVBillExpensePaymentMethod;
  reasonForExpense?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillExpenseReportOutput = {
  __typename?: "ExpenseReportOutput";
  additionalFields?: Maybe<Array<IVBillAbstractExpenseAdditionalField>>;
  amount: Scalars["String"]["output"];
  attachmentKeys?: Maybe<Array<Scalars["String"]["output"]>>;
  createdAt: Scalars["String"]["output"];
  employeeId?: Maybe<Scalars["String"]["output"]>;
  expenseDate: Scalars["String"]["output"];
  expenseReportNumber: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  payee: IVBillExpensePayeeOutput;
  paymentMethod: IVBillExpensePaymentMethod;
  reasonForExpense?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillExpenseTokenParamsInput = {
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  organizationId: Scalars["String"]["input"];
};

export enum IVBillExpenseType {
  Bill = "BILL",
  Reimbursement = "REIMBURSEMENT",
}

export type IVBillFileFiltersInput = {
  attachmentId: Scalars["Int"]["input"];
  coreOrgId: Scalars["String"]["input"];
  invoiceId: Scalars["Int"]["input"];
  pageFileId?: InputMaybe<Scalars["Int"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IVBillFileInfoSchema = {
  __typename?: "FileInfoSchema";
  arrivedAt?: Maybe<Scalars["String"]["output"]>;
  cloudS3Key?: Maybe<Scalars["String"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  fileOcrState: IVBillKFileOcrState;
  id: Scalars["Int"]["output"];
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  isEncrypted?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  ocrText?: Maybe<Scalars["String"]["output"]>;
  ocrTextItems?: Maybe<Array<IVBillTextItemSchema>>;
  origText?: Maybe<Scalars["String"]["output"]>;
  origTextItems?: Maybe<Array<IVBillTextItemSchema>>;
  pageCount?: Maybe<Scalars["Float"]["output"]>;
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  parentId?: Maybe<Scalars["Int"]["output"]>;
  parentRelation?: Maybe<IVBillKFileParentRelation>;
  parents?: Maybe<Array<Scalars["String"]["output"]>>;
  processType?: Maybe<IVBillKFileProcessType>;
  sha1sum?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
};

export type IVBillFileMetadataSchema = {
  __typename?: "FileMetadataSchema";
  AOCR?: Maybe<IVBillInvoiceSuggestionsMetadata>;
  GDAI?: Maybe<IVBillInvoiceSuggestionsMetadata>;
  OPENAI?: Maybe<IVBillInvoiceSuggestionsMetadata>;
};

export type IVBillFilePaginatedResponse = {
  __typename?: "FilePaginatedResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillPageFileSchema>;
  total: Scalars["Int"]["output"];
};

export type IVBillFilePaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum IVBillGqlErrorTypes {
  Generic = "GENERIC",
  NotFound = "NOT_FOUND",
  Unauthenticated = "UNAUTHENTICATED",
  Unauthorized = "UNAUTHORIZED",
  Validation = "VALIDATION",
}

export enum IVBillIIntegrationDataType {
  Contact = "contact",
  Employee = "employee",
  FakeCategory = "fakeCategory",
  FakeLocation = "fakeLocation",
  GlAccount = "glAccount",
  PeoplesoftBankAccount = "peoplesoftBankAccount",
  PeoplesoftCustomField1 = "peoplesoftCustomField1",
  PeoplesoftCustomField2 = "peoplesoftCustomField2",
  PeoplesoftCustomField3 = "peoplesoftCustomField3",
  PeoplesoftCustomField4 = "peoplesoftCustomField4",
  PeoplesoftPayGroup = "peoplesoftPayGroup",
  PeoplesoftPayTerm = "peoplesoftPayTerm",
  QbdCustomer = "qbdCustomer",
  QbdSalesTerm = "qbdSalesTerm",
  QboCustomer = "qboCustomer",
  QboProduct = "qboProduct",
  QboSalesTerm = "qboSalesTerm",
  SageAccountAllocation = "sageAccountAllocation",
  SageAllocation = "sageAllocation",
  SageApTerm = "sageApTerm",
  SageBankAccount = "sageBankAccount",
  SageDepartment = "sageDepartment",
  SageEntity = "sageEntity",
  SageGlAllocation = "sageGlAllocation",
  SageLocation = "sageLocation",
  SagePaymentMethod = "sagePaymentMethod",
  SageUserInfo = "sageUserInfo",
}

export enum IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs {
  CheckBox = "CheckBox",
  DateInput = "DateInput",
  Select = "Select",
}

export enum IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn {
  BillTo = "BillTo",
  Bottom = "Bottom",
  Center = "Center",
  Footer = "Footer",
  From = "From",
  Left = "Left",
  Middle = "Middle",
  Misc = "Misc",
  None = "None",
  Payment = "Payment",
  Right = "Right",
  Top = "Top",
}

export enum IVBillIPaymentFiltersSortValues {
  Asc = "ASC",
  Desc = "DESC",
}

export enum IVBillIPaymentSearchFilterContext {
  Payment = "PAYMENT",
  Vbill = "VBILL",
  Vcheck = "VCHECK",
}

export enum IVBillIShtarActionLogMetaContentBlockMessageType {
  Message = "MESSAGE",
  Table = "TABLE",
}

export enum IVBillIShtarActionLogMetaContentBlockTableIcon {
  IconRight = "ICON_RIGHT",
}

export enum IVBillIShtarActionLogObjectType {
  Account = "account",
  Bankaccount = "bankaccount",
  Bill = "bill",
  Billbatch = "billbatch",
  Check = "check",
  Checkbatch = "checkbatch",
  Company = "company",
  Department = "department",
  Organization = "organization",
  Payment = "payment",
}

export enum IVBillIntegrationSettingsCapabilities {
  VBillPaymentVoid = "vBillPaymentVoid",
  VBillPaymentsRead = "vBillPaymentsRead",
  VBillPaymentsWrite = "vBillPaymentsWrite",
  VBillPaymentsWriteMultiple = "vBillPaymentsWriteMultiple",
  VBillPaymentsWritePartial = "vBillPaymentsWritePartial",
  VBillRead = "vBillRead",
  VBillWriteCreate = "vBillWriteCreate",
  VBillWriteUpdate = "vBillWriteUpdate",
  VCheckRead = "vCheckRead",
  VCheckWriteCheckNumber = "vCheckWriteCheckNumber",
  VCheckWriteStatusPaid = "vCheckWriteStatusPaid",
  VCheckWriteStatusReceived = "vCheckWriteStatusReceived",
  VCheckWriteStatusSigned = "vCheckWriteStatusSigned",
  VCheckWriteStatusVoid = "vCheckWriteStatusVoid",
  VCheckWriteUpdateMemo = "vCheckWriteUpdateMemo",
  VCheckWriteUpdatePttoo = "vCheckWriteUpdatePttoo",
}

export type IVBillIntegrationSettingsCapabilitiesOutput = {
  __typename?: "IntegrationSettingsCapabilitiesOutput";
  available: Array<IVBillIntegrationSettingsCapabilities>;
  enabled: Array<IVBillIntegrationSettingsCapabilities>;
};

export enum IVBillIntegrationSettingsDataSource {
  Boolean = "Boolean",
  Input = "Input",
  StaticSelectList = "StaticSelectList",
  Store = "Store",
}

export type IVBillIntegrationSettingsInfoOutput = {
  __typename?: "IntegrationSettingsInfoOutput";
  storeSource: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type IVBillIntegrationSettingsOutput = {
  __typename?: "IntegrationSettingsOutput";
  capabilities: IVBillIntegrationSettingsCapabilitiesOutput;
  info?: Maybe<IVBillIntegrationSettingsInfoOutput>;
  vBill: IVBillIntegrationSettingsVBillOutput;
};

export type IVBillIntegrationSettingsStaticSelectListItem = {
  __typename?: "IntegrationSettingsStaticSelectListItem";
  label?: Maybe<Scalars["String"]["output"]>;
  label2?: Maybe<Scalars["String"]["output"]>;
  parentLabel?: Maybe<Scalars["String"]["output"]>;
  settingsKey?: Maybe<Scalars["String"]["output"]>;
  typeOfValue?: Maybe<IVBillIntegrationSettingsTypeOfValue>;
  value: Scalars["String"]["output"];
};

export enum IVBillIntegrationSettingsTypeOfValue {
  Boolean = "Boolean",
  Datetime = "Datetime",
  Json = "Json",
  Numeric = "Numeric",
  Text = "Text",
}

export type IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput = {
  __typename?: "IntegrationSettingsVBillAdditionalMappingsEntryOutput";
  dataSource: IVBillIntegrationSettingsDataSource;
  defaultValue?: Maybe<Scalars["String"]["output"]>;
  displayKeys?: Maybe<Array<Scalars["String"]["output"]>>;
  displayMetaKeys?: Maybe<Array<Scalars["String"]["output"]>>;
  isContact?: Maybe<Scalars["Boolean"]["output"]>;
  isGlAccount?: Maybe<Scalars["Boolean"]["output"]>;
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  showAs: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs;
  showIn?: Maybe<IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn>;
  staticSelectList?: Maybe<Array<IVBillIntegrationSettingsStaticSelectListItem>>;
  storeDataType: IVBillIIntegrationDataType;
  tooltip?: Maybe<Scalars["String"]["output"]>;
  typeOfValue?: Maybe<IVBillIntegrationSettingsTypeOfValue>;
};

export type IVBillIntegrationSettingsVBillAdditionalMappingsOutput = {
  __typename?: "IntegrationSettingsVBillAdditionalMappingsOutput";
  invoice: Array<IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput>;
  invoiceValidator?: Maybe<Scalars["String"]["output"]>;
  line: Array<IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput>;
  lineValidator?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillIntegrationSettingsVBillOutput = {
  __typename?: "IntegrationSettingsVBillOutput";
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsOutput;
};

export type IVBillInvoiceActionLogChangedBy = {
  __typename?: "InvoiceActionLogChangedBy";
  accountId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationUserId?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillInvoiceActionLogMetaAdditionalContentBlockSchema = {
  __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
  bindings?: Maybe<Array<IVBillInvoiceActionLogMetaTemplateBindings>>;
  columns?: Maybe<Array<IVBillInvoiceActionLogMetaContentBlockTableCell>>;
  message?: Maybe<Scalars["String"]["output"]>;
  related?: Maybe<Array<IVBillInvoiceActionLogMetaContentBlockMessageRelated>>;
  rows?: Maybe<Array<IVBillInvoiceActionLogMetaContentBlockTableRow>>;
  template?: Maybe<Scalars["String"]["output"]>;
  type: IVBillIShtarActionLogMetaContentBlockMessageType;
};

export type IVBillInvoiceActionLogMetaContentBlockMessageRelated = {
  __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
  key: Scalars["String"]["output"];
  related: IVBillInvoiceActionLogPathOrRelatedSchema;
};

export type IVBillInvoiceActionLogMetaContentBlockMessageSchema = {
  __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
  bindings?: Maybe<Array<IVBillInvoiceActionLogMetaTemplateBindings>>;
  message?: Maybe<Scalars["String"]["output"]>;
  related?: Maybe<Array<IVBillInvoiceActionLogMetaContentBlockMessageRelated>>;
  template?: Maybe<Scalars["String"]["output"]>;
  type: IVBillIShtarActionLogMetaContentBlockMessageType;
};

export type IVBillInvoiceActionLogMetaContentBlockTableCell = {
  __typename?: "InvoiceActionLogMetaContentBlockTableCell";
  bindings?: Maybe<Array<IVBillInvoiceActionLogMetaTemplateBindings>>;
  icon?: Maybe<IVBillIShtarActionLogMetaContentBlockTableIcon>;
  message?: Maybe<Scalars["String"]["output"]>;
  template?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillInvoiceActionLogMetaContentBlockTableRow = {
  __typename?: "InvoiceActionLogMetaContentBlockTableRow";
  cells?: Maybe<Array<IVBillInvoiceActionLogMetaContentBlockTableCell>>;
};

export type IVBillInvoiceActionLogMetaSchema = {
  __typename?: "InvoiceActionLogMetaSchema";
  additionalContent?: Maybe<Array<IVBillInvoiceActionLogMetaAdditionalContentBlockSchema>>;
  content?: Maybe<IVBillInvoiceActionLogMetaContentBlockMessageSchema>;
};

export type IVBillInvoiceActionLogMetaTemplateBindings = {
  __typename?: "InvoiceActionLogMetaTemplateBindings";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type IVBillInvoiceActionLogPathOrRelatedSchema = {
  __typename?: "InvoiceActionLogPathOrRelatedSchema";
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  type: IVBillIShtarActionLogObjectType;
};

export type IVBillInvoiceActionLogSchema = {
  __typename?: "InvoiceActionLogSchema";
  action: Scalars["String"]["output"];
  changedBy: IVBillInvoiceActionLogChangedBy;
  createdAt: Scalars["String"]["output"];
  id: Scalars["Float"]["output"];
  initiator: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  level: Scalars["String"]["output"];
  meta?: Maybe<IVBillInvoiceActionLogMetaSchema>;
  path?: Maybe<Array<IVBillInvoiceActionLogPathOrRelatedSchema>>;
  related?: Maybe<Array<IVBillInvoiceActionLogPathOrRelatedSchema>>;
  scope: Scalars["String"]["output"];
  type: IVBillIShtarActionLogObjectType;
};

export enum IVBillInvoiceActionRequiredStatus {
  IntegrationSyncError = "INTEGRATION_SYNC_ERROR",
  None = "NONE",
  PendingApprovalExpired = "PENDING_APPROVAL_EXPIRED",
  StartApprovalError = "START_APPROVAL_ERROR",
}

export type IVBillInvoiceAmountInBatchDetail = {
  __typename?: "InvoiceAmountInBatchDetail";
  batchAmountForInvoice: Scalars["String"]["output"];
  batchId: Scalars["Int"]["output"];
  invoiceId: Scalars["Int"]["output"];
  memo?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillInvoiceAmounts = {
  __typename?: "InvoiceAmounts";
  amount: Scalars["String"]["output"];
  amountFreeToPay: Scalars["String"]["output"];
  amountInBatches: Scalars["String"]["output"];
  amountInBatchesBreakdown: Array<IVBillInvoiceAmountInBatchDetail>;
  amountInPayments: Scalars["String"]["output"];
  paymentsStats: IVBillInvoicePaymentsStats;
};

export type IVBillInvoiceApprovalSchema = {
  __typename?: "InvoiceApprovalSchema";
  createdAt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  invoiceId: Scalars["Int"]["output"];
  orgUserId: Scalars["String"]["output"];
  status: IVBillInvoiceApprovalStatus;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export enum IVBillInvoiceApprovalStatus {
  Revoked = "REVOKED",
  Valid = "VALID",
}

export type IVBillInvoiceAttachmentToMoveInput = {
  attachmentId: Scalars["Int"]["input"];
  pagesToMove?: InputMaybe<Array<IVBillPageToMoveInput>>;
};

export type IVBillInvoiceFileSchema = {
  __typename?: "InvoiceFileSchema";
  contentType?: Maybe<Scalars["String"]["output"]>;
  fileIsEncrypted?: Maybe<Scalars["Boolean"]["output"]>;
  fileOcrState: IVBillKFileOcrState;
  id: Scalars["Int"]["output"];
  invoiceId: Scalars["Int"]["output"];
  isInvoiceMainFile?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<IVBillFileMetadataSchema>;
  movedToInvoice?: Maybe<IVBillInvoiceSchema>;
  name?: Maybe<Scalars["String"]["output"]>;
  pageCount?: Maybe<Scalars["Int"]["output"]>;
  pages?: Maybe<Array<IVBillAbstractPageFileOutput>>;
  sha1sum?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
  storageKey?: Maybe<Scalars["String"]["output"]>;
};

export enum IVBillInvoiceIncomingStatus {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Deleted = "DELETED",
  Duplicate = "DUPLICATE",
  History = "HISTORY",
  Mapped = "MAPPED",
  New = "NEW",
  PendingApproval = "PENDING_APPROVAL",
  Queued = "QUEUED",
}

export enum IVBillInvoiceIntegrationStatus {
  InvoiceApproved = "INVOICE_APPROVED",
  InvoiceDraft = "INVOICE_DRAFT",
  InvoicePaid = "INVOICE_PAID",
  InvoicePartialPaid = "INVOICE_PARTIAL_PAID",
  InvoicePaymentPending = "INVOICE_PAYMENT_PENDING",
  InvoiceVoided = "INVOICE_VOIDED",
}

export type IVBillInvoiceLightSchema = {
  __typename?: "InvoiceLightSchema";
  actionRequiredStatus?: Maybe<IVBillInvoiceActionRequiredStatus>;
  additionalMappings?: Maybe<Array<IVBillAdditionalMappingSchema>>;
  amount: Scalars["String"]["output"];
  amountToBePaid?: Maybe<Scalars["String"]["output"]>;
  amounts?: Maybe<IVBillInvoiceAmounts>;
  approvalCount?: Maybe<Scalars["Int"]["output"]>;
  approvalStatus?: Maybe<IVBillApprovalStatusSchema>;
  canBePaid?: Maybe<Scalars["Boolean"]["output"]>;
  companyId?: Maybe<Scalars["String"]["output"]>;
  contactExternalRef?: Maybe<Scalars["String"]["output"]>;
  coreOrgId: Scalars["String"]["output"];
  createdAt: Scalars["String"]["output"];
  displayError?: Maybe<IVBillErrorOutput>;
  emailAttachmentUrls?: Maybe<Array<Scalars["String"]["output"]>>;
  emailBodyText?: Maybe<Scalars["String"]["output"]>;
  emailDate?: Maybe<Scalars["String"]["output"]>;
  emailSubject?: Maybe<Scalars["String"]["output"]>;
  emailUrl?: Maybe<Scalars["String"]["output"]>;
  expenseReport?: Maybe<IVBillAbstractExpenseReportOutput>;
  expenseType?: Maybe<IVBillExpenseType>;
  externalRef?: Maybe<Scalars["String"]["output"]>;
  filesOcrState?: Maybe<IVBillKInvoiceFilesOcrState>;
  hasLineItems: Scalars["Boolean"]["output"];
  hashedId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  initialSenderEmail?: Maybe<Scalars["String"]["output"]>;
  initialSenderName?: Maybe<Scalars["String"]["output"]>;
  integrationRef?: Maybe<Scalars["String"]["output"]>;
  integrationStatus?: Maybe<IVBillInvoiceIntegrationStatus>;
  invoiceDate?: Maybe<Scalars["String"]["output"]>;
  invoiceDateDue?: Maybe<Scalars["String"]["output"]>;
  invoiceNumber?: Maybe<Scalars["String"]["output"]>;
  isPotentialDuplicate?: Maybe<Scalars["Boolean"]["output"]>;
  mainFileId?: Maybe<Scalars["String"]["output"]>;
  nextUserActions: Array<IVBillInvoiceUserActionSchema>;
  payments?: Maybe<Array<IVBillInvoicePaymentOutput>>;
  pushStatus?: Maybe<IVBillInvoicePushStatus>;
  recipientAddress?: Maybe<Scalars["String"]["output"]>;
  recipientEmail?: Maybe<Scalars["String"]["output"]>;
  recipientEmails?: Maybe<Array<Scalars["String"]["output"]>>;
  recipientName?: Maybe<Scalars["String"]["output"]>;
  recipientPhone?: Maybe<Scalars["String"]["output"]>;
  senderAddress?: Maybe<Scalars["String"]["output"]>;
  senderEmail?: Maybe<Scalars["String"]["output"]>;
  senderEmails?: Maybe<Array<Scalars["String"]["output"]>>;
  senderName?: Maybe<Scalars["String"]["output"]>;
  senderPhone?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<IVBillInvoiceSource>;
  status: IVBillInvoiceIncomingStatus;
  statusFlags: IVBillInvoiceStatusFlagsSchema;
  suggestedMainFileId?: Maybe<Scalars["String"]["output"]>;
  syncError?: Maybe<IVBillErrorOutput>;
};

export type IVBillInvoiceLineItemMetaSchema = {
  __typename?: "InvoiceLineItemMetaSchema";
  amount?: Maybe<IVBillNumberWithConfidence>;
  confidence: Scalars["Float"]["output"];
  description?: Maybe<IVBillStringWithConfidence>;
  qty?: Maybe<IVBillNumberWithConfidence>;
  rate?: Maybe<IVBillNumberWithConfidence>;
  type?: Maybe<IVBillLineItemType>;
  uid: Scalars["String"]["output"];
  um?: Maybe<IVBillStringWithConfidence>;
};

export type IVBillInvoicePaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IVBillInvoicePaymentOutput = {
  __typename?: "InvoicePaymentOutput";
  batchId?: Maybe<Scalars["Int"]["output"]>;
  companyId: Scalars["String"]["output"];
  invoiceId: Scalars["Int"]["output"];
  invoiceRelationType?: Maybe<Scalars["String"]["output"]>;
  memo?: Maybe<Scalars["String"]["output"]>;
  paymentAmount: Scalars["String"]["output"];
  paymentDate: Scalars["String"]["output"];
  paymentError?: Maybe<IVBillErrorOutput>;
  paymentExternalRef?: Maybe<Scalars["String"]["output"]>;
  paymentExternalUrl?: Maybe<Scalars["String"]["output"]>;
  paymentFromGlAccount: IVBillPaymentAccount;
  paymentId: Scalars["Int"]["output"];
  paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
  paymentPushStatus: IVBillPaymentPushStatus;
  paymentStatus?: Maybe<IVBillPaymentStatus>;
  paymentToVendorAccount: IVBillPaymentAccount;
  submittedByUserAccount?: Maybe<IVBillPaymentSubmittedByUserAccount>;
  vCheckId?: Maybe<Scalars["String"]["output"]>;
  vCheckNumber?: Maybe<Scalars["String"]["output"]>;
  vCheckPaymentType?: Maybe<IVBillVCheckPaymentType>;
  vCheckState?: Maybe<IVBillVCheckState>;
};

export type IVBillInvoicePaymentsStats = {
  __typename?: "InvoicePaymentsStats";
  failed: IVBillPaymentsStats;
  pending: IVBillPaymentsStats;
  successful: IVBillPaymentsStats;
};

export enum IVBillInvoicePushStatus {
  Done = "DONE",
  Error = "ERROR",
  Queued = "QUEUED",
}

export enum IVBillInvoiceQuickFilterStatus {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Deleted = "DELETED",
  Duplicate = "DUPLICATE",
  History = "HISTORY",
  Mapped = "MAPPED",
  New = "NEW",
  PendingApproval = "PENDING_APPROVAL",
  PushDone = "PUSH_DONE",
  PushPending = "PUSH_PENDING",
  Queued = "QUEUED",
}

export type IVBillInvoiceRelationSchema = {
  __typename?: "InvoiceRelationSchema";
  fromInvoiceId: Scalars["Int"]["output"];
  status: IVBillInvoiceRelationStatus;
  toInvoiceId: Scalars["Int"]["output"];
  type: IVBillInvoiceRelationType;
};

export enum IVBillInvoiceRelationStatus {
  Copied = "COPIED",
  DuplicateIgnored = "DUPLICATE_IGNORED",
  DuplicateResolved = "DUPLICATE_RESOLVED",
  DuplicateUnresolved = "DUPLICATE_UNRESOLVED",
}

export enum IVBillInvoiceRelationType {
  Copy = "COPY",
  DuplicateFiles = "DUPLICATE_FILES",
  DuplicateOf = "DUPLICATE_OF",
  PotentialOcrDuplicateOf = "POTENTIAL_OCR_DUPLICATE_OF",
  Split = "SPLIT",
}

export type IVBillInvoiceSchema = {
  __typename?: "InvoiceSchema";
  actionRequiredStatus?: Maybe<IVBillInvoiceActionRequiredStatus>;
  actions?: Maybe<Array<IVBillInvoiceActionLogSchema>>;
  additionalMappings?: Maybe<Array<IVBillAdditionalMappingSchema>>;
  amount: Scalars["String"]["output"];
  amountToBePaid?: Maybe<Scalars["String"]["output"]>;
  amounts?: Maybe<IVBillInvoiceAmounts>;
  approvalCount?: Maybe<Scalars["Int"]["output"]>;
  approvalStatus?: Maybe<IVBillApprovalStatusSchema>;
  approvals?: Maybe<Array<IVBillInvoiceApprovalSchema>>;
  canBePaid?: Maybe<Scalars["Boolean"]["output"]>;
  company?: Maybe<IVBillCompanySchema>;
  companyId?: Maybe<Scalars["String"]["output"]>;
  contactExternalRef?: Maybe<Scalars["String"]["output"]>;
  coreOrgId: Scalars["String"]["output"];
  createdAt: Scalars["String"]["output"];
  displayError?: Maybe<IVBillErrorOutput>;
  emailAttachmentUrls?: Maybe<Array<Scalars["String"]["output"]>>;
  emailBodyText?: Maybe<Scalars["String"]["output"]>;
  emailDate?: Maybe<Scalars["String"]["output"]>;
  emailSubject?: Maybe<Scalars["String"]["output"]>;
  emailUrl?: Maybe<Scalars["String"]["output"]>;
  expenseReport?: Maybe<IVBillExpenseReportOutput>;
  expenseType?: Maybe<IVBillExpenseType>;
  externalRef?: Maybe<Scalars["String"]["output"]>;
  files?: Maybe<Array<IVBillInvoiceFileSchema>>;
  filesOcrState?: Maybe<IVBillKInvoiceFilesOcrState>;
  hasLineItems: Scalars["Boolean"]["output"];
  hashedId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  initialSenderEmail?: Maybe<Scalars["String"]["output"]>;
  initialSenderName?: Maybe<Scalars["String"]["output"]>;
  integrationRef?: Maybe<Scalars["String"]["output"]>;
  integrationStatus?: Maybe<IVBillInvoiceIntegrationStatus>;
  invoiceDate?: Maybe<Scalars["String"]["output"]>;
  invoiceDateDue?: Maybe<Scalars["String"]["output"]>;
  invoiceNumber?: Maybe<Scalars["String"]["output"]>;
  isPotentialDuplicate?: Maybe<Scalars["Boolean"]["output"]>;
  lineItems?: Maybe<Array<IVBillLineItemSchema>>;
  mainFile?: Maybe<IVBillFileInfoSchema>;
  mainFileId?: Maybe<Scalars["String"]["output"]>;
  nextUserActions: Array<IVBillInvoiceUserActionSchema>;
  organizationContactExternalRef?: Maybe<Scalars["String"]["output"]>;
  payments?: Maybe<Array<IVBillInvoicePaymentOutput>>;
  pushStatus?: Maybe<IVBillInvoicePushStatus>;
  recipientAddress?: Maybe<Scalars["String"]["output"]>;
  recipientEmail?: Maybe<Scalars["String"]["output"]>;
  recipientEmails?: Maybe<Array<Scalars["String"]["output"]>>;
  recipientName?: Maybe<Scalars["String"]["output"]>;
  recipientPhone?: Maybe<Scalars["String"]["output"]>;
  senderAddress?: Maybe<Scalars["String"]["output"]>;
  senderEmail?: Maybe<Scalars["String"]["output"]>;
  senderEmails?: Maybe<Array<Scalars["String"]["output"]>>;
  senderName?: Maybe<Scalars["String"]["output"]>;
  senderPhone?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<IVBillInvoiceSource>;
  status: IVBillInvoiceIncomingStatus;
  statusFlags: IVBillInvoiceStatusFlagsSchema;
  suggestedMainFileId?: Maybe<Scalars["String"]["output"]>;
  syncError?: Maybe<IVBillErrorOutput>;
  validationErrors?: Maybe<IVBillValidationErrorsOutput>;
};

export enum IVBillInvoiceSource {
  Email = "email",
  GuestExpense = "guestExpense",
  InternalImport = "internalImport",
  InternalIntegrationImport = "internalIntegrationImport",
  UserUpload = "userUpload",
}

export type IVBillInvoiceSplitAttachmentsInput = {
  attachmentsToMove: Array<IVBillInvoiceAttachmentToMoveInput>;
  createOneInvoiceForAllMovedFiles?: InputMaybe<Scalars["Boolean"]["input"]>;
  deleteMovedPagesFromOriginal?: InputMaybe<Scalars["Boolean"]["input"]>;
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillInvoiceStatsItemSchema = {
  __typename?: "InvoiceStatsItemSchema";
  amount?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  withCompanyCount?: Maybe<Scalars["Float"]["output"]>;
  withoutCompanyCount?: Maybe<Scalars["Float"]["output"]>;
};

export type IVBillInvoiceStatusFlagsSchema = {
  __typename?: "InvoiceStatusFlagsSchema";
  canBeEdited: Scalars["Boolean"]["output"];
  isCompanyMappingComplete: Scalars["Boolean"]["output"];
  isMappingComplete: Scalars["Boolean"]["output"];
  isOCRComplete: Scalars["Boolean"]["output"];
};

export type IVBillInvoiceStatusStatsSchema = {
  __typename?: "InvoiceStatusStatsSchema";
  APPROVED?: Maybe<IVBillInvoiceStatsItemSchema>;
  DECLINED?: Maybe<IVBillInvoiceStatsItemSchema>;
  MAPPED?: Maybe<IVBillInvoiceStatsItemSchema>;
  NEW?: Maybe<IVBillInvoiceStatsItemSchema>;
  PENDING_APPROVAL?: Maybe<IVBillInvoiceStatsItemSchema>;
  QF_ACTION_REQUIRED?: Maybe<IVBillInvoiceStatsItemSchema>;
  QF_ALL?: Maybe<IVBillInvoiceStatsItemSchema>;
  QF_APPROVAL?: Maybe<IVBillInvoiceStatsItemSchema>;
  QF_CODING?: Maybe<IVBillInvoiceStatsItemSchema>;
  QF_COMPLETED?: Maybe<IVBillInvoiceStatsItemSchema>;
  QF_PENDING_REVIEW?: Maybe<IVBillInvoiceStatsItemSchema>;
  QUEUED?: Maybe<IVBillInvoiceStatsItemSchema>;
};

export type IVBillInvoiceSuggestionsMetadata = {
  __typename?: "InvoiceSuggestionsMetadata";
  amount?: Maybe<Scalars["Float"]["output"]>;
  invoiceDate?: Maybe<Scalars["String"]["output"]>;
  invoiceDateDue?: Maybe<Scalars["String"]["output"]>;
  invoiceNumber?: Maybe<Scalars["String"]["output"]>;
  lineItems: Array<IVBillInvoiceLineItemMetaSchema>;
  recipientAddress?: Maybe<Scalars["String"]["output"]>;
  recipientEmail?: Maybe<Scalars["String"]["output"]>;
  recipientName?: Maybe<Scalars["String"]["output"]>;
  recipientPhone?: Maybe<Scalars["String"]["output"]>;
  senderAddress?: Maybe<Scalars["String"]["output"]>;
  senderEmail?: Maybe<Scalars["String"]["output"]>;
  senderName?: Maybe<Scalars["String"]["output"]>;
  senderPhone?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillInvoiceUpdateCompanyInput = {
  companyCoreId: Scalars["String"]["input"];
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillInvoiceUserActionSchema = {
  __typename?: "InvoiceUserActionSchema";
  description: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  showIn: IVBillInvoiceUserActionShowIn;
  type: IVBillInvoiceUserActionType;
};

export enum IVBillInvoiceUserActionShowIn {
  InvoiceDetails = "INVOICE_DETAILS",
  InvoiceListing = "INVOICE_LISTING",
}

export enum IVBillInvoiceUserActionType {
  Approve = "APPROVE",
  CompleteCoding = "COMPLETE_CODING",
  CompleteOcr = "COMPLETE_OCR",
  DelegateApproval = "DELEGATE_APPROVAL",
  DeleteInvoice = "DELETE_INVOICE",
  Reject = "REJECT",
  SaveDraft = "SAVE_DRAFT",
  StartApproval = "START_APPROVAL",
  StartCoding = "START_CODING",
  StartOcr = "START_OCR",
}

export type IVBillInvoicesFiltersInput = {
  actionRequiredStatusList?: InputMaybe<Array<IVBillInvoiceActionRequiredStatus>>;
  amount_from?: InputMaybe<Scalars["Float"]["input"]>;
  amount_sort?: InputMaybe<IVBillSortOrderType>;
  amount_to?: InputMaybe<Scalars["Float"]["input"]>;
  /** Mutually exclusive with organizationId, but one must be specified */
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  contact_external_ref_ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
  createDateFrom?: InputMaybe<Scalars["String"]["input"]>;
  createDateTo?: InputMaybe<Scalars["String"]["input"]>;
  dates_sort?: InputMaybe<IVBillBillDateSortType>;
  invoiceDateDueFrom?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateDueTo?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateFrom?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateTo?: InputMaybe<Scalars["String"]["input"]>;
  invoice_id_sort?: InputMaybe<IVBillSortOrderType>;
  invoice_number_sort?: InputMaybe<IVBillSortOrderType>;
  mappings?: InputMaybe<Array<IVBillBillsFiltersMappingInput>>;
  /** Mutually exclusive with companyId, but one must be specified */
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
  quickFilterStatusList?: InputMaybe<Array<IVBillInvoiceQuickFilterStatus>>;
  related_to_invoice_id?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<IVBillInvoiceIncomingStatus>;
  status_sort?: InputMaybe<IVBillSortOrderType>;
};

export type IVBillInvoicesResponse = {
  __typename?: "InvoicesResponse";
  generalStats: IVBillInvoiceStatsItemSchema;
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillInvoiceLightSchema>;
  statusStats?: Maybe<IVBillInvoiceStatusStatsSchema>;
  total: Scalars["Int"]["output"];
};

export enum IVBillKAutoocrContentProcessor {
  Autoocr = "AUTOOCR",
  Googledocumentai = "GOOGLEDOCUMENTAI",
  Graphicsmagick = "GRAPHICSMAGICK",
  Pdfjs = "PDFJS",
  Pdflib = "PDFLIB",
  Tesseract = "TESSERACT",
  Unknown = "UNKNOWN",
}

export enum IVBillKFileOcrState {
  OcrDoneOk = "OCR_DONE_OK",
  OcrError = "OCR_ERROR",
  OcrStartTriggered = "OCR_START_TRIGGERED",
  PendingStartProcessing = "PENDING_START_PROCESSING",
  Unknown = "UNKNOWN",
}

export enum IVBillKFileParentRelation {
  Attachment = "ATTACHMENT",
  Content = "CONTENT",
  None = "NONE",
  Page = "PAGE",
  Transform = "TRANSFORM",
  Version = "VERSION",
}

export enum IVBillKFileProcessType {
  Bind = "BIND",
  Extract = "EXTRACT",
  None = "NONE",
  Processed = "PROCESSED",
  Unknown = "UNKNOWN",
}

export enum IVBillKInvoiceFilesOcrState {
  OcrProcessingDone = "OCR_PROCESSING_DONE",
  OcrProcessingDoneError = "OCR_PROCESSING_DONE_ERROR",
  OcrProcessingDoneOk = "OCR_PROCESSING_DONE_OK",
  Pending = "PENDING",
}

export type IVBillLineItemGlMappingInput = {
  additionalMapping?: InputMaybe<Array<IVBillAdditionalMappingInput>>;
  glAccountExternalRef?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Float"]["input"]>;
  lineItemId?: InputMaybe<Scalars["Float"]["input"]>;
  qty?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IVBillLineItemGlMappingSchema = {
  __typename?: "LineItemGLMappingSchema";
  additionalMapping?: Maybe<Array<IVBillAdditionalMappingOutput>>;
  additionalMappings?: Maybe<Array<IVBillAdditionalMappingSchema>>;
  glAccountExternalRef?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  lineItemId: Scalars["Int"]["output"];
  organizationGlAccountExternalRef?: Maybe<Scalars["String"]["output"]>;
  qty: Scalars["Float"]["output"];
};

export type IVBillLineItemInput = {
  amount: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  glMappings?: InputMaybe<Array<IVBillLineItemGlMappingInput>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  invoiceId?: InputMaybe<Scalars["Int"]["input"]>;
  orderIndex?: InputMaybe<Scalars["Int"]["input"]>;
  qty?: InputMaybe<Scalars["String"]["input"]>;
  rate?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<IVBillLineItemType>;
  uid?: InputMaybe<Scalars["String"]["input"]>;
  um?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillLineItemSchema = {
  __typename?: "LineItemSchema";
  amount: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  glMappings?: Maybe<Array<IVBillLineItemGlMappingSchema>>;
  id: Scalars["Int"]["output"];
  invoiceId: Scalars["Int"]["output"];
  orderIndex?: Maybe<Scalars["Int"]["output"]>;
  qty?: Maybe<Scalars["String"]["output"]>;
  rate?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<IVBillLineItemType>;
  uid?: Maybe<Scalars["String"]["output"]>;
  um?: Maybe<Scalars["String"]["output"]>;
};

export enum IVBillLineItemType {
  Freight = "FREIGHT",
  Normal = "NORMAL",
  Tax = "TAX",
}

export type IVBillMutation = {
  __typename?: "Mutation";
  addAllInvoicesToBatch: IVBillBatchResponse;
  addCompaniesToBatch: IVBillBatchCompanyListStatusResponse;
  addInvoiceComment: IVBillInvoiceSchema;
  addInvoicesToBatch: IVBillBatchResponse;
  addMembersToBatch: IVBillBatchMemberListStatusResponse;
  completeInvoiceMapping: IVBillInvoiceSchema;
  completeInvoiceOCR: IVBillInvoiceSchema;
  /** Copy failed to sync invoice to a new editable invoice */
  copyInvoice: IVBillInvoiceSchema;
  createBatch: IVBillBatchResponse;
  createBatchPayments: IVBillBatchPaymentsOutput;
  createBillsPayments: Array<IVBillInvoiceLightSchema>;
  createExpenseReport: IVBillExpenseReportOutput;
  deleteBatch: IVBillBatchResponse;
  deleteInvoice: IVBillInvoiceSchema;
  deleteInvoices: Array<Scalars["Float"]["output"]>;
  markInvoiceAsDuplicate: Scalars["Boolean"]["output"];
  pushInvoice: IVBillInvoiceSchema;
  removeCompaniesFromBatch: IVBillBatchCompanyListStatusResponse;
  removeInvoicesFromBatch: IVBillBatchResponse;
  removeMembersFromBatch: IVBillBatchMemberListStatusResponse;
  revokeInvoice: IVBillInvoiceSchema;
  splitInvoiceAttachments: Array<IVBillInvoiceSchema>;
  splitInvoiceFile: IVBillInvoiceSchema;
  startApprovalForInvoice: IVBillInvoiceSchema;
  updateBatch: IVBillBatchResponse;
  updateBatchStatus: IVBillBatchResponse;
  updateInvoiceAndMappings: IVBillInvoiceSchema;
  updateInvoiceCompany: IVBillInvoiceSchema;
  uploadInvoice: IVBillInvoiceSchema;
  upsertReaction: IVBillReactionsItemResponse;
};

export type IVBillMutationAddAllInvoicesToBatchArgs = {
  batchId: Scalars["Int"]["input"];
  billsFilters: IVBillBillsFiltersInput;
};

export type IVBillMutationAddCompaniesToBatchArgs = {
  batchId: Scalars["Int"]["input"];
  companyIds: Array<Scalars["String"]["input"]>;
};

export type IVBillMutationAddInvoiceCommentArgs = {
  comment: Scalars["String"]["input"];
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationAddInvoicesToBatchArgs = {
  batchId: Scalars["Int"]["input"];
  batchInvoices: Array<IVBillBatchInvoiceInput>;
};

export type IVBillMutationAddMembersToBatchArgs = {
  batchId: Scalars["Int"]["input"];
  memberIds: Array<Scalars["String"]["input"]>;
};

export type IVBillMutationCompleteInvoiceMappingArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationCompleteInvoiceOcrArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationCopyInvoiceArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationCreateBatchArgs = {
  batchData: IVBillNullableCreateBatchInput;
};

export type IVBillMutationCreateBatchPaymentsArgs = {
  payableBatch: IVBillPayableBatchInput;
};

export type IVBillMutationCreateBillsPaymentsArgs = {
  grouping?: InputMaybe<IVBillPaymentGrouping>;
  organizationId: Scalars["String"]["input"];
  payableBills: Array<IVBillPayableInvoiceInput>;
};

export type IVBillMutationCreateExpenseReportArgs = {
  input: IVBillExpenseReportInput;
  token: Scalars["String"]["input"];
};

export type IVBillMutationDeleteBatchArgs = {
  batchId: Scalars["Int"]["input"];
  shouldPreserveChildren?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IVBillMutationDeleteInvoiceArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationDeleteInvoicesArgs = {
  invoiceIds: Array<Scalars["Int"]["input"]>;
};

export type IVBillMutationMarkInvoiceAsDuplicateArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationPushInvoiceArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationRemoveCompaniesFromBatchArgs = {
  batchId: Scalars["Int"]["input"];
  companyIds: Array<Scalars["String"]["input"]>;
};

export type IVBillMutationRemoveInvoicesFromBatchArgs = {
  batchId: Scalars["Int"]["input"];
  invoiceIds: Array<Scalars["Int"]["input"]>;
};

export type IVBillMutationRemoveMembersFromBatchArgs = {
  batchId: Scalars["Int"]["input"];
  memberIds: Array<Scalars["String"]["input"]>;
};

export type IVBillMutationRevokeInvoiceArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationSplitInvoiceAttachmentsArgs = {
  options: IVBillInvoiceSplitAttachmentsInput;
};

export type IVBillMutationSplitInvoiceFileArgs = {
  fileId: Scalars["Int"]["input"];
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationStartApprovalForInvoiceArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillMutationUpdateBatchArgs = {
  batchData: IVBillNullableBatchInput;
  batchId: Scalars["Int"]["input"];
};

export type IVBillMutationUpdateBatchStatusArgs = {
  batchId: Scalars["Int"]["input"];
  batchStatus: IVBillNullableBatchStatusInput;
};

export type IVBillMutationUpdateInvoiceAndMappingsArgs = {
  invoiceData: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
  saveDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  skipAdditionalMappingsValidations: Scalars["Boolean"]["input"];
};

export type IVBillMutationUpdateInvoiceCompanyArgs = {
  updateData: IVBillInvoiceUpdateCompanyInput;
};

export type IVBillMutationUploadInvoiceArgs = {
  input: IVBillUploadInvoiceInput;
};

export type IVBillMutationUpsertReactionArgs = {
  parentKey: Scalars["String"]["input"];
  parentType: IVBillReactionParentType;
  type: IVBillReactionType;
};

export type IVBillNullableBatchInput = {
  batchDate?: InputMaybe<Scalars["String"]["input"]>;
  batchDateDue?: InputMaybe<Scalars["String"]["input"]>;
  batchFilters?: InputMaybe<IVBillBillsFiltersInput>;
  budget?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  parentBatchId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IVBillNullableBatchStatusInput = {
  status: IVBillBatchStatus;
};

export type IVBillNullableCreateBatchInput = {
  addAllBillsMatchingFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
  batchDate?: InputMaybe<Scalars["String"]["input"]>;
  batchDateDue?: InputMaybe<Scalars["String"]["input"]>;
  batchFilters?: InputMaybe<IVBillBillsFiltersInput>;
  budget?: InputMaybe<Scalars["String"]["input"]>;
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  memberIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  parentBatchId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IVBillNullableInvoiceInput = {
  additionalMapping?: InputMaybe<Array<IVBillAdditionalMappingInput>>;
  amount?: InputMaybe<Scalars["String"]["input"]>;
  emailAttachmentUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  emailHash?: InputMaybe<Scalars["String"]["input"]>;
  emailUrl?: InputMaybe<Scalars["String"]["input"]>;
  hasLineItems?: InputMaybe<Scalars["Boolean"]["input"]>;
  invoiceDate?: InputMaybe<Scalars["String"]["input"]>;
  invoiceDateDue?: InputMaybe<Scalars["String"]["input"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]["input"]>;
  lineItems?: InputMaybe<Array<IVBillLineItemInput>>;
  mainFileId?: InputMaybe<Scalars["Float"]["input"]>;
  recipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  recipientEmail?: InputMaybe<Scalars["String"]["input"]>;
  recipientName?: InputMaybe<Scalars["String"]["input"]>;
  recipientPhone?: InputMaybe<Scalars["String"]["input"]>;
  senderAddress?: InputMaybe<Scalars["String"]["input"]>;
  senderEmail?: InputMaybe<Scalars["String"]["input"]>;
  senderName?: InputMaybe<Scalars["String"]["input"]>;
  senderPhone?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillNumberWithConfidence = {
  __typename?: "NumberWithConfidence";
  confidence: Scalars["Float"]["output"];
  value: Scalars["Float"]["output"];
};

export type IVBillOrganizationInvoicesOutput = {
  __typename?: "OrganizationInvoicesOutput";
  generalStats: IVBillInvoiceStatsItemSchema;
  organizationId: Scalars["String"]["output"];
  organizationName: Scalars["String"]["output"];
  statusStats?: Maybe<IVBillInvoiceStatusStatsSchema>;
};

export type IVBillOrganizationOutput = {
  __typename?: "OrganizationOutput";
  coreOrgId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  organizationEmailHash: Scalars["String"]["output"];
  organizationName: Scalars["String"]["output"];
};

export type IVBillPageFileSchema = {
  __typename?: "PageFileSchema";
  contentType: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  movedToInvoiceId?: Maybe<Scalars["Int"]["output"]>;
  ocrText: Scalars["String"]["output"];
  ocrTextItems: Array<IVBillTextItemSchema>;
  pageCount?: Maybe<Scalars["Int"]["output"]>;
  pageNumber: Scalars["Int"]["output"];
  size: Scalars["Int"]["output"];
};

export type IVBillPageToMoveInput = {
  id?: InputMaybe<Scalars["Float"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Float"]["input"]>;
};

export type IVBillPayableBatchInput = {
  batchId: Scalars["Float"]["input"];
};

export type IVBillPayableInvoiceInput = {
  invoiceId: Scalars["Float"]["input"];
  invoiceRelation?: InputMaybe<Scalars["String"]["input"]>;
  memo?: InputMaybe<Scalars["String"]["input"]>;
  paymentAmount?: InputMaybe<Scalars["String"]["input"]>;
  paymentFromGlAccount?: InputMaybe<IVBillPaymentAccountInput>;
};

export type IVBillPayableInvoiceOutput = {
  __typename?: "PayableInvoiceOutput";
  invoiceId: Scalars["Float"]["output"];
  invoiceNumber: Scalars["String"]["output"];
  invoiceRelationType?: Maybe<Scalars["String"]["output"]>;
  memo?: Maybe<Scalars["String"]["output"]>;
  paymentAmount: Scalars["String"]["output"];
};

export type IVBillPaymentAccount = {
  __typename?: "PaymentAccount";
  coreCompanyId: Scalars["String"]["output"];
  coreOrgId: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  label2?: Maybe<Scalars["String"]["output"]>;
  parentLabel?: Maybe<Scalars["String"]["output"]>;
  settingsKey: Scalars["String"]["output"];
  source?: Maybe<Scalars["String"]["output"]>;
  storeType: IVBillStoreIoDataType;
};

export type IVBillPaymentAccountInput = {
  coreCompanyId?: InputMaybe<Scalars["String"]["input"]>;
  coreOrgId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  label2?: InputMaybe<Scalars["String"]["input"]>;
  parentLabel?: InputMaybe<Scalars["String"]["input"]>;
  settingsKey: Scalars["String"]["input"];
  source?: InputMaybe<Scalars["String"]["input"]>;
  storeType: IVBillStoreIoDataType;
};

export type IVBillPaymentDetailsOutput = {
  __typename?: "PaymentDetailsOutput";
  batch?: Maybe<IVBillBatchOutput>;
  batchId?: Maybe<Scalars["Int"]["output"]>;
  companyId: Scalars["String"]["output"];
  integrationInfo: IVBillPaymentIntegrationInfo;
  payableInvoices: Array<IVBillPayableInvoiceOutput>;
  paymentAmount: Scalars["String"]["output"];
  paymentDate: Scalars["String"]["output"];
  paymentError?: Maybe<IVBillErrorOutput>;
  paymentExternalRef?: Maybe<Scalars["String"]["output"]>;
  paymentExternalUrl?: Maybe<Scalars["String"]["output"]>;
  paymentFromGlAccount: IVBillPaymentAccount;
  paymentId: Scalars["Int"]["output"];
  paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
  paymentPushStatus: IVBillPaymentPushStatus;
  paymentStatus?: Maybe<IVBillPaymentStatus>;
  paymentToVendorAccount: IVBillPaymentAccount;
  submittedByUserAccount?: Maybe<IVBillPaymentSubmittedByUserAccount>;
  vCheckId?: Maybe<Scalars["String"]["output"]>;
  vCheckNumber?: Maybe<Scalars["String"]["output"]>;
  vCheckPaymentType?: Maybe<IVBillVCheckPaymentType>;
  vCheckState?: Maybe<IVBillVCheckState>;
};

export type IVBillPaymentGrouping = {
  byPayerAndPayee?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IVBillPaymentIntegrationInfo = {
  __typename?: "PaymentIntegrationInfo";
  active: Scalars["Boolean"]["output"];
  storeSource: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export enum IVBillPaymentIntegrationStatus {
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Pending = "PENDING",
  Unknown = "UNKNOWN",
  Voided = "VOIDED",
}

export type IVBillPaymentListFiltersInput = {
  amountFrom?: InputMaybe<Scalars["String"]["input"]>;
  amountTo?: InputMaybe<Scalars["String"]["input"]>;
  batch_id_in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  hasVCheck?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  integrationStatus?: InputMaybe<IVBillPaymentIntegrationStatus>;
  mappings?: InputMaybe<Array<IVBillPaymentsFiltersMappingInput>>;
  paymentIds?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  paymentStatus?: InputMaybe<Array<IVBillPaymentsGroupingStatus>>;
  payment_amount_sort?: InputMaybe<IVBillIPaymentFiltersSortValues>;
  payment_date_sort?: InputMaybe<IVBillIPaymentFiltersSortValues>;
  payment_id_sort?: InputMaybe<IVBillIPaymentFiltersSortValues>;
  payment_status_sort?: InputMaybe<IVBillIPaymentFiltersSortValues>;
  pushStatus?: InputMaybe<IVBillPaymentPushStatus>;
  search?: InputMaybe<IVBillPaymentListFiltersSearchInput>;
};

export type IVBillPaymentListFiltersSearchInput = {
  context: IVBillIPaymentSearchFilterContext;
  term: Scalars["String"]["input"];
  vCheckPaymentType?: InputMaybe<IVBillVCheckPaymentType>;
  vCheckState?: InputMaybe<IVBillVCheckState>;
};

export type IVBillPaymentListOutput = {
  __typename?: "PaymentListOutput";
  batchId?: Maybe<Scalars["Int"]["output"]>;
  companyId: Scalars["String"]["output"];
  paymentAmount: Scalars["String"]["output"];
  paymentDate: Scalars["String"]["output"];
  paymentError?: Maybe<IVBillErrorOutput>;
  paymentExternalRef?: Maybe<Scalars["String"]["output"]>;
  paymentExternalUrl?: Maybe<Scalars["String"]["output"]>;
  paymentFromGlAccount: IVBillPaymentAccount;
  paymentId: Scalars["Int"]["output"];
  paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
  paymentPushStatus: IVBillPaymentPushStatus;
  paymentStatus?: Maybe<IVBillPaymentStatus>;
  paymentToVendorAccount: IVBillPaymentAccount;
  submittedByUserAccount?: Maybe<IVBillPaymentSubmittedByUserAccount>;
  vCheckId?: Maybe<Scalars["String"]["output"]>;
  vCheckNumber?: Maybe<Scalars["String"]["output"]>;
  vCheckPaymentType?: Maybe<IVBillVCheckPaymentType>;
  vCheckState?: Maybe<IVBillVCheckState>;
};

export type IVBillPaymentListPaginatedOutput = {
  __typename?: "PaymentListPaginatedOutput";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillPaymentListOutput>;
  stats?: Maybe<IVBillPaymentsStatsOutput>;
  total: Scalars["Int"]["output"];
};

export type IVBillPaymentPaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum IVBillPaymentPushStatus {
  Pending = "PENDING",
  SubmittedError = "SUBMITTED_ERROR",
  SubmittedOk = "SUBMITTED_OK",
}

export enum IVBillPaymentStatus {
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Error = "ERROR",
  Pending = "PENDING",
  Voided = "VOIDED",
}

export type IVBillPaymentSubmittedByUserAccount = {
  __typename?: "PaymentSubmittedByUserAccount";
  organizationUserId: Scalars["String"]["output"];
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["String"]["output"];
  userName?: Maybe<Scalars["String"]["output"]>;
  userPhoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillPaymentsFiltersMappingInput = {
  key: Scalars["String"]["input"];
  valuesIn: Array<IVBillPaymentsFiltersMappingValueInput>;
};

export type IVBillPaymentsFiltersMappingValueInput = {
  source?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<IVBillStoreIoDataType>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type IVBillPaymentsGeneralStatsOutput = {
  __typename?: "PaymentsGeneralStatsOutput";
  amount: Scalars["String"]["output"];
  count: Scalars["String"]["output"];
};

export type IVBillPaymentsGroupStatsOutput = {
  __typename?: "PaymentsGroupStatsOutput";
  amount: Scalars["String"]["output"];
  count: Scalars["String"]["output"];
  groupingType: IVBillPaymentsGroupingType;
  maxDate?: Maybe<Scalars["String"]["output"]>;
  minDate?: Maybe<Scalars["String"]["output"]>;
  value: IVBillPaymentsGroupingValue;
};

export enum IVBillPaymentsGroupingStatus {
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Error = "ERROR",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Voided = "VOIDED",
}

export enum IVBillPaymentsGroupingType {
  All = "ALL",
  Status = "STATUS",
}

export type IVBillPaymentsGroupingValue = {
  __typename?: "PaymentsGroupingValue";
  ALL?: Maybe<Scalars["String"]["output"]>;
  STATUS?: Maybe<IVBillPaymentsGroupingStatus>;
};

export type IVBillPaymentsStats = {
  __typename?: "PaymentsStats";
  count: Scalars["Int"]["output"];
  maxDate?: Maybe<Scalars["String"]["output"]>;
  minDate?: Maybe<Scalars["String"]["output"]>;
  sum: Scalars["String"]["output"];
};

export type IVBillPaymentsStatsOutput = {
  __typename?: "PaymentsStatsOutput";
  generalStats: IVBillPaymentsGeneralStatsOutput;
  groupStats: Array<IVBillPaymentsGroupStatsOutput>;
};

export enum IVBillPermission {
  MapCompany = "MAP_COMPANY",
  MapData = "MAP_DATA",
  MapOcr = "MAP_OCR",
  StartApproval = "START_APPROVAL",
}

export type IVBillQuery = {
  __typename?: "Query";
  checkInvoiceForDuplicates: Array<IVBillRelatedInvoiceSchema>;
  companies: IVBillCompaniesResponse;
  companiesSettings: IVBillIntegrationSettingsOutput;
  files: IVBillFilePaginatedResponse;
  getBatchCompanies: IVBillBatchCompanyListStatusResponse;
  getBatchList: IVBillBatchListResponse;
  getBatchListStats: IVBillBatchStatsResponse;
  getBatchMembers: IVBillBatchMemberListStatusResponse;
  getBills: IVBillBillsResponse;
  getBillsStats: IVBillBillsStatsOutput;
  getExpenseDisplayInfo: IVBillExpenseDisplayInfoOutput;
  getExpenseToken: IVBillExpenseEntityInfoOutput;
  getReactionsList: IVBillReactionsListResponse;
  getReactionsStats: IVBillReactionsStatsResponse;
  getStoreItem: IVBillStoreItemDetailsSchema;
  invoice: IVBillInvoiceSchema;
  invoices: IVBillInvoicesResponse;
  organizationsInvoices: Array<IVBillOrganizationInvoicesOutput>;
  payment: IVBillPaymentDetailsOutput;
  payments: IVBillPaymentListPaginatedOutput;
  /** @deprecated Use invoices query instead */
  queuedInvoices: IVBillInvoicesResponse;
  relatedInvoices: Array<IVBillRelatedInvoiceSchema>;
  session: IVBillSessionOutput;
  settings: IVBillIntegrationSettingsOutput;
  storeItemsForCompany: Array<IVBillStoreItemSchema>;
  storeItemsForOrganization: Array<IVBillStoreItemSchema>;
  validateInvoice: IVBillInvoiceSchema;
};

export type IVBillQueryCheckInvoiceForDuplicatesArgs = {
  invoiceData: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillQueryCompaniesArgs = {
  organizationId: Scalars["String"]["input"];
};

export type IVBillQueryCompaniesSettingsArgs = {
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  organizationId: Scalars["String"]["input"];
};

export type IVBillQueryFilesArgs = {
  filters: IVBillFileFiltersInput;
  pagination?: InputMaybe<IVBillFilePaginationInput>;
};

export type IVBillQueryGetBatchCompaniesArgs = {
  batchId: Scalars["Int"]["input"];
};

export type IVBillQueryGetBatchListArgs = {
  filters: IVBillBatchListFiltersInput;
  grouping?: InputMaybe<IVBillBatchListStatsGroupingInput>;
  pagination: IVBillBatchPaginationInput;
};

export type IVBillQueryGetBatchListStatsArgs = {
  filters: IVBillBatchListFiltersInput;
  grouping?: InputMaybe<IVBillBatchListStatsGroupingInput>;
};

export type IVBillQueryGetBatchMembersArgs = {
  batchId: Scalars["Int"]["input"];
};

export type IVBillQueryGetBillsArgs = {
  filters: IVBillBillsFiltersInput;
  pagination: IVBillInvoicePaginationInput;
};

export type IVBillQueryGetBillsStatsArgs = {
  filters: IVBillBillsFiltersInput;
  grouping?: InputMaybe<IVBillBillsStatsGroupingInput>;
};

export type IVBillQueryGetExpenseDisplayInfoArgs = {
  input: IVBillExpenseDisplayInfoParamsInput;
};

export type IVBillQueryGetExpenseTokenArgs = {
  input: IVBillExpenseTokenParamsInput;
};

export type IVBillQueryGetReactionsListArgs = {
  filters: IVBillReactionsFiltersInput;
  pagination: IVBillReactionPaginationInput;
};

export type IVBillQueryGetReactionsStatsArgs = {
  filters: Array<IVBillReactionsFiltersInput>;
};

export type IVBillQueryGetStoreItemArgs = {
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  organizationId: Scalars["String"]["input"];
  source?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillStoreIoDataType;
};

export type IVBillQueryInvoiceArgs = {
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillQueryInvoicesArgs = {
  filters: IVBillInvoicesFiltersInput;
  pagination?: InputMaybe<IVBillInvoicePaginationInput>;
};

export type IVBillQueryOrganizationsInvoicesArgs = {
  organizationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type IVBillQueryPaymentArgs = {
  organizationId: Scalars["String"]["input"];
  paymentId: Scalars["Int"]["input"];
};

export type IVBillQueryPaymentsArgs = {
  filters?: InputMaybe<IVBillPaymentListFiltersInput>;
  organizationId: Scalars["String"]["input"];
  pagination?: InputMaybe<IVBillPaymentPaginationInput>;
};

export type IVBillQueryQueuedInvoicesArgs = {
  filters: IVBillInvoicesFiltersInput;
  pagination: IVBillInvoicePaginationInput;
};

export type IVBillQueryRelatedInvoicesArgs = {
  filters?: InputMaybe<IVBillRelatedInvoiceFiltersInput>;
  invoiceId: Scalars["Int"]["input"];
};

export type IVBillQuerySettingsArgs = {
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IVBillQueryStoreItemsForCompanyArgs = {
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: Scalars["String"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  settingsKey?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<IVBillStoreIoDataType>;
  typeKeyIn?: InputMaybe<Array<IVBillStoreItemTypeKeyInput>>;
};

export type IVBillQueryStoreItemsForOrganizationArgs = {
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  keySourcePairs?: InputMaybe<Array<IVBillStoreKeySourcePair>>;
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  type: IVBillStoreIoDataType;
};

export type IVBillQueryValidateInvoiceArgs = {
  invoiceData: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
  skipAdditionalMappingsValidations?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IVBillReactionPaginationInput = {
  page: Scalars["Float"]["input"];
  per_page?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum IVBillReactionParentType {
  Vbill = "VBILL",
  VbillBatch = "VBILL_BATCH",
}

export enum IVBillReactionType {
  Dislike = "DISLIKE",
  Like = "LIKE",
  Null = "NULL",
}

export type IVBillReactionsFiltersInput = {
  parentKey?: InputMaybe<Scalars["String"]["input"]>;
  parentType?: InputMaybe<IVBillReactionParentType>;
};

export type IVBillReactionsGroupStatsGroupSchema = {
  __typename?: "ReactionsGroupStatsGroupSchema";
  count?: Maybe<Scalars["Int"]["output"]>;
  includesMyReaction: Scalars["Boolean"]["output"];
  type?: Maybe<IVBillReactionType>;
};

export type IVBillReactionsGroupStatsSchema = {
  __typename?: "ReactionsGroupStatsSchema";
  includesMyReaction: Scalars["Boolean"]["output"];
  parentKey?: Maybe<Scalars["String"]["output"]>;
  parentType?: Maybe<IVBillReactionParentType>;
  stats: Array<IVBillReactionsGroupStatsGroupSchema>;
};

export type IVBillReactionsItemResponse = {
  __typename?: "ReactionsItemResponse";
  changedByAccountId: Scalars["String"]["output"];
  changedByEmailAddress: Scalars["String"]["output"];
  changedByName: Scalars["String"]["output"];
  changedByOrganizationUserId: Scalars["String"]["output"];
  type: IVBillReactionType;
};

export type IVBillReactionsListResponse = {
  __typename?: "ReactionsListResponse";
  hasMore: Scalars["Boolean"]["output"];
  items: Array<IVBillReactionsItemResponse>;
  total: Scalars["Int"]["output"];
};

export type IVBillReactionsStatsResponse = {
  __typename?: "ReactionsStatsResponse";
  groups?: Maybe<Array<IVBillReactionsGroupStatsSchema>>;
  includesMyReaction: Scalars["Boolean"]["output"];
  total: Scalars["Int"]["output"];
};

export type IVBillRelatedInvoiceFiltersInput = {
  status?: InputMaybe<IVBillInvoiceRelationStatus>;
  type?: InputMaybe<Array<IVBillInvoiceRelationType>>;
};

export type IVBillRelatedInvoiceSchema = {
  __typename?: "RelatedInvoiceSchema";
  invoice: IVBillInvoiceSchema;
  relation?: Maybe<IVBillInvoiceRelationSchema>;
};

export type IVBillSessionAccountOutput = {
  __typename?: "SessionAccountOutput";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export type IVBillSessionOutput = {
  __typename?: "SessionOutput";
  account: IVBillSessionAccountOutput;
  hasPermissions: Array<IVBillPermission>;
};

export enum IVBillSortOrderType {
  Asc = "ASC",
  Desc = "DESC",
}

export type IVBillStoreIoDataContactAddressOutput = {
  __typename?: "StoreIoDataContactAddressOutput";
  address?: Maybe<Scalars["String"]["output"]>;
  address_line_2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  zip_code?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillStoreIoDataContactEmailOutput = {
  __typename?: "StoreIoDataContactEmailOutput";
  description?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillStoreIoDataContactOutput = {
  __typename?: "StoreIoDataContactOutput";
  address?: Maybe<Scalars["String"]["output"]>;
  addressList?: Maybe<Array<IVBillStoreIoDataContactAddressOutput>>;
  email?: Maybe<Scalars["String"]["output"]>;
  emailList?: Maybe<Array<IVBillStoreIoDataContactEmailOutput>>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  phoneList?: Maybe<Array<IVBillStoreIoDataContactPhoneNumberOutput>>;
};

export type IVBillStoreIoDataContactPhoneNumberOutput = {
  __typename?: "StoreIoDataContactPhoneNumberOutput";
  description?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillStoreIoDataGlAccountOutput = {
  __typename?: "StoreIoDataGlAccountOutput";
  accountNo?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillStoreIoDataOutput = {
  __typename?: "StoreIoDataOutput";
  contact?: Maybe<IVBillStoreIoDataContactOutput>;
  glAccount?: Maybe<IVBillStoreIoDataGlAccountOutput>;
};

export enum IVBillStoreIoDataType {
  Contact = "contact",
  Employee = "employee",
  FakeCategory = "fakeCategory",
  FakeLocation = "fakeLocation",
  GlAccount = "glAccount",
  PeoplesoftBankAccount = "peoplesoftBankAccount",
  PeoplesoftCustomField1 = "peoplesoftCustomField1",
  PeoplesoftCustomField2 = "peoplesoftCustomField2",
  PeoplesoftCustomField3 = "peoplesoftCustomField3",
  PeoplesoftCustomField4 = "peoplesoftCustomField4",
  PeoplesoftPayGroup = "peoplesoftPayGroup",
  PeoplesoftPayTerm = "peoplesoftPayTerm",
  QbdCustomer = "qbdCustomer",
  QbdSalesTerm = "qbdSalesTerm",
  QboCustomer = "qboCustomer",
  QboProduct = "qboProduct",
  QboSalesTerm = "qboSalesTerm",
  SageAccountAllocation = "sageAccountAllocation",
  SageAllocation = "sageAllocation",
  SageApTerm = "sageApTerm",
  SageBankAccount = "sageBankAccount",
  SageDepartment = "sageDepartment",
  SageEntity = "sageEntity",
  SageGlAllocation = "sageGlAllocation",
  SageLocation = "sageLocation",
  SagePaymentMethod = "sagePaymentMethod",
  SageUserInfo = "sageUserInfo",
}

export type IVBillStoreIoMetaItemOutput = {
  __typename?: "StoreIoMetaItemOutput";
  label?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type IVBillStoreItemDetailsSchema = {
  __typename?: "StoreItemDetailsSchema";
  data?: Maybe<IVBillStoreItemSchema>;
};

export type IVBillStoreItemSchema = {
  __typename?: "StoreItemSchema";
  companyId?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<IVBillStoreIoDataOutput>;
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  label2?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Array<IVBillStoreIoMetaItemOutput>>;
  organizationId: Scalars["String"]["output"];
  parentLabel?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  type: IVBillStoreIoDataType;
};

export type IVBillStoreItemTypeKeyInput = {
  key: Scalars["String"]["input"];
  type: IVBillStoreIoDataType;
};

export type IVBillStoreKeySourcePair = {
  key: Scalars["String"]["input"];
  source: Scalars["String"]["input"];
};

export type IVBillStringWithConfidence = {
  __typename?: "StringWithConfidence";
  confidence: Scalars["Float"]["output"];
  value: Scalars["String"]["output"];
};

export enum IVBillTemporality {
  After = "AFTER",
  Before = "BEFORE",
}

export type IVBillTextItemBBoxSchema = {
  __typename?: "TextItemBBoxSchema";
  x0: Scalars["Float"]["output"];
  x1: Scalars["Float"]["output"];
  y0: Scalars["Float"]["output"];
  y1: Scalars["Float"]["output"];
};

export type IVBillTextItemBaseLineSchema = {
  __typename?: "TextItemBaseLineSchema";
  hasBaseLine: Scalars["Boolean"]["output"];
  x0: Scalars["Float"]["output"];
  x1: Scalars["Float"]["output"];
  y0: Scalars["Float"]["output"];
  y1: Scalars["Float"]["output"];
};

export type IVBillTextItemSchema = {
  __typename?: "TextItemSchema";
  bBox?: Maybe<IVBillTextItemBBoxSchema>;
  baseLine?: Maybe<IVBillTextItemBaseLineSchema>;
  direction?: Maybe<Scalars["String"]["output"]>;
  fontName?: Maybe<Scalars["String"]["output"]>;
  fontSize?: Maybe<Scalars["Float"]["output"]>;
  hasEOL?: Maybe<Scalars["Boolean"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  isBold?: Maybe<Scalars["Boolean"]["output"]>;
  isItalic?: Maybe<Scalars["Boolean"]["output"]>;
  isMonospace?: Maybe<Scalars["Boolean"]["output"]>;
  isNumeric?: Maybe<Scalars["Boolean"]["output"]>;
  isSerif?: Maybe<Scalars["Boolean"]["output"]>;
  isSmallcaps?: Maybe<Scalars["Boolean"]["output"]>;
  isUnderlined?: Maybe<Scalars["Boolean"]["output"]>;
  processor?: Maybe<IVBillKAutoocrContentProcessor>;
  text?: Maybe<Scalars["String"]["output"]>;
  transform?: Maybe<Array<Scalars["Float"]["output"]>>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

export enum IVBillTypeOfValue {
  Boolean = "boolean",
  Datetime = "datetime",
  Json = "json",
  Numeric = "numeric",
  Text = "text",
}

export type IVBillUploadInvoiceInput = {
  attachmentKeys: Array<Scalars["String"]["input"]>;
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  organizationId: Scalars["String"]["input"];
};

export enum IVBillVCheckPaymentType {
  Ach = "ACH",
  Card = "CARD",
  Print = "PRINT",
}

export enum IVBillVCheckState {
  Blocked = "BLOCKED",
  Cancelled = "CANCELLED",
  Deleted = "DELETED",
  Deposited = "DEPOSITED",
  PendingSignature = "PENDING_SIGNATURE",
  Processing = "PROCESSING",
  Signed = "SIGNED",
}

export type IVBillValidationErrorsOutput = {
  __typename?: "ValidationErrorsOutput";
  invoice: IVBillErInvoice;
  lineItems: Array<IVBillErLineItem>;
};

export type IVBillInvoiceFragmentFragment = {
  __typename?: "InvoiceSchema";
  id: number;
  hashedId: string;
  coreOrgId: string;
  companyId?: string | null;
  createdAt: string;
  integrationStatus?: IVBillInvoiceIntegrationStatus | null;
  actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
  expenseType?: IVBillExpenseType | null;
  status: IVBillInvoiceIncomingStatus;
  filesOcrState?: IVBillKInvoiceFilesOcrState | null;
  pushStatus?: IVBillInvoicePushStatus | null;
  emailSubject?: string | null;
  emailDate?: string | null;
  emailUrl?: string | null;
  emailAttachmentUrls?: Array<string> | null;
  emailBodyText?: string | null;
  hasLineItems: boolean;
  initialSenderName?: string | null;
  initialSenderEmail?: string | null;
  senderName?: string | null;
  senderAddress?: string | null;
  senderEmail?: string | null;
  senderEmails?: Array<string> | null;
  senderPhone?: string | null;
  recipientName?: string | null;
  recipientAddress?: string | null;
  recipientEmail?: string | null;
  recipientEmails?: Array<string> | null;
  recipientPhone?: string | null;
  invoiceNumber?: string | null;
  invoiceDate?: string | null;
  invoiceDateDue?: string | null;
  integrationRef?: string | null;
  amount: string;
  approvalCount?: number | null;
  canBePaid?: boolean | null;
  isPotentialDuplicate?: boolean | null;
  suggestedMainFileId?: string | null;
  company?: {
    __typename?: "CompanySchema";
    id: number;
    companyId: string;
    orgId: string;
    orgName: string;
    emailHash: string;
    name: string;
  } | null;
  expenseReport?: {
    __typename?: "ExpenseReportOutput";
    expenseDate: string;
    employeeId?: string | null;
    reasonForExpense?: string | null;
    attachmentKeys?: Array<string> | null;
    paymentMethod: IVBillExpensePaymentMethod;
    amount: string;
    id: string;
    createdAt: string;
    expenseReportNumber: string;
    payee: {
      __typename?: "ExpensePayeeOutput";
      email?: string | null;
      bankName?: string | null;
      bankAccountType?: IVBillBankAccountType | null;
      bankAccountNumber?: string | null;
      bankRoutingNumber?: string | null;
      addressStreet?: string | null;
      addressCity?: string | null;
      addressState?: string | null;
      addressPostalCode?: string | null;
      uid: string;
    };
    additionalFields?: Array<{
      __typename?: "AbstractExpenseAdditionalField";
      type: string;
      key: string;
      label?: string | null;
      value: string;
      typeOfValue: IVBillTypeOfValue;
      order?: number | null;
    }> | null;
  } | null;
  statusFlags: {
    __typename?: "InvoiceStatusFlagsSchema";
    isOCRComplete: boolean;
    isMappingComplete: boolean;
    canBeEdited: boolean;
  };
  approvalStatus?: {
    __typename?: "ApprovalStatusSchema";
    isApproved: boolean;
    potentialApprovers: Array<string>;
    approvedBy: Array<string>;
    potentialReviewers: Array<string>;
    reviewedBy: Array<string>;
    declinedBy: Array<string>;
  } | null;
  lineItems?: Array<{
    __typename?: "LineItemSchema";
    id: number;
    invoiceId: number;
    description?: string | null;
    um?: string | null;
    qty?: string | null;
    rate?: string | null;
    amount: string;
    type?: IVBillLineItemType | null;
    glMappings?: Array<{
      __typename?: "LineItemGLMappingSchema";
      id: number;
      lineItemId: number;
      qty: number;
      additionalMappings?: Array<{
        __typename?: "AdditionalMappingSchema";
        key: string;
        value: string;
        type: IVBillStoreIoDataType;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      }> | null;
    }> | null;
  }> | null;
  payments?: Array<{
    __typename?: "InvoicePaymentOutput";
    paymentId: number;
    companyId: string;
    paymentPushStatus: IVBillPaymentPushStatus;
    paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
    paymentAmount: string;
    paymentDate: string;
    vCheckNumber?: string | null;
    vCheckId?: string | null;
    paymentExternalRef?: string | null;
    paymentExternalUrl?: string | null;
    batchId?: number | null;
    invoiceId: number;
    invoiceRelationType?: string | null;
    memo?: string | null;
    submittedByUserAccount?: {
      __typename?: "PaymentSubmittedByUserAccount";
      userId: string;
      organizationUserId: string;
      userName?: string | null;
      userEmail?: string | null;
      userPhoneNumber?: string | null;
    } | null;
    paymentFromGlAccount: {
      __typename?: "PaymentAccount";
      key: string;
      settingsKey: string;
      storeType: IVBillStoreIoDataType;
      source?: string | null;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    };
    paymentToVendorAccount: {
      __typename?: "PaymentAccount";
      key: string;
      settingsKey: string;
      storeType: IVBillStoreIoDataType;
      source?: string | null;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    };
    paymentError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
  }> | null;
  amounts?: {
    __typename?: "InvoiceAmounts";
    amount: string;
    amountInPayments: string;
    amountInBatches: string;
    amountFreeToPay: string;
    amountInBatchesBreakdown: Array<{
      __typename?: "InvoiceAmountInBatchDetail";
      batchId: number;
      invoiceId: number;
      batchAmountForInvoice: string;
      memo?: string | null;
    }>;
    paymentsStats: {
      __typename?: "InvoicePaymentsStats";
      successful: {
        __typename?: "PaymentsStats";
        count: number;
        sum: string;
        minDate?: string | null;
        maxDate?: string | null;
      };
      failed: {
        __typename?: "PaymentsStats";
        count: number;
        sum: string;
        minDate?: string | null;
        maxDate?: string | null;
      };
      pending: {
        __typename?: "PaymentsStats";
        count: number;
        sum: string;
        minDate?: string | null;
        maxDate?: string | null;
      };
    };
  } | null;
  files?: Array<{
    __typename?: "InvoiceFileSchema";
    id: number;
    invoiceId: number;
    fileOcrState: IVBillKFileOcrState;
    fileIsEncrypted?: boolean | null;
    name?: string | null;
    size?: number | null;
    pageCount?: number | null;
    movedToInvoice?: {
      __typename?: "InvoiceSchema";
      id: number;
      status: IVBillInvoiceIncomingStatus;
      invoiceNumber?: string | null;
    } | null;
    pages?: Array<{
      __typename?: "AbstractPageFileOutput";
      id?: number | null;
      movedToInvoiceId?: number | null;
      pageNumber?: number | null;
      contentType?: string | null;
      size?: number | null;
    }> | null;
    metadata?: {
      __typename?: "FileMetadataSchema";
      OPENAI?: {
        __typename?: "InvoiceSuggestionsMetadata";
        amount?: number | null;
        invoiceNumber?: string | null;
        invoiceDate?: string | null;
        invoiceDateDue?: string | null;
        senderName?: string | null;
        senderAddress?: string | null;
        senderEmail?: string | null;
        senderPhone?: string | null;
        recipientName?: string | null;
        recipientAddress?: string | null;
        recipientEmail?: string | null;
        recipientPhone?: string | null;
        lineItems: Array<{
          __typename?: "InvoiceLineItemMetaSchema";
          type?: IVBillLineItemType | null;
          confidence: number;
          amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
          um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
          qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
        }>;
      } | null;
      GDAI?: {
        __typename?: "InvoiceSuggestionsMetadata";
        amount?: number | null;
        invoiceNumber?: string | null;
        invoiceDate?: string | null;
        invoiceDateDue?: string | null;
        senderName?: string | null;
        senderAddress?: string | null;
        senderEmail?: string | null;
        senderPhone?: string | null;
        recipientName?: string | null;
        recipientAddress?: string | null;
        recipientEmail?: string | null;
        recipientPhone?: string | null;
        lineItems: Array<{
          __typename?: "InvoiceLineItemMetaSchema";
          type?: IVBillLineItemType | null;
          confidence: number;
          amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
          um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
          qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
        }>;
      } | null;
      AOCR?: {
        __typename?: "InvoiceSuggestionsMetadata";
        invoiceNumber?: string | null;
        invoiceDate?: string | null;
        invoiceDateDue?: string | null;
        senderName?: string | null;
        senderAddress?: string | null;
        senderEmail?: string | null;
        senderPhone?: string | null;
        recipientName?: string | null;
        recipientAddress?: string | null;
        recipientEmail?: string | null;
        recipientPhone?: string | null;
        amount?: number | null;
      } | null;
    } | null;
  }> | null;
  mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
  actions?: Array<{
    __typename?: "InvoiceActionLogSchema";
    id: number;
    createdAt: string;
    key: string;
    type: IVBillIShtarActionLogObjectType;
    initiator: string;
    scope: string;
    action: string;
    level: string;
    path?: Array<{
      __typename?: "InvoiceActionLogPathOrRelatedSchema";
      key: string;
      type: IVBillIShtarActionLogObjectType;
      label?: string | null;
    }> | null;
    related?: Array<{
      __typename?: "InvoiceActionLogPathOrRelatedSchema";
      key: string;
      type: IVBillIShtarActionLogObjectType;
      label?: string | null;
    }> | null;
    changedBy: {
      __typename?: "InvoiceActionLogChangedBy";
      accountId?: string | null;
      organizationUserId?: string | null;
      name?: string | null;
    };
    meta?: {
      __typename?: "InvoiceActionLogMetaSchema";
      content?: {
        __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
        type: IVBillIShtarActionLogMetaContentBlockMessageType;
        message?: string | null;
        template?: string | null;
        bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
        related?: Array<{
          __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
          key: string;
          related: {
            __typename?: "InvoiceActionLogPathOrRelatedSchema";
            key: string;
            type: IVBillIShtarActionLogObjectType;
            label?: string | null;
          };
        }> | null;
      } | null;
      additionalContent?: Array<{
        __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
        type: IVBillIShtarActionLogMetaContentBlockMessageType;
        message?: string | null;
        template?: string | null;
        bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
        related?: Array<{
          __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
          key: string;
          related: {
            __typename?: "InvoiceActionLogPathOrRelatedSchema";
            key: string;
            type: IVBillIShtarActionLogObjectType;
            label?: string | null;
          };
        }> | null;
        columns?: Array<{
          __typename?: "InvoiceActionLogMetaContentBlockTableCell";
          icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
        }> | null;
        rows?: Array<{
          __typename?: "InvoiceActionLogMetaContentBlockTableRow";
          cells?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
        }> | null;
      }> | null;
    } | null;
  }> | null;
  approvals?: Array<{
    __typename?: "InvoiceApprovalSchema";
    id: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    orgUserId: string;
    status: IVBillInvoiceApprovalStatus;
    invoiceId: number;
  }> | null;
  additionalMappings?: Array<{
    __typename?: "AdditionalMappingSchema";
    key: string;
    value: string;
    type: IVBillStoreIoDataType;
    label?: string | null;
    label2?: string | null;
    parentLabel?: string | null;
  }> | null;
  syncError?: {
    __typename?: "ErrorOutput";
    message: string;
    type: IVBillGqlErrorTypes;
    codes?: Array<string> | null;
  } | null;
  displayError?: {
    __typename?: "ErrorOutput";
    message: string;
    type: IVBillGqlErrorTypes;
    codes?: Array<string> | null;
  } | null;
  nextUserActions: Array<{
    __typename?: "InvoiceUserActionSchema";
    type: IVBillInvoiceUserActionType;
    showIn: IVBillInvoiceUserActionShowIn;
    displayName: string;
    description: string;
  }>;
};

export type IVBillInvoiceListFragmentFragment = {
  __typename?: "InvoiceLightSchema";
  id: number;
  hashedId: string;
  coreOrgId: string;
  companyId?: string | null;
  createdAt: string;
  status: IVBillInvoiceIncomingStatus;
  pushStatus?: IVBillInvoicePushStatus | null;
  emailSubject?: string | null;
  emailDate?: string | null;
  emailUrl?: string | null;
  emailAttachmentUrls?: Array<string> | null;
  emailBodyText?: string | null;
  expenseType?: IVBillExpenseType | null;
  initialSenderName?: string | null;
  initialSenderEmail?: string | null;
  senderName?: string | null;
  senderAddress?: string | null;
  senderEmail?: string | null;
  senderEmails?: Array<string> | null;
  senderPhone?: string | null;
  recipientName?: string | null;
  recipientAddress?: string | null;
  recipientEmail?: string | null;
  recipientEmails?: Array<string> | null;
  recipientPhone?: string | null;
  invoiceNumber?: string | null;
  invoiceDate?: string | null;
  invoiceDateDue?: string | null;
  mainFileId?: string | null;
  suggestedMainFileId?: string | null;
  filesOcrState?: IVBillKInvoiceFilesOcrState | null;
  hasLineItems: boolean;
  approvalCount?: number | null;
  integrationRef?: string | null;
  integrationStatus?: IVBillInvoiceIntegrationStatus | null;
  actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
  amount: string;
  contactExternalRef?: string | null;
  canBePaid?: boolean | null;
  isPotentialDuplicate?: boolean | null;
  statusFlags: {
    __typename?: "InvoiceStatusFlagsSchema";
    isOCRComplete: boolean;
    isCompanyMappingComplete: boolean;
    isMappingComplete: boolean;
    canBeEdited: boolean;
  };
  expenseReport?: {
    __typename?: "AbstractExpenseReportOutput";
    expenseDate: string;
    employeeId?: string | null;
    reasonForExpense?: string | null;
    attachmentKeys?: Array<string> | null;
    amount: string;
    payee: {
      __typename?: "AbstractExpensePayeeOutput";
      email?: string | null;
      bankName?: string | null;
      bankAccountType?: IVBillBankAccountType | null;
      bankAccountNumber?: string | null;
      bankRoutingNumber?: string | null;
    };
  } | null;
  approvalStatus?: {
    __typename?: "ApprovalStatusSchema";
    potentialApprovers: Array<string>;
    approvedBy: Array<string>;
    potentialReviewers: Array<string>;
    reviewedBy: Array<string>;
    declinedBy: Array<string>;
    isApproved: boolean;
  } | null;
  additionalMappings?: Array<{
    __typename?: "AdditionalMappingSchema";
    key: string;
    value: string;
    type: IVBillStoreIoDataType;
    label?: string | null;
    label2?: string | null;
    parentLabel?: string | null;
  }> | null;
  payments?: Array<{
    __typename?: "InvoicePaymentOutput";
    paymentId: number;
    companyId: string;
    paymentPushStatus: IVBillPaymentPushStatus;
    paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
    paymentAmount: string;
    paymentDate: string;
    vCheckNumber?: string | null;
    vCheckId?: string | null;
    paymentExternalRef?: string | null;
    paymentExternalUrl?: string | null;
    batchId?: number | null;
    invoiceId: number;
    invoiceRelationType?: string | null;
    memo?: string | null;
    submittedByUserAccount?: {
      __typename?: "PaymentSubmittedByUserAccount";
      userId: string;
      organizationUserId: string;
      userName?: string | null;
      userEmail?: string | null;
      userPhoneNumber?: string | null;
    } | null;
    paymentFromGlAccount: {
      __typename?: "PaymentAccount";
      key: string;
      settingsKey: string;
      storeType: IVBillStoreIoDataType;
      source?: string | null;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    };
    paymentToVendorAccount: {
      __typename?: "PaymentAccount";
      key: string;
      settingsKey: string;
      storeType: IVBillStoreIoDataType;
      source?: string | null;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    };
    paymentError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
  }> | null;
  amounts?: {
    __typename?: "InvoiceAmounts";
    amount: string;
    amountInPayments: string;
    amountInBatches: string;
    amountFreeToPay: string;
    amountInBatchesBreakdown: Array<{
      __typename?: "InvoiceAmountInBatchDetail";
      batchId: number;
      invoiceId: number;
      batchAmountForInvoice: string;
      memo?: string | null;
    }>;
    paymentsStats: {
      __typename?: "InvoicePaymentsStats";
      successful: {
        __typename?: "PaymentsStats";
        count: number;
        sum: string;
        minDate?: string | null;
        maxDate?: string | null;
      };
      failed: {
        __typename?: "PaymentsStats";
        count: number;
        sum: string;
        minDate?: string | null;
        maxDate?: string | null;
      };
      pending: {
        __typename?: "PaymentsStats";
        count: number;
        sum: string;
        minDate?: string | null;
        maxDate?: string | null;
      };
    };
  } | null;
  syncError?: {
    __typename?: "ErrorOutput";
    message: string;
    type: IVBillGqlErrorTypes;
    codes?: Array<string> | null;
  } | null;
  displayError?: {
    __typename?: "ErrorOutput";
    message: string;
    type: IVBillGqlErrorTypes;
    codes?: Array<string> | null;
  } | null;
  nextUserActions: Array<{
    __typename?: "InvoiceUserActionSchema";
    type: IVBillInvoiceUserActionType;
    showIn: IVBillInvoiceUserActionShowIn;
    displayName: string;
    description: string;
  }>;
};

export type IVBillVBillAddCommentMutationVariables = Exact<{
  comment: Scalars["String"]["input"];
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillAddCommentMutation = {
  __typename?: "Mutation";
  addInvoiceComment: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillCheckInvoiceForDuplicatesQueryVariables = Exact<{
  invoiceData: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillCheckInvoiceForDuplicatesQuery = {
  __typename?: "Query";
  checkInvoiceForDuplicates: Array<{
    __typename?: "RelatedInvoiceSchema";
    invoice: {
      __typename?: "InvoiceSchema";
      id: number;
      hashedId: string;
      coreOrgId: string;
      companyId?: string | null;
      createdAt: string;
      integrationStatus?: IVBillInvoiceIntegrationStatus | null;
      actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
      expenseType?: IVBillExpenseType | null;
      status: IVBillInvoiceIncomingStatus;
      filesOcrState?: IVBillKInvoiceFilesOcrState | null;
      pushStatus?: IVBillInvoicePushStatus | null;
      emailSubject?: string | null;
      emailDate?: string | null;
      emailUrl?: string | null;
      emailAttachmentUrls?: Array<string> | null;
      emailBodyText?: string | null;
      hasLineItems: boolean;
      initialSenderName?: string | null;
      initialSenderEmail?: string | null;
      senderName?: string | null;
      senderAddress?: string | null;
      senderEmail?: string | null;
      senderEmails?: Array<string> | null;
      senderPhone?: string | null;
      recipientName?: string | null;
      recipientAddress?: string | null;
      recipientEmail?: string | null;
      recipientEmails?: Array<string> | null;
      recipientPhone?: string | null;
      invoiceNumber?: string | null;
      invoiceDate?: string | null;
      invoiceDateDue?: string | null;
      integrationRef?: string | null;
      amount: string;
      approvalCount?: number | null;
      canBePaid?: boolean | null;
      isPotentialDuplicate?: boolean | null;
      suggestedMainFileId?: string | null;
      company?: {
        __typename?: "CompanySchema";
        id: number;
        companyId: string;
        orgId: string;
        orgName: string;
        emailHash: string;
        name: string;
      } | null;
      expenseReport?: {
        __typename?: "ExpenseReportOutput";
        expenseDate: string;
        employeeId?: string | null;
        reasonForExpense?: string | null;
        attachmentKeys?: Array<string> | null;
        paymentMethod: IVBillExpensePaymentMethod;
        amount: string;
        id: string;
        createdAt: string;
        expenseReportNumber: string;
        payee: {
          __typename?: "ExpensePayeeOutput";
          email?: string | null;
          bankName?: string | null;
          bankAccountType?: IVBillBankAccountType | null;
          bankAccountNumber?: string | null;
          bankRoutingNumber?: string | null;
          addressStreet?: string | null;
          addressCity?: string | null;
          addressState?: string | null;
          addressPostalCode?: string | null;
          uid: string;
        };
        additionalFields?: Array<{
          __typename?: "AbstractExpenseAdditionalField";
          type: string;
          key: string;
          label?: string | null;
          value: string;
          typeOfValue: IVBillTypeOfValue;
          order?: number | null;
        }> | null;
      } | null;
      statusFlags: {
        __typename?: "InvoiceStatusFlagsSchema";
        isOCRComplete: boolean;
        isMappingComplete: boolean;
        canBeEdited: boolean;
      };
      approvalStatus?: {
        __typename?: "ApprovalStatusSchema";
        isApproved: boolean;
        potentialApprovers: Array<string>;
        approvedBy: Array<string>;
        potentialReviewers: Array<string>;
        reviewedBy: Array<string>;
        declinedBy: Array<string>;
      } | null;
      lineItems?: Array<{
        __typename?: "LineItemSchema";
        id: number;
        invoiceId: number;
        description?: string | null;
        um?: string | null;
        qty?: string | null;
        rate?: string | null;
        amount: string;
        type?: IVBillLineItemType | null;
        glMappings?: Array<{
          __typename?: "LineItemGLMappingSchema";
          id: number;
          lineItemId: number;
          qty: number;
          additionalMappings?: Array<{
            __typename?: "AdditionalMappingSchema";
            key: string;
            value: string;
            type: IVBillStoreIoDataType;
            label?: string | null;
            label2?: string | null;
            parentLabel?: string | null;
          }> | null;
        }> | null;
      }> | null;
      payments?: Array<{
        __typename?: "InvoicePaymentOutput";
        paymentId: number;
        companyId: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentAmount: string;
        paymentDate: string;
        vCheckNumber?: string | null;
        vCheckId?: string | null;
        paymentExternalRef?: string | null;
        paymentExternalUrl?: string | null;
        batchId?: number | null;
        invoiceId: number;
        invoiceRelationType?: string | null;
        memo?: string | null;
        submittedByUserAccount?: {
          __typename?: "PaymentSubmittedByUserAccount";
          userId: string;
          organizationUserId: string;
          userName?: string | null;
          userEmail?: string | null;
          userPhoneNumber?: string | null;
        } | null;
        paymentFromGlAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentToVendorAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentError?: {
          __typename?: "ErrorOutput";
          message: string;
          type: IVBillGqlErrorTypes;
          codes?: Array<string> | null;
        } | null;
      }> | null;
      amounts?: {
        __typename?: "InvoiceAmounts";
        amount: string;
        amountInPayments: string;
        amountInBatches: string;
        amountFreeToPay: string;
        amountInBatchesBreakdown: Array<{
          __typename?: "InvoiceAmountInBatchDetail";
          batchId: number;
          invoiceId: number;
          batchAmountForInvoice: string;
          memo?: string | null;
        }>;
        paymentsStats: {
          __typename?: "InvoicePaymentsStats";
          successful: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          failed: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          pending: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
        };
      } | null;
      files?: Array<{
        __typename?: "InvoiceFileSchema";
        id: number;
        invoiceId: number;
        fileOcrState: IVBillKFileOcrState;
        fileIsEncrypted?: boolean | null;
        name?: string | null;
        size?: number | null;
        pageCount?: number | null;
        movedToInvoice?: {
          __typename?: "InvoiceSchema";
          id: number;
          status: IVBillInvoiceIncomingStatus;
          invoiceNumber?: string | null;
        } | null;
        pages?: Array<{
          __typename?: "AbstractPageFileOutput";
          id?: number | null;
          movedToInvoiceId?: number | null;
          pageNumber?: number | null;
          contentType?: string | null;
          size?: number | null;
        }> | null;
        metadata?: {
          __typename?: "FileMetadataSchema";
          OPENAI?: {
            __typename?: "InvoiceSuggestionsMetadata";
            amount?: number | null;
            invoiceNumber?: string | null;
            invoiceDate?: string | null;
            invoiceDateDue?: string | null;
            senderName?: string | null;
            senderAddress?: string | null;
            senderEmail?: string | null;
            senderPhone?: string | null;
            recipientName?: string | null;
            recipientAddress?: string | null;
            recipientEmail?: string | null;
            recipientPhone?: string | null;
            lineItems: Array<{
              __typename?: "InvoiceLineItemMetaSchema";
              type?: IVBillLineItemType | null;
              confidence: number;
              amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            }>;
          } | null;
          GDAI?: {
            __typename?: "InvoiceSuggestionsMetadata";
            amount?: number | null;
            invoiceNumber?: string | null;
            invoiceDate?: string | null;
            invoiceDateDue?: string | null;
            senderName?: string | null;
            senderAddress?: string | null;
            senderEmail?: string | null;
            senderPhone?: string | null;
            recipientName?: string | null;
            recipientAddress?: string | null;
            recipientEmail?: string | null;
            recipientPhone?: string | null;
            lineItems: Array<{
              __typename?: "InvoiceLineItemMetaSchema";
              type?: IVBillLineItemType | null;
              confidence: number;
              amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            }>;
          } | null;
          AOCR?: {
            __typename?: "InvoiceSuggestionsMetadata";
            invoiceNumber?: string | null;
            invoiceDate?: string | null;
            invoiceDateDue?: string | null;
            senderName?: string | null;
            senderAddress?: string | null;
            senderEmail?: string | null;
            senderPhone?: string | null;
            recipientName?: string | null;
            recipientAddress?: string | null;
            recipientEmail?: string | null;
            recipientPhone?: string | null;
            amount?: number | null;
          } | null;
        } | null;
      }> | null;
      mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
      actions?: Array<{
        __typename?: "InvoiceActionLogSchema";
        id: number;
        createdAt: string;
        key: string;
        type: IVBillIShtarActionLogObjectType;
        initiator: string;
        scope: string;
        action: string;
        level: string;
        path?: Array<{
          __typename?: "InvoiceActionLogPathOrRelatedSchema";
          key: string;
          type: IVBillIShtarActionLogObjectType;
          label?: string | null;
        }> | null;
        related?: Array<{
          __typename?: "InvoiceActionLogPathOrRelatedSchema";
          key: string;
          type: IVBillIShtarActionLogObjectType;
          label?: string | null;
        }> | null;
        changedBy: {
          __typename?: "InvoiceActionLogChangedBy";
          accountId?: string | null;
          organizationUserId?: string | null;
          name?: string | null;
        };
        meta?: {
          __typename?: "InvoiceActionLogMetaSchema";
          content?: {
            __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
            type: IVBillIShtarActionLogMetaContentBlockMessageType;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
            related?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
              key: string;
              related: {
                __typename?: "InvoiceActionLogPathOrRelatedSchema";
                key: string;
                type: IVBillIShtarActionLogObjectType;
                label?: string | null;
              };
            }> | null;
          } | null;
          additionalContent?: Array<{
            __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
            type: IVBillIShtarActionLogMetaContentBlockMessageType;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
            related?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
              key: string;
              related: {
                __typename?: "InvoiceActionLogPathOrRelatedSchema";
                key: string;
                type: IVBillIShtarActionLogObjectType;
                label?: string | null;
              };
            }> | null;
            columns?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
            rows?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableRow";
              cells?: Array<{
                __typename?: "InvoiceActionLogMetaContentBlockTableCell";
                icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
                message?: string | null;
                template?: string | null;
                bindings?: Array<{
                  __typename?: "InvoiceActionLogMetaTemplateBindings";
                  key: string;
                  value: string;
                }> | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }> | null;
      approvals?: Array<{
        __typename?: "InvoiceApprovalSchema";
        id: number;
        createdAt?: string | null;
        updatedAt?: string | null;
        orgUserId: string;
        status: IVBillInvoiceApprovalStatus;
        invoiceId: number;
      }> | null;
      additionalMappings?: Array<{
        __typename?: "AdditionalMappingSchema";
        key: string;
        value: string;
        type: IVBillStoreIoDataType;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      }> | null;
      syncError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      displayError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      nextUserActions: Array<{
        __typename?: "InvoiceUserActionSchema";
        type: IVBillInvoiceUserActionType;
        showIn: IVBillInvoiceUserActionShowIn;
        displayName: string;
        description: string;
      }>;
    };
    relation?: {
      __typename?: "InvoiceRelationSchema";
      fromInvoiceId: number;
      toInvoiceId: number;
      type: IVBillInvoiceRelationType;
      status: IVBillInvoiceRelationStatus;
    } | null;
  }>;
};

export type IVBillVBillCompaniesQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type IVBillVBillCompaniesQuery = {
  __typename?: "Query";
  companies: {
    __typename?: "CompaniesResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
      organization?: {
        __typename?: "OrganizationOutput";
        id: number;
        coreOrgId: string;
        organizationEmailHash: string;
        organizationName: string;
      } | null;
    }>;
  };
};

export type IVBillVBillCompleteInvoiceMappingMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillCompleteInvoiceMappingMutation = {
  __typename?: "Mutation";
  completeInvoiceMapping: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillCompleteInvoiceOcrMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillCompleteInvoiceOcrMutation = {
  __typename?: "Mutation";
  completeInvoiceOCR: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillCopyInvoiceMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillCopyInvoiceMutation = {
  __typename?: "Mutation";
  copyInvoice: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillCreateBatchPaymentsMutationVariables = Exact<{
  payableBatch: IVBillPayableBatchInput;
}>;

export type IVBillVBillCreateBatchPaymentsMutation = {
  __typename?: "Mutation";
  createBatchPayments: {
    __typename?: "BatchPaymentsOutput";
    success: boolean;
    batch: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDate?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
      batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
      batchPayments: Array<{
        __typename?: "BatchPaymentOutput";
        batchId: number;
        paymentId: number;
        invoiceId: number;
        paymentAmountForBillInBatch: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentStatus: IVBillPaymentStatus;
        paymentErrors: Array<{
          __typename?: "BatchPaymentErrorOutput";
          type: IVBillBatchPaymentErrorType;
          messages: Array<string>;
        }>;
      }>;
      batchTreeDetails?: {
        __typename?: "BatchTreeDetails";
        batchIds: Array<number>;
        commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
        paymentsStats: Array<{
          __typename?: "PaymentsGroupStatsOutput";
          count: string;
          amount: string;
          groupingType: IVBillPaymentsGroupingType;
          minDate?: string | null;
          maxDate?: string | null;
          value: {
            __typename?: "PaymentsGroupingValue";
            ALL?: string | null;
            STATUS?: IVBillPaymentsGroupingStatus | null;
          };
        }>;
      } | null;
    };
    payments: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentStatus?: IVBillPaymentStatus | null;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      vCheckState?: IVBillVCheckState | null;
      vCheckPaymentType?: IVBillVCheckPaymentType | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        coreOrgId: string;
        coreCompanyId: string;
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        coreOrgId: string;
        coreCompanyId: string;
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }>;
  };
};

export type IVBillVBillCreateBillsPaymentsMutationVariables = Exact<{
  payableBills: Array<IVBillPayableInvoiceInput> | IVBillPayableInvoiceInput;
  organizationId: Scalars["String"]["input"];
  grouping?: InputMaybe<IVBillPaymentGrouping>;
}>;

export type IVBillVBillCreateBillsPaymentsMutation = {
  __typename?: "Mutation";
  createBillsPayments: Array<{
    __typename?: "InvoiceLightSchema";
    id: number;
    coreOrgId: string;
    companyId?: string | null;
    status: IVBillInvoiceIncomingStatus;
    amount: string;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
  }>;
};

export type IVBillVBillCreateExpenseReportMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  input: IVBillExpenseReportInput;
}>;

export type IVBillVBillCreateExpenseReportMutation = {
  __typename?: "Mutation";
  createExpenseReport: {
    __typename?: "ExpenseReportOutput";
    id: string;
    expenseReportNumber: string;
    amount: string;
    createdAt: string;
    expenseDate: string;
    employeeId?: string | null;
    reasonForExpense?: string | null;
    attachmentKeys?: Array<string> | null;
    payee: {
      __typename?: "ExpensePayeeOutput";
      email?: string | null;
      bankName?: string | null;
      bankAccountType?: IVBillBankAccountType | null;
      bankAccountNumber?: string | null;
      bankRoutingNumber?: string | null;
      uid: string;
    };
  };
};

export type IVBillVBillDeleteInvoicesMutationVariables = Exact<{
  invoiceIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type IVBillVBillDeleteInvoicesMutation = { __typename?: "Mutation"; deleteInvoices: Array<number> };

export type IVBillVBillFilesInvoiceQueryVariables = Exact<{
  pagination: IVBillFilePaginationInput;
  filters: IVBillFileFiltersInput;
}>;

export type IVBillVBillFilesInvoiceQuery = {
  __typename?: "Query";
  files: {
    __typename?: "FilePaginatedResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "PageFileSchema";
      id: number;
      pageNumber: number;
      pageCount?: number | null;
      contentType: string;
      movedToInvoiceId?: number | null;
    }>;
  };
};

export type IVBillVBillFilesOcrQueryVariables = Exact<{
  pagination: IVBillFilePaginationInput;
  filters: IVBillFileFiltersInput;
}>;

export type IVBillVBillFilesOcrQuery = {
  __typename?: "Query";
  files: {
    __typename?: "FilePaginatedResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "PageFileSchema";
      id: number;
      size: number;
      pageNumber: number;
      pageCount?: number | null;
      contentType: string;
      movedToInvoiceId?: number | null;
      ocrText: string;
      ocrTextItems: Array<{
        __typename?: "TextItemSchema";
        processor?: IVBillKAutoocrContentProcessor | null;
        text?: string | null;
        direction?: string | null;
        fontName?: string | null;
        width?: number | null;
        height?: number | null;
        transform?: Array<number> | null;
        hasEOL?: boolean | null;
        fontSize?: number | null;
        isNumeric?: boolean | null;
        isBold?: boolean | null;
        isItalic?: boolean | null;
        isUnderlined?: boolean | null;
        isMonospace?: boolean | null;
        isSerif?: boolean | null;
        isSmallcaps?: boolean | null;
        baseLine?: {
          __typename?: "TextItemBaseLineSchema";
          x0: number;
          y0: number;
          x1: number;
          y1: number;
          hasBaseLine: boolean;
        } | null;
        bBox?: { __typename?: "TextItemBBoxSchema"; x0: number; y0: number; x1: number; y1: number } | null;
      }>;
    }>;
  };
};

export type IVBillVBillGetExpenseDisplayInfoQueryVariables = Exact<{
  input: IVBillExpenseDisplayInfoParamsInput;
}>;

export type IVBillVBillGetExpenseDisplayInfoQuery = {
  __typename?: "Query";
  getExpenseDisplayInfo: {
    __typename?: "ExpenseDisplayInfoOutput";
    organization: {
      __typename?: "ExpenseEntityInfoOutput";
      type: string;
      key: string;
      name: string;
      expensePublicUrl?: string | null;
      token?: string | null;
    };
    company?: {
      __typename?: "ExpenseEntityInfoOutput";
      type: string;
      key: string;
      name: string;
      expensePublicUrl?: string | null;
      token?: string | null;
    } | null;
  };
};

export type IVBillVBillGetExpenseTokenQueryVariables = Exact<{
  input: IVBillExpenseTokenParamsInput;
}>;

export type IVBillVBillGetExpenseTokenQuery = {
  __typename?: "Query";
  getExpenseToken: {
    __typename?: "ExpenseEntityInfoOutput";
    type: string;
    key: string;
    name: string;
    expensePublicUrl?: string | null;
    token?: string | null;
  };
};

export type IVBillVBillGetStoreItemQueryVariables = Exact<{
  key: Scalars["String"]["input"];
  type: IVBillStoreIoDataType;
  organizationId: Scalars["String"]["input"];
  source?: InputMaybe<Scalars["String"]["input"]>;
  companyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type IVBillVBillGetStoreItemQuery = {
  __typename?: "Query";
  getStoreItem: {
    __typename?: "StoreItemDetailsSchema";
    data?: {
      __typename?: "StoreItemSchema";
      organizationId: string;
      companyId?: string | null;
      key: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
      source?: string | null;
      data?: {
        __typename?: "StoreIoDataOutput";
        contact?: {
          __typename?: "StoreIoDataContactOutput";
          name?: string | null;
          address?: string | null;
          email?: string | null;
          phone?: string | null;
          addressList?: Array<{
            __typename?: "StoreIoDataContactAddressOutput";
            type?: string | null;
            address?: string | null;
            address_line_2?: string | null;
            city?: string | null;
            country?: string | null;
            state?: string | null;
            zip_code?: string | null;
          }> | null;
          emailList?: Array<{
            __typename?: "StoreIoDataContactEmailOutput";
            type?: string | null;
            description?: string | null;
            email?: string | null;
          }> | null;
          phoneList?: Array<{
            __typename?: "StoreIoDataContactPhoneNumberOutput";
            type?: string | null;
            description?: string | null;
            number?: string | null;
          }> | null;
        } | null;
        glAccount?: {
          __typename?: "StoreIoDataGlAccountOutput";
          name?: string | null;
          accountNo?: string | null;
          type?: string | null;
        } | null;
      } | null;
      meta?: Array<{
        __typename?: "StoreIoMetaItemOutput";
        label?: string | null;
        order?: number | null;
        value?: string | null;
      }> | null;
    } | null;
  };
};

export type IVBillVBillInvoiceQueryVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillInvoiceQuery = {
  __typename?: "Query";
  invoice: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillInvoicesQueryVariables = Exact<{
  pagination: IVBillInvoicePaginationInput;
  filters: IVBillInvoicesFiltersInput;
}>;

export type IVBillVBillInvoicesQuery = {
  __typename?: "Query";
  invoices: {
    __typename?: "InvoicesResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "InvoiceLightSchema";
      id: number;
      hashedId: string;
      coreOrgId: string;
      companyId?: string | null;
      createdAt: string;
      status: IVBillInvoiceIncomingStatus;
      pushStatus?: IVBillInvoicePushStatus | null;
      emailSubject?: string | null;
      emailDate?: string | null;
      emailUrl?: string | null;
      emailAttachmentUrls?: Array<string> | null;
      emailBodyText?: string | null;
      expenseType?: IVBillExpenseType | null;
      initialSenderName?: string | null;
      initialSenderEmail?: string | null;
      senderName?: string | null;
      senderAddress?: string | null;
      senderEmail?: string | null;
      senderEmails?: Array<string> | null;
      senderPhone?: string | null;
      recipientName?: string | null;
      recipientAddress?: string | null;
      recipientEmail?: string | null;
      recipientEmails?: Array<string> | null;
      recipientPhone?: string | null;
      invoiceNumber?: string | null;
      invoiceDate?: string | null;
      invoiceDateDue?: string | null;
      mainFileId?: string | null;
      suggestedMainFileId?: string | null;
      filesOcrState?: IVBillKInvoiceFilesOcrState | null;
      hasLineItems: boolean;
      approvalCount?: number | null;
      integrationRef?: string | null;
      integrationStatus?: IVBillInvoiceIntegrationStatus | null;
      actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
      amount: string;
      contactExternalRef?: string | null;
      canBePaid?: boolean | null;
      isPotentialDuplicate?: boolean | null;
      statusFlags: {
        __typename?: "InvoiceStatusFlagsSchema";
        isOCRComplete: boolean;
        isCompanyMappingComplete: boolean;
        isMappingComplete: boolean;
        canBeEdited: boolean;
      };
      expenseReport?: {
        __typename?: "AbstractExpenseReportOutput";
        expenseDate: string;
        employeeId?: string | null;
        reasonForExpense?: string | null;
        attachmentKeys?: Array<string> | null;
        amount: string;
        payee: {
          __typename?: "AbstractExpensePayeeOutput";
          email?: string | null;
          bankName?: string | null;
          bankAccountType?: IVBillBankAccountType | null;
          bankAccountNumber?: string | null;
          bankRoutingNumber?: string | null;
        };
      } | null;
      approvalStatus?: {
        __typename?: "ApprovalStatusSchema";
        potentialApprovers: Array<string>;
        approvedBy: Array<string>;
        potentialReviewers: Array<string>;
        reviewedBy: Array<string>;
        declinedBy: Array<string>;
        isApproved: boolean;
      } | null;
      additionalMappings?: Array<{
        __typename?: "AdditionalMappingSchema";
        key: string;
        value: string;
        type: IVBillStoreIoDataType;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      }> | null;
      payments?: Array<{
        __typename?: "InvoicePaymentOutput";
        paymentId: number;
        companyId: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentAmount: string;
        paymentDate: string;
        vCheckNumber?: string | null;
        vCheckId?: string | null;
        paymentExternalRef?: string | null;
        paymentExternalUrl?: string | null;
        batchId?: number | null;
        invoiceId: number;
        invoiceRelationType?: string | null;
        memo?: string | null;
        submittedByUserAccount?: {
          __typename?: "PaymentSubmittedByUserAccount";
          userId: string;
          organizationUserId: string;
          userName?: string | null;
          userEmail?: string | null;
          userPhoneNumber?: string | null;
        } | null;
        paymentFromGlAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentToVendorAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentError?: {
          __typename?: "ErrorOutput";
          message: string;
          type: IVBillGqlErrorTypes;
          codes?: Array<string> | null;
        } | null;
      }> | null;
      amounts?: {
        __typename?: "InvoiceAmounts";
        amount: string;
        amountInPayments: string;
        amountInBatches: string;
        amountFreeToPay: string;
        amountInBatchesBreakdown: Array<{
          __typename?: "InvoiceAmountInBatchDetail";
          batchId: number;
          invoiceId: number;
          batchAmountForInvoice: string;
          memo?: string | null;
        }>;
        paymentsStats: {
          __typename?: "InvoicePaymentsStats";
          successful: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          failed: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          pending: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
        };
      } | null;
      syncError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      displayError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      nextUserActions: Array<{
        __typename?: "InvoiceUserActionSchema";
        type: IVBillInvoiceUserActionType;
        showIn: IVBillInvoiceUserActionShowIn;
        displayName: string;
        description: string;
      }>;
    }>;
    statusStats?: {
      __typename?: "InvoiceStatusStatsSchema";
      QUEUED?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      NEW?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      MAPPED?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      PENDING_APPROVAL?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
      } | null;
      APPROVED?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      DECLINED?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_ALL?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_PENDING_REVIEW?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
      } | null;
      QF_CODING?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_APPROVAL?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_ACTION_REQUIRED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
      } | null;
      QF_COMPLETED?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
    } | null;
    generalStats: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null };
  };
};

export type IVBillVBillInvoicesStatsQueryVariables = Exact<{
  pagination?: InputMaybe<IVBillInvoicePaginationInput>;
  filters: IVBillInvoicesFiltersInput;
}>;

export type IVBillVBillInvoicesStatsQuery = {
  __typename?: "Query";
  invoices: {
    __typename?: "InvoicesResponse";
    statusStats?: {
      __typename?: "InvoiceStatusStatsSchema";
      QUEUED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      NEW?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      MAPPED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      PENDING_APPROVAL?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
      } | null;
      APPROVED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      DECLINED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_ALL?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_PENDING_REVIEW?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
      } | null;
      QF_CODING?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_APPROVAL?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
      QF_ACTION_REQUIRED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
      } | null;
      QF_COMPLETED?: { __typename?: "InvoiceStatsItemSchema"; count?: number | null; amount?: string | null } | null;
    } | null;
    generalStats: {
      __typename?: "InvoiceStatsItemSchema";
      count?: number | null;
      amount?: string | null;
      withoutCompanyCount?: number | null;
      withCompanyCount?: number | null;
    };
  };
};

export type IVBillVBillOrganizationsInvoicesQueryVariables = Exact<{
  organizationIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type IVBillVBillOrganizationsInvoicesQuery = {
  __typename?: "Query";
  organizationsInvoices: Array<{
    __typename?: "OrganizationInvoicesOutput";
    organizationId: string;
    organizationName: string;
    generalStats: {
      __typename?: "InvoiceStatsItemSchema";
      count?: number | null;
      amount?: string | null;
      withoutCompanyCount?: number | null;
      withCompanyCount?: number | null;
    };
    statusStats?: {
      __typename?: "InvoiceStatusStatsSchema";
      QUEUED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      NEW?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      MAPPED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      PENDING_APPROVAL?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      APPROVED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      DECLINED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_ALL?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_PENDING_REVIEW?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_CODING?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_APPROVAL?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_ACTION_REQUIRED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      QF_COMPLETED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
    } | null;
  }>;
};

export type IVBillVBillPaymentQueryVariables = Exact<{
  paymentId: Scalars["Int"]["input"];
  organizationId: Scalars["String"]["input"];
}>;

export type IVBillVBillPaymentQuery = {
  __typename?: "Query";
  payment: {
    __typename?: "PaymentDetailsOutput";
    paymentId: number;
    companyId: string;
    paymentPushStatus: IVBillPaymentPushStatus;
    paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
    paymentAmount: string;
    paymentDate: string;
    vCheckNumber?: string | null;
    vCheckId?: string | null;
    paymentExternalRef?: string | null;
    paymentExternalUrl?: string | null;
    batchId?: number | null;
    submittedByUserAccount?: {
      __typename?: "PaymentSubmittedByUserAccount";
      userId: string;
      organizationUserId: string;
      userName?: string | null;
      userEmail?: string | null;
      userPhoneNumber?: string | null;
    } | null;
    paymentFromGlAccount: {
      __typename?: "PaymentAccount";
      key: string;
      settingsKey: string;
      storeType: IVBillStoreIoDataType;
      source?: string | null;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    };
    paymentToVendorAccount: {
      __typename?: "PaymentAccount";
      key: string;
      settingsKey: string;
      storeType: IVBillStoreIoDataType;
      source?: string | null;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    };
    paymentError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    payableInvoices: Array<{
      __typename?: "PayableInvoiceOutput";
      invoiceId: number;
      paymentAmount: string;
      invoiceRelationType?: string | null;
      memo?: string | null;
    }>;
    integrationInfo: { __typename?: "PaymentIntegrationInfo"; storeSource: string; active: boolean; type: string };
  };
};

export type IVBillVBillPaymentsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  pagination?: InputMaybe<IVBillPaymentPaginationInput>;
  filters?: InputMaybe<IVBillPaymentListFiltersInput>;
}>;

export type IVBillVBillPaymentsQuery = {
  __typename?: "Query";
  payments: {
    __typename?: "PaymentListPaginatedOutput";
    total: number;
    hasMore: boolean;
    stats?: {
      __typename?: "PaymentsStatsOutput";
      generalStats: { __typename?: "PaymentsGeneralStatsOutput"; count: string; amount: string };
      groupStats: Array<{
        __typename?: "PaymentsGroupStatsOutput";
        groupingType: IVBillPaymentsGroupingType;
        count: string;
        amount: string;
        minDate?: string | null;
        maxDate?: string | null;
        value: {
          __typename?: "PaymentsGroupingValue";
          ALL?: string | null;
          STATUS?: IVBillPaymentsGroupingStatus | null;
        };
      }>;
    } | null;
    items: Array<{
      __typename?: "PaymentListOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        coreOrgId: string;
        coreCompanyId: string;
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        coreOrgId: string;
        coreCompanyId: string;
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }>;
  };
};

export type IVBillVBillPaymentsStatsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
}>;

export type IVBillVBillPaymentsStatsQuery = {
  __typename?: "Query";
  payments: {
    __typename?: "PaymentListPaginatedOutput";
    stats?: {
      __typename?: "PaymentsStatsOutput";
      groupStats: Array<{
        __typename?: "PaymentsGroupStatsOutput";
        groupingType: IVBillPaymentsGroupingType;
        count: string;
        amount: string;
        minDate?: string | null;
        maxDate?: string | null;
        value: {
          __typename?: "PaymentsGroupingValue";
          ALL?: string | null;
          STATUS?: IVBillPaymentsGroupingStatus | null;
        };
      }>;
    } | null;
  };
};

export type IVBillVBillPushInvoiceMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillPushInvoiceMutation = {
  __typename?: "Mutation";
  pushInvoice: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillQueuedInvoicesQueryVariables = Exact<{
  filters: IVBillInvoicesFiltersInput;
  withInvoices: Scalars["Boolean"]["input"];
  pagination: IVBillInvoicePaginationInput;
}>;

export type IVBillVBillQueuedInvoicesQuery = {
  __typename?: "Query";
  queuedInvoices: {
    __typename?: "InvoicesResponse";
    total: number;
    hasMore: boolean;
    items?: Array<{
      __typename?: "InvoiceLightSchema";
      id: number;
      hashedId: string;
      coreOrgId: string;
      companyId?: string | null;
      createdAt: string;
      status: IVBillInvoiceIncomingStatus;
      pushStatus?: IVBillInvoicePushStatus | null;
      emailSubject?: string | null;
      emailDate?: string | null;
      emailUrl?: string | null;
      emailAttachmentUrls?: Array<string> | null;
      emailBodyText?: string | null;
      expenseType?: IVBillExpenseType | null;
      initialSenderName?: string | null;
      initialSenderEmail?: string | null;
      senderName?: string | null;
      senderAddress?: string | null;
      senderEmail?: string | null;
      senderEmails?: Array<string> | null;
      senderPhone?: string | null;
      recipientName?: string | null;
      recipientAddress?: string | null;
      recipientEmail?: string | null;
      recipientEmails?: Array<string> | null;
      recipientPhone?: string | null;
      invoiceNumber?: string | null;
      invoiceDate?: string | null;
      invoiceDateDue?: string | null;
      mainFileId?: string | null;
      suggestedMainFileId?: string | null;
      filesOcrState?: IVBillKInvoiceFilesOcrState | null;
      hasLineItems: boolean;
      approvalCount?: number | null;
      integrationRef?: string | null;
      integrationStatus?: IVBillInvoiceIntegrationStatus | null;
      actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
      amount: string;
      contactExternalRef?: string | null;
      canBePaid?: boolean | null;
      isPotentialDuplicate?: boolean | null;
      statusFlags: {
        __typename?: "InvoiceStatusFlagsSchema";
        isOCRComplete: boolean;
        isCompanyMappingComplete: boolean;
        isMappingComplete: boolean;
        canBeEdited: boolean;
      };
      expenseReport?: {
        __typename?: "AbstractExpenseReportOutput";
        expenseDate: string;
        employeeId?: string | null;
        reasonForExpense?: string | null;
        attachmentKeys?: Array<string> | null;
        amount: string;
        payee: {
          __typename?: "AbstractExpensePayeeOutput";
          email?: string | null;
          bankName?: string | null;
          bankAccountType?: IVBillBankAccountType | null;
          bankAccountNumber?: string | null;
          bankRoutingNumber?: string | null;
        };
      } | null;
      approvalStatus?: {
        __typename?: "ApprovalStatusSchema";
        potentialApprovers: Array<string>;
        approvedBy: Array<string>;
        potentialReviewers: Array<string>;
        reviewedBy: Array<string>;
        declinedBy: Array<string>;
        isApproved: boolean;
      } | null;
      additionalMappings?: Array<{
        __typename?: "AdditionalMappingSchema";
        key: string;
        value: string;
        type: IVBillStoreIoDataType;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      }> | null;
      payments?: Array<{
        __typename?: "InvoicePaymentOutput";
        paymentId: number;
        companyId: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentAmount: string;
        paymentDate: string;
        vCheckNumber?: string | null;
        vCheckId?: string | null;
        paymentExternalRef?: string | null;
        paymentExternalUrl?: string | null;
        batchId?: number | null;
        invoiceId: number;
        invoiceRelationType?: string | null;
        memo?: string | null;
        submittedByUserAccount?: {
          __typename?: "PaymentSubmittedByUserAccount";
          userId: string;
          organizationUserId: string;
          userName?: string | null;
          userEmail?: string | null;
          userPhoneNumber?: string | null;
        } | null;
        paymentFromGlAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentToVendorAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentError?: {
          __typename?: "ErrorOutput";
          message: string;
          type: IVBillGqlErrorTypes;
          codes?: Array<string> | null;
        } | null;
      }> | null;
      amounts?: {
        __typename?: "InvoiceAmounts";
        amount: string;
        amountInPayments: string;
        amountInBatches: string;
        amountFreeToPay: string;
        amountInBatchesBreakdown: Array<{
          __typename?: "InvoiceAmountInBatchDetail";
          batchId: number;
          invoiceId: number;
          batchAmountForInvoice: string;
          memo?: string | null;
        }>;
        paymentsStats: {
          __typename?: "InvoicePaymentsStats";
          successful: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          failed: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          pending: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
        };
      } | null;
      syncError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      displayError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      nextUserActions: Array<{
        __typename?: "InvoiceUserActionSchema";
        type: IVBillInvoiceUserActionType;
        showIn: IVBillInvoiceUserActionShowIn;
        displayName: string;
        description: string;
      }>;
    }>;
    statusStats?: {
      __typename?: "InvoiceStatusStatsSchema";
      QUEUED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      NEW?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      MAPPED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      APPROVED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      DECLINED?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
      PENDING_APPROVAL?: {
        __typename?: "InvoiceStatsItemSchema";
        count?: number | null;
        amount?: string | null;
        withoutCompanyCount?: number | null;
        withCompanyCount?: number | null;
      } | null;
    } | null;
    generalStats: {
      __typename?: "InvoiceStatsItemSchema";
      count?: number | null;
      amount?: string | null;
      withoutCompanyCount?: number | null;
      withCompanyCount?: number | null;
    };
  };
};

export type IVBillVBillRelatedInvoicesQueryVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
  filters?: InputMaybe<IVBillRelatedInvoiceFiltersInput>;
}>;

export type IVBillVBillRelatedInvoicesQuery = {
  __typename?: "Query";
  relatedInvoices: Array<{
    __typename?: "RelatedInvoiceSchema";
    invoice: {
      __typename?: "InvoiceSchema";
      id: number;
      hashedId: string;
      coreOrgId: string;
      companyId?: string | null;
      createdAt: string;
      integrationStatus?: IVBillInvoiceIntegrationStatus | null;
      actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
      expenseType?: IVBillExpenseType | null;
      status: IVBillInvoiceIncomingStatus;
      filesOcrState?: IVBillKInvoiceFilesOcrState | null;
      pushStatus?: IVBillInvoicePushStatus | null;
      emailSubject?: string | null;
      emailDate?: string | null;
      emailUrl?: string | null;
      emailAttachmentUrls?: Array<string> | null;
      emailBodyText?: string | null;
      hasLineItems: boolean;
      initialSenderName?: string | null;
      initialSenderEmail?: string | null;
      senderName?: string | null;
      senderAddress?: string | null;
      senderEmail?: string | null;
      senderEmails?: Array<string> | null;
      senderPhone?: string | null;
      recipientName?: string | null;
      recipientAddress?: string | null;
      recipientEmail?: string | null;
      recipientEmails?: Array<string> | null;
      recipientPhone?: string | null;
      invoiceNumber?: string | null;
      invoiceDate?: string | null;
      invoiceDateDue?: string | null;
      integrationRef?: string | null;
      amount: string;
      approvalCount?: number | null;
      canBePaid?: boolean | null;
      isPotentialDuplicate?: boolean | null;
      suggestedMainFileId?: string | null;
      company?: {
        __typename?: "CompanySchema";
        id: number;
        companyId: string;
        orgId: string;
        orgName: string;
        emailHash: string;
        name: string;
      } | null;
      expenseReport?: {
        __typename?: "ExpenseReportOutput";
        expenseDate: string;
        employeeId?: string | null;
        reasonForExpense?: string | null;
        attachmentKeys?: Array<string> | null;
        paymentMethod: IVBillExpensePaymentMethod;
        amount: string;
        id: string;
        createdAt: string;
        expenseReportNumber: string;
        payee: {
          __typename?: "ExpensePayeeOutput";
          email?: string | null;
          bankName?: string | null;
          bankAccountType?: IVBillBankAccountType | null;
          bankAccountNumber?: string | null;
          bankRoutingNumber?: string | null;
          addressStreet?: string | null;
          addressCity?: string | null;
          addressState?: string | null;
          addressPostalCode?: string | null;
          uid: string;
        };
        additionalFields?: Array<{
          __typename?: "AbstractExpenseAdditionalField";
          type: string;
          key: string;
          label?: string | null;
          value: string;
          typeOfValue: IVBillTypeOfValue;
          order?: number | null;
        }> | null;
      } | null;
      statusFlags: {
        __typename?: "InvoiceStatusFlagsSchema";
        isOCRComplete: boolean;
        isMappingComplete: boolean;
        canBeEdited: boolean;
      };
      approvalStatus?: {
        __typename?: "ApprovalStatusSchema";
        isApproved: boolean;
        potentialApprovers: Array<string>;
        approvedBy: Array<string>;
        potentialReviewers: Array<string>;
        reviewedBy: Array<string>;
        declinedBy: Array<string>;
      } | null;
      lineItems?: Array<{
        __typename?: "LineItemSchema";
        id: number;
        invoiceId: number;
        description?: string | null;
        um?: string | null;
        qty?: string | null;
        rate?: string | null;
        amount: string;
        type?: IVBillLineItemType | null;
        glMappings?: Array<{
          __typename?: "LineItemGLMappingSchema";
          id: number;
          lineItemId: number;
          qty: number;
          additionalMappings?: Array<{
            __typename?: "AdditionalMappingSchema";
            key: string;
            value: string;
            type: IVBillStoreIoDataType;
            label?: string | null;
            label2?: string | null;
            parentLabel?: string | null;
          }> | null;
        }> | null;
      }> | null;
      payments?: Array<{
        __typename?: "InvoicePaymentOutput";
        paymentId: number;
        companyId: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentAmount: string;
        paymentDate: string;
        vCheckNumber?: string | null;
        vCheckId?: string | null;
        paymentExternalRef?: string | null;
        paymentExternalUrl?: string | null;
        batchId?: number | null;
        invoiceId: number;
        invoiceRelationType?: string | null;
        memo?: string | null;
        submittedByUserAccount?: {
          __typename?: "PaymentSubmittedByUserAccount";
          userId: string;
          organizationUserId: string;
          userName?: string | null;
          userEmail?: string | null;
          userPhoneNumber?: string | null;
        } | null;
        paymentFromGlAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentToVendorAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentError?: {
          __typename?: "ErrorOutput";
          message: string;
          type: IVBillGqlErrorTypes;
          codes?: Array<string> | null;
        } | null;
      }> | null;
      amounts?: {
        __typename?: "InvoiceAmounts";
        amount: string;
        amountInPayments: string;
        amountInBatches: string;
        amountFreeToPay: string;
        amountInBatchesBreakdown: Array<{
          __typename?: "InvoiceAmountInBatchDetail";
          batchId: number;
          invoiceId: number;
          batchAmountForInvoice: string;
          memo?: string | null;
        }>;
        paymentsStats: {
          __typename?: "InvoicePaymentsStats";
          successful: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          failed: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          pending: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
        };
      } | null;
      files?: Array<{
        __typename?: "InvoiceFileSchema";
        id: number;
        invoiceId: number;
        fileOcrState: IVBillKFileOcrState;
        fileIsEncrypted?: boolean | null;
        name?: string | null;
        size?: number | null;
        pageCount?: number | null;
        movedToInvoice?: {
          __typename?: "InvoiceSchema";
          id: number;
          status: IVBillInvoiceIncomingStatus;
          invoiceNumber?: string | null;
        } | null;
        pages?: Array<{
          __typename?: "AbstractPageFileOutput";
          id?: number | null;
          movedToInvoiceId?: number | null;
          pageNumber?: number | null;
          contentType?: string | null;
          size?: number | null;
        }> | null;
        metadata?: {
          __typename?: "FileMetadataSchema";
          OPENAI?: {
            __typename?: "InvoiceSuggestionsMetadata";
            amount?: number | null;
            invoiceNumber?: string | null;
            invoiceDate?: string | null;
            invoiceDateDue?: string | null;
            senderName?: string | null;
            senderAddress?: string | null;
            senderEmail?: string | null;
            senderPhone?: string | null;
            recipientName?: string | null;
            recipientAddress?: string | null;
            recipientEmail?: string | null;
            recipientPhone?: string | null;
            lineItems: Array<{
              __typename?: "InvoiceLineItemMetaSchema";
              type?: IVBillLineItemType | null;
              confidence: number;
              amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            }>;
          } | null;
          GDAI?: {
            __typename?: "InvoiceSuggestionsMetadata";
            amount?: number | null;
            invoiceNumber?: string | null;
            invoiceDate?: string | null;
            invoiceDateDue?: string | null;
            senderName?: string | null;
            senderAddress?: string | null;
            senderEmail?: string | null;
            senderPhone?: string | null;
            recipientName?: string | null;
            recipientAddress?: string | null;
            recipientEmail?: string | null;
            recipientPhone?: string | null;
            lineItems: Array<{
              __typename?: "InvoiceLineItemMetaSchema";
              type?: IVBillLineItemType | null;
              confidence: number;
              amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
              qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
              rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            }>;
          } | null;
          AOCR?: {
            __typename?: "InvoiceSuggestionsMetadata";
            invoiceNumber?: string | null;
            invoiceDate?: string | null;
            invoiceDateDue?: string | null;
            senderName?: string | null;
            senderAddress?: string | null;
            senderEmail?: string | null;
            senderPhone?: string | null;
            recipientName?: string | null;
            recipientAddress?: string | null;
            recipientEmail?: string | null;
            recipientPhone?: string | null;
            amount?: number | null;
          } | null;
        } | null;
      }> | null;
      mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
      actions?: Array<{
        __typename?: "InvoiceActionLogSchema";
        id: number;
        createdAt: string;
        key: string;
        type: IVBillIShtarActionLogObjectType;
        initiator: string;
        scope: string;
        action: string;
        level: string;
        path?: Array<{
          __typename?: "InvoiceActionLogPathOrRelatedSchema";
          key: string;
          type: IVBillIShtarActionLogObjectType;
          label?: string | null;
        }> | null;
        related?: Array<{
          __typename?: "InvoiceActionLogPathOrRelatedSchema";
          key: string;
          type: IVBillIShtarActionLogObjectType;
          label?: string | null;
        }> | null;
        changedBy: {
          __typename?: "InvoiceActionLogChangedBy";
          accountId?: string | null;
          organizationUserId?: string | null;
          name?: string | null;
        };
        meta?: {
          __typename?: "InvoiceActionLogMetaSchema";
          content?: {
            __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
            type: IVBillIShtarActionLogMetaContentBlockMessageType;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
            related?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
              key: string;
              related: {
                __typename?: "InvoiceActionLogPathOrRelatedSchema";
                key: string;
                type: IVBillIShtarActionLogObjectType;
                label?: string | null;
              };
            }> | null;
          } | null;
          additionalContent?: Array<{
            __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
            type: IVBillIShtarActionLogMetaContentBlockMessageType;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
            related?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
              key: string;
              related: {
                __typename?: "InvoiceActionLogPathOrRelatedSchema";
                key: string;
                type: IVBillIShtarActionLogObjectType;
                label?: string | null;
              };
            }> | null;
            columns?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
            rows?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableRow";
              cells?: Array<{
                __typename?: "InvoiceActionLogMetaContentBlockTableCell";
                icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
                message?: string | null;
                template?: string | null;
                bindings?: Array<{
                  __typename?: "InvoiceActionLogMetaTemplateBindings";
                  key: string;
                  value: string;
                }> | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }> | null;
      approvals?: Array<{
        __typename?: "InvoiceApprovalSchema";
        id: number;
        createdAt?: string | null;
        updatedAt?: string | null;
        orgUserId: string;
        status: IVBillInvoiceApprovalStatus;
        invoiceId: number;
      }> | null;
      additionalMappings?: Array<{
        __typename?: "AdditionalMappingSchema";
        key: string;
        value: string;
        type: IVBillStoreIoDataType;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      }> | null;
      syncError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      displayError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      nextUserActions: Array<{
        __typename?: "InvoiceUserActionSchema";
        type: IVBillInvoiceUserActionType;
        showIn: IVBillInvoiceUserActionShowIn;
        displayName: string;
        description: string;
      }>;
    };
    relation?: {
      __typename?: "InvoiceRelationSchema";
      fromInvoiceId: number;
      toInvoiceId: number;
      type: IVBillInvoiceRelationType;
      status: IVBillInvoiceRelationStatus;
    } | null;
  }>;
};

export type IVBillVBillMarkInvoiceAsDuplicateMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillMarkInvoiceAsDuplicateMutation = { __typename?: "Mutation"; markInvoiceAsDuplicate: boolean };

export type IVBillVBillSessionQueryVariables = Exact<{ [key: string]: never }>;

export type IVBillVBillSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "SessionOutput";
    hasPermissions: Array<IVBillPermission>;
    account: { __typename?: "SessionAccountOutput"; id: string; username: string; name: string; email?: string | null };
  };
};

export type IVBillVBillSettingsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type IVBillVBillSettingsQuery = {
  __typename?: "Query";
  settings: {
    __typename?: "IntegrationSettingsOutput";
    info?: { __typename?: "IntegrationSettingsInfoOutput"; type: string; storeSource: string } | null;
    vBill: {
      __typename?: "IntegrationSettingsVBillOutput";
      additionalMappings: {
        __typename?: "IntegrationSettingsVBillAdditionalMappingsOutput";
        invoiceValidator?: string | null;
        lineValidator?: string | null;
        invoice: Array<{
          __typename?: "IntegrationSettingsVBillAdditionalMappingsEntryOutput";
          label?: string | null;
          placeholder?: string | null;
          tooltip?: string | null;
          key: string;
          showAs: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs;
          showIn?: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn | null;
          displayKeys?: Array<string> | null;
          displayMetaKeys?: Array<string> | null;
          required?: boolean | null;
          isContact?: boolean | null;
          isGlAccount?: boolean | null;
          storeDataType: IVBillIIntegrationDataType;
          dataSource: IVBillIntegrationSettingsDataSource;
          defaultValue?: string | null;
          typeOfValue?: IVBillIntegrationSettingsTypeOfValue | null;
          staticSelectList?: Array<{
            __typename?: "IntegrationSettingsStaticSelectListItem";
            settingsKey?: string | null;
            value: string;
            typeOfValue?: IVBillIntegrationSettingsTypeOfValue | null;
            label?: string | null;
            label2?: string | null;
            parentLabel?: string | null;
          }> | null;
        }>;
        line: Array<{
          __typename?: "IntegrationSettingsVBillAdditionalMappingsEntryOutput";
          label?: string | null;
          placeholder?: string | null;
          tooltip?: string | null;
          key: string;
          showAs: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs;
          showIn?: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn | null;
          displayKeys?: Array<string> | null;
          displayMetaKeys?: Array<string> | null;
          required?: boolean | null;
          isContact?: boolean | null;
          isGlAccount?: boolean | null;
          storeDataType: IVBillIIntegrationDataType;
          dataSource: IVBillIntegrationSettingsDataSource;
          defaultValue?: string | null;
          typeOfValue?: IVBillIntegrationSettingsTypeOfValue | null;
          staticSelectList?: Array<{
            __typename?: "IntegrationSettingsStaticSelectListItem";
            settingsKey?: string | null;
            value: string;
            typeOfValue?: IVBillIntegrationSettingsTypeOfValue | null;
            label?: string | null;
            label2?: string | null;
            parentLabel?: string | null;
          }> | null;
        }>;
      };
    };
    capabilities: {
      __typename?: "IntegrationSettingsCapabilitiesOutput";
      available: Array<IVBillIntegrationSettingsCapabilities>;
      enabled: Array<IVBillIntegrationSettingsCapabilities>;
    };
  };
};

export type IVBillVBillSplitInvoiceAttachmentsMutationVariables = Exact<{
  options: IVBillInvoiceSplitAttachmentsInput;
}>;

export type IVBillVBillSplitInvoiceAttachmentsMutation = {
  __typename?: "Mutation";
  splitInvoiceAttachments: Array<{
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  }>;
};

export type IVBillVBillSplitInvoiceFileMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
  fileId: Scalars["Int"]["input"];
}>;

export type IVBillVBillSplitInvoiceFileMutation = {
  __typename?: "Mutation";
  splitInvoiceFile: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillStartApprovalForInvoiceMutationVariables = Exact<{
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillStartApprovalForInvoiceMutation = {
  __typename?: "Mutation";
  startApprovalForInvoice: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    validationErrors?: {
      __typename?: "ValidationErrorsOutput";
      invoice: {
        __typename?: "ErInvoice";
        fields: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
        mappings: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
      };
      lineItems: Array<{
        __typename?: "ErLineItem";
        index?: number | null;
        key?: string | null;
        glMapping: {
          __typename?: "ErGLMapping";
          fields: {
            __typename?: "ErInfo";
            required: Array<string>;
            custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
          };
          mappings: {
            __typename?: "ErInfo";
            required: Array<string>;
            custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
          };
        };
        fields: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
      }>;
    } | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillStoreItemsForCompanyQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  typeKeyIn?: InputMaybe<Array<IVBillStoreItemTypeKeyInput> | IVBillStoreItemTypeKeyInput>;
  type?: InputMaybe<IVBillStoreIoDataType>;
  settingsKey?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  companyId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type IVBillVBillStoreItemsForCompanyQuery = {
  __typename?: "Query";
  storeItemsForCompany: Array<{
    __typename?: "StoreItemSchema";
    organizationId: string;
    companyId?: string | null;
    key: string;
    type: IVBillStoreIoDataType;
    label?: string | null;
    label2?: string | null;
    parentLabel?: string | null;
    source?: string | null;
    data?: {
      __typename?: "StoreIoDataOutput";
      contact?: {
        __typename?: "StoreIoDataContactOutput";
        name?: string | null;
        address?: string | null;
        email?: string | null;
        phone?: string | null;
        addressList?: Array<{
          __typename?: "StoreIoDataContactAddressOutput";
          type?: string | null;
          address?: string | null;
          address_line_2?: string | null;
          city?: string | null;
          country?: string | null;
          state?: string | null;
          zip_code?: string | null;
        }> | null;
        emailList?: Array<{
          __typename?: "StoreIoDataContactEmailOutput";
          type?: string | null;
          description?: string | null;
          email?: string | null;
        }> | null;
        phoneList?: Array<{
          __typename?: "StoreIoDataContactPhoneNumberOutput";
          type?: string | null;
          description?: string | null;
          number?: string | null;
        }> | null;
      } | null;
      glAccount?: {
        __typename?: "StoreIoDataGlAccountOutput";
        name?: string | null;
        accountNo?: string | null;
        type?: string | null;
      } | null;
    } | null;
    meta?: Array<{
      __typename?: "StoreIoMetaItemOutput";
      label?: string | null;
      order?: number | null;
      value?: string | null;
    }> | null;
  }>;
};

export type IVBillVBillUpdateInvoiceAndMappingsMutationVariables = Exact<{
  skipAdditionalMappingsValidations: Scalars["Boolean"]["input"];
  invoiceData: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
  saveDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type IVBillVBillUpdateInvoiceAndMappingsMutation = {
  __typename?: "Mutation";
  updateInvoiceAndMappings: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    validationErrors?: {
      __typename?: "ValidationErrorsOutput";
      invoice: {
        __typename?: "ErInvoice";
        fields: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
        mappings: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
      };
      lineItems: Array<{
        __typename?: "ErLineItem";
        index?: number | null;
        key?: string | null;
        glMapping: {
          __typename?: "ErGLMapping";
          fields: {
            __typename?: "ErInfo";
            required: Array<string>;
            custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
          };
          mappings: {
            __typename?: "ErInfo";
            required: Array<string>;
            custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
          };
        };
        fields: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
      }>;
    } | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillUpdateInvoiceCompanyMutationVariables = Exact<{
  updateData: IVBillInvoiceUpdateCompanyInput;
}>;

export type IVBillVBillUpdateInvoiceCompanyMutation = {
  __typename?: "Mutation";
  updateInvoiceCompany: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillUploadInvoiceMutationVariables = Exact<{
  input: IVBillUploadInvoiceInput;
}>;

export type IVBillVBillUploadInvoiceMutation = {
  __typename?: "Mutation";
  uploadInvoice: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillValidateInvoiceQueryVariables = Exact<{
  invoiceData: IVBillNullableInvoiceInput;
  invoiceId: Scalars["Int"]["input"];
}>;

export type IVBillVBillValidateInvoiceQuery = {
  __typename?: "Query";
  validateInvoice: {
    __typename?: "InvoiceSchema";
    id: number;
    hashedId: string;
    coreOrgId: string;
    companyId?: string | null;
    createdAt: string;
    integrationStatus?: IVBillInvoiceIntegrationStatus | null;
    actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
    expenseType?: IVBillExpenseType | null;
    status: IVBillInvoiceIncomingStatus;
    filesOcrState?: IVBillKInvoiceFilesOcrState | null;
    pushStatus?: IVBillInvoicePushStatus | null;
    emailSubject?: string | null;
    emailDate?: string | null;
    emailUrl?: string | null;
    emailAttachmentUrls?: Array<string> | null;
    emailBodyText?: string | null;
    hasLineItems: boolean;
    initialSenderName?: string | null;
    initialSenderEmail?: string | null;
    senderName?: string | null;
    senderAddress?: string | null;
    senderEmail?: string | null;
    senderEmails?: Array<string> | null;
    senderPhone?: string | null;
    recipientName?: string | null;
    recipientAddress?: string | null;
    recipientEmail?: string | null;
    recipientEmails?: Array<string> | null;
    recipientPhone?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: string | null;
    invoiceDateDue?: string | null;
    integrationRef?: string | null;
    amount: string;
    approvalCount?: number | null;
    canBePaid?: boolean | null;
    isPotentialDuplicate?: boolean | null;
    suggestedMainFileId?: string | null;
    validationErrors?: {
      __typename?: "ValidationErrorsOutput";
      invoice: {
        __typename?: "ErInvoice";
        fields: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
        mappings: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
      };
      lineItems: Array<{
        __typename?: "ErLineItem";
        index?: number | null;
        key?: string | null;
        glMapping: {
          __typename?: "ErGLMapping";
          fields: {
            __typename?: "ErInfo";
            required: Array<string>;
            custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
          };
          mappings: {
            __typename?: "ErInfo";
            required: Array<string>;
            custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
          };
        };
        fields: {
          __typename?: "ErInfo";
          required: Array<string>;
          custom?: { __typename?: "ErInfoCustom"; fields: Array<string>; message: string } | null;
        };
      }>;
    } | null;
    company?: {
      __typename?: "CompanySchema";
      id: number;
      companyId: string;
      orgId: string;
      orgName: string;
      emailHash: string;
      name: string;
    } | null;
    expenseReport?: {
      __typename?: "ExpenseReportOutput";
      expenseDate: string;
      employeeId?: string | null;
      reasonForExpense?: string | null;
      attachmentKeys?: Array<string> | null;
      paymentMethod: IVBillExpensePaymentMethod;
      amount: string;
      id: string;
      createdAt: string;
      expenseReportNumber: string;
      payee: {
        __typename?: "ExpensePayeeOutput";
        email?: string | null;
        bankName?: string | null;
        bankAccountType?: IVBillBankAccountType | null;
        bankAccountNumber?: string | null;
        bankRoutingNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostalCode?: string | null;
        uid: string;
      };
      additionalFields?: Array<{
        __typename?: "AbstractExpenseAdditionalField";
        type: string;
        key: string;
        label?: string | null;
        value: string;
        typeOfValue: IVBillTypeOfValue;
        order?: number | null;
      }> | null;
    } | null;
    statusFlags: {
      __typename?: "InvoiceStatusFlagsSchema";
      isOCRComplete: boolean;
      isMappingComplete: boolean;
      canBeEdited: boolean;
    };
    approvalStatus?: {
      __typename?: "ApprovalStatusSchema";
      isApproved: boolean;
      potentialApprovers: Array<string>;
      approvedBy: Array<string>;
      potentialReviewers: Array<string>;
      reviewedBy: Array<string>;
      declinedBy: Array<string>;
    } | null;
    lineItems?: Array<{
      __typename?: "LineItemSchema";
      id: number;
      invoiceId: number;
      description?: string | null;
      um?: string | null;
      qty?: string | null;
      rate?: string | null;
      amount: string;
      type?: IVBillLineItemType | null;
      glMappings?: Array<{
        __typename?: "LineItemGLMappingSchema";
        id: number;
        lineItemId: number;
        qty: number;
        additionalMappings?: Array<{
          __typename?: "AdditionalMappingSchema";
          key: string;
          value: string;
          type: IVBillStoreIoDataType;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        }> | null;
      }> | null;
    }> | null;
    payments?: Array<{
      __typename?: "InvoicePaymentOutput";
      paymentId: number;
      companyId: string;
      paymentPushStatus: IVBillPaymentPushStatus;
      paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
      paymentAmount: string;
      paymentDate: string;
      vCheckNumber?: string | null;
      vCheckId?: string | null;
      paymentExternalRef?: string | null;
      paymentExternalUrl?: string | null;
      batchId?: number | null;
      invoiceId: number;
      invoiceRelationType?: string | null;
      memo?: string | null;
      submittedByUserAccount?: {
        __typename?: "PaymentSubmittedByUserAccount";
        userId: string;
        organizationUserId: string;
        userName?: string | null;
        userEmail?: string | null;
        userPhoneNumber?: string | null;
      } | null;
      paymentFromGlAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentToVendorAccount: {
        __typename?: "PaymentAccount";
        key: string;
        settingsKey: string;
        storeType: IVBillStoreIoDataType;
        source?: string | null;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      };
      paymentError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
    }> | null;
    amounts?: {
      __typename?: "InvoiceAmounts";
      amount: string;
      amountInPayments: string;
      amountInBatches: string;
      amountFreeToPay: string;
      amountInBatchesBreakdown: Array<{
        __typename?: "InvoiceAmountInBatchDetail";
        batchId: number;
        invoiceId: number;
        batchAmountForInvoice: string;
        memo?: string | null;
      }>;
      paymentsStats: {
        __typename?: "InvoicePaymentsStats";
        successful: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        failed: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
        pending: {
          __typename?: "PaymentsStats";
          count: number;
          sum: string;
          minDate?: string | null;
          maxDate?: string | null;
        };
      };
    } | null;
    files?: Array<{
      __typename?: "InvoiceFileSchema";
      id: number;
      invoiceId: number;
      fileOcrState: IVBillKFileOcrState;
      fileIsEncrypted?: boolean | null;
      name?: string | null;
      size?: number | null;
      pageCount?: number | null;
      movedToInvoice?: {
        __typename?: "InvoiceSchema";
        id: number;
        status: IVBillInvoiceIncomingStatus;
        invoiceNumber?: string | null;
      } | null;
      pages?: Array<{
        __typename?: "AbstractPageFileOutput";
        id?: number | null;
        movedToInvoiceId?: number | null;
        pageNumber?: number | null;
        contentType?: string | null;
        size?: number | null;
      }> | null;
      metadata?: {
        __typename?: "FileMetadataSchema";
        OPENAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        GDAI?: {
          __typename?: "InvoiceSuggestionsMetadata";
          amount?: number | null;
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          lineItems: Array<{
            __typename?: "InvoiceLineItemMetaSchema";
            type?: IVBillLineItemType | null;
            confidence: number;
            amount?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            description?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            um?: { __typename?: "StringWithConfidence"; confidence: number; value: string } | null;
            qty?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
            rate?: { __typename?: "NumberWithConfidence"; confidence: number; value: number } | null;
          }>;
        } | null;
        AOCR?: {
          __typename?: "InvoiceSuggestionsMetadata";
          invoiceNumber?: string | null;
          invoiceDate?: string | null;
          invoiceDateDue?: string | null;
          senderName?: string | null;
          senderAddress?: string | null;
          senderEmail?: string | null;
          senderPhone?: string | null;
          recipientName?: string | null;
          recipientAddress?: string | null;
          recipientEmail?: string | null;
          recipientPhone?: string | null;
          amount?: number | null;
        } | null;
      } | null;
    }> | null;
    mainFile?: { __typename?: "FileInfoSchema"; id: number; name?: string | null } | null;
    actions?: Array<{
      __typename?: "InvoiceActionLogSchema";
      id: number;
      createdAt: string;
      key: string;
      type: IVBillIShtarActionLogObjectType;
      initiator: string;
      scope: string;
      action: string;
      level: string;
      path?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      related?: Array<{
        __typename?: "InvoiceActionLogPathOrRelatedSchema";
        key: string;
        type: IVBillIShtarActionLogObjectType;
        label?: string | null;
      }> | null;
      changedBy: {
        __typename?: "InvoiceActionLogChangedBy";
        accountId?: string | null;
        organizationUserId?: string | null;
        name?: string | null;
      };
      meta?: {
        __typename?: "InvoiceActionLogMetaSchema";
        content?: {
          __typename?: "InvoiceActionLogMetaContentBlockMessageSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
        } | null;
        additionalContent?: Array<{
          __typename?: "InvoiceActionLogMetaAdditionalContentBlockSchema";
          type: IVBillIShtarActionLogMetaContentBlockMessageType;
          message?: string | null;
          template?: string | null;
          bindings?: Array<{ __typename?: "InvoiceActionLogMetaTemplateBindings"; key: string; value: string }> | null;
          related?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockMessageRelated";
            key: string;
            related: {
              __typename?: "InvoiceActionLogPathOrRelatedSchema";
              key: string;
              type: IVBillIShtarActionLogObjectType;
              label?: string | null;
            };
          }> | null;
          columns?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableCell";
            icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
            message?: string | null;
            template?: string | null;
            bindings?: Array<{
              __typename?: "InvoiceActionLogMetaTemplateBindings";
              key: string;
              value: string;
            }> | null;
          }> | null;
          rows?: Array<{
            __typename?: "InvoiceActionLogMetaContentBlockTableRow";
            cells?: Array<{
              __typename?: "InvoiceActionLogMetaContentBlockTableCell";
              icon?: IVBillIShtarActionLogMetaContentBlockTableIcon | null;
              message?: string | null;
              template?: string | null;
              bindings?: Array<{
                __typename?: "InvoiceActionLogMetaTemplateBindings";
                key: string;
                value: string;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    approvals?: Array<{
      __typename?: "InvoiceApprovalSchema";
      id: number;
      createdAt?: string | null;
      updatedAt?: string | null;
      orgUserId: string;
      status: IVBillInvoiceApprovalStatus;
      invoiceId: number;
    }> | null;
    additionalMappings?: Array<{
      __typename?: "AdditionalMappingSchema";
      key: string;
      value: string;
      type: IVBillStoreIoDataType;
      label?: string | null;
      label2?: string | null;
      parentLabel?: string | null;
    }> | null;
    syncError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    displayError?: {
      __typename?: "ErrorOutput";
      message: string;
      type: IVBillGqlErrorTypes;
      codes?: Array<string> | null;
    } | null;
    nextUserActions: Array<{
      __typename?: "InvoiceUserActionSchema";
      type: IVBillInvoiceUserActionType;
      showIn: IVBillInvoiceUserActionShowIn;
      displayName: string;
      description: string;
    }>;
  };
};

export type IVBillVBillAddAllInvoicesToBatchMutationVariables = Exact<{
  batchId: Scalars["Int"]["input"];
  billsFilters: IVBillBillsFiltersInput;
}>;

export type IVBillVBillAddAllInvoicesToBatchMutation = {
  __typename?: "Mutation";
  addAllInvoicesToBatch: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
    } | null;
  };
};

export type IVBillVBillAddCompaniesToBatchMutationVariables = Exact<{
  companyIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillAddCompaniesToBatchMutation = {
  __typename?: "Mutation";
  addCompaniesToBatch: {
    __typename?: "BatchCompanyListStatusResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchCompanyListPaginatedResponse";
      items: Array<{
        __typename?: "BatchCompanySchema";
        id: number;
        coreId: string;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        batchId: number;
      }>;
    } | null;
  };
};

export type IVBillVBillAddInvoicesToBatchMutationVariables = Exact<{
  batchInvoices: Array<IVBillBatchInvoiceInput> | IVBillBatchInvoiceInput;
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillAddInvoicesToBatchMutation = {
  __typename?: "Mutation";
  addInvoicesToBatch: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDate?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
      batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
      batchPayments: Array<{
        __typename?: "BatchPaymentOutput";
        batchId: number;
        paymentId: number;
        invoiceId: number;
        paymentAmountForBillInBatch: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentStatus: IVBillPaymentStatus;
        paymentErrors: Array<{
          __typename?: "BatchPaymentErrorOutput";
          type: IVBillBatchPaymentErrorType;
          messages: Array<string>;
        }>;
      }>;
      batchTreeDetails?: {
        __typename?: "BatchTreeDetails";
        batchIds: Array<number>;
        commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
        paymentsStats: Array<{
          __typename?: "PaymentsGroupStatsOutput";
          count: string;
          amount: string;
          groupingType: IVBillPaymentsGroupingType;
          minDate?: string | null;
          maxDate?: string | null;
          value: {
            __typename?: "PaymentsGroupingValue";
            ALL?: string | null;
            STATUS?: IVBillPaymentsGroupingStatus | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type IVBillVBillAddMembersToBatchMutationVariables = Exact<{
  memberIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillAddMembersToBatchMutation = {
  __typename?: "Mutation";
  addMembersToBatch: {
    __typename?: "BatchMemberListStatusResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchMemberListResponse";
      items: Array<{
        __typename?: "BatchMemberSchema";
        id: number;
        coreOrgUserId: string;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        batchId: number;
      }>;
    } | null;
  };
};

export type IVBillVBillCompaniesSettingsQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type IVBillVBillCompaniesSettingsQuery = {
  __typename?: "Query";
  companiesSettings: {
    __typename?: "IntegrationSettingsOutput";
    vBill: {
      __typename?: "IntegrationSettingsVBillOutput";
      additionalMappings: {
        __typename?: "IntegrationSettingsVBillAdditionalMappingsOutput";
        invoiceValidator?: string | null;
        lineValidator?: string | null;
        invoice: Array<{
          __typename?: "IntegrationSettingsVBillAdditionalMappingsEntryOutput";
          label?: string | null;
          placeholder?: string | null;
          tooltip?: string | null;
          key: string;
          showAs: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs;
          showIn?: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn | null;
          required?: boolean | null;
          isContact?: boolean | null;
          isGlAccount?: boolean | null;
          storeDataType: IVBillIIntegrationDataType;
        }>;
        line: Array<{
          __typename?: "IntegrationSettingsVBillAdditionalMappingsEntryOutput";
          label?: string | null;
          placeholder?: string | null;
          tooltip?: string | null;
          key: string;
          showAs: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs;
          showIn?: IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn | null;
          required?: boolean | null;
          isContact?: boolean | null;
          isGlAccount?: boolean | null;
          storeDataType: IVBillIIntegrationDataType;
        }>;
      };
    };
    capabilities: {
      __typename?: "IntegrationSettingsCapabilitiesOutput";
      available: Array<IVBillIntegrationSettingsCapabilities>;
      enabled: Array<IVBillIntegrationSettingsCapabilities>;
    };
  };
};

export type IVBillVBillCompaniesSettingsEnabledCapabilitiesQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type IVBillVBillCompaniesSettingsEnabledCapabilitiesQuery = {
  __typename?: "Query";
  companiesSettings: {
    __typename?: "IntegrationSettingsOutput";
    capabilities: {
      __typename?: "IntegrationSettingsCapabilitiesOutput";
      enabled: Array<IVBillIntegrationSettingsCapabilities>;
    };
  };
};

export type IVBillVBillCreateBatchMutationVariables = Exact<{
  batchData: IVBillNullableCreateBatchInput;
}>;

export type IVBillVBillCreateBatchMutation = {
  __typename?: "Mutation";
  createBatch: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      budget?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
    } | null;
  };
};

export type IVBillVBillDeleteBatchMutationVariables = Exact<{
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillDeleteBatchMutation = {
  __typename?: "Mutation";
  deleteBatch: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDate?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
      batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
      batchPayments: Array<{
        __typename?: "BatchPaymentOutput";
        batchId: number;
        paymentId: number;
        invoiceId: number;
        paymentAmountForBillInBatch: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentStatus: IVBillPaymentStatus;
        paymentErrors: Array<{
          __typename?: "BatchPaymentErrorOutput";
          type: IVBillBatchPaymentErrorType;
          messages: Array<string>;
        }>;
      }>;
      batchTreeDetails?: {
        __typename?: "BatchTreeDetails";
        batchIds: Array<number>;
        commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
        paymentsStats: Array<{
          __typename?: "PaymentsGroupStatsOutput";
          count: string;
          amount: string;
          groupingType: IVBillPaymentsGroupingType;
          minDate?: string | null;
          maxDate?: string | null;
          value: {
            __typename?: "PaymentsGroupingValue";
            ALL?: string | null;
            STATUS?: IVBillPaymentsGroupingStatus | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type IVBillVBillGetBatchCompaniesQueryVariables = Exact<{
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillGetBatchCompaniesQuery = {
  __typename?: "Query";
  getBatchCompanies: {
    __typename?: "BatchCompanyListStatusResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchCompanyListPaginatedResponse";
      items: Array<{
        __typename?: "BatchCompanySchema";
        id: number;
        coreId: string;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        batchId: number;
      }>;
    } | null;
  };
};

export type IVBillVBillGetBatchListQueryVariables = Exact<{
  pagination: IVBillBatchPaginationInput;
  filters: IVBillBatchListFiltersInput;
  grouping?: InputMaybe<IVBillBatchListStatsGroupingInput>;
}>;

export type IVBillVBillGetBatchListQuery = {
  __typename?: "Query";
  getBatchList: {
    __typename?: "BatchListResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchListPaginatedResponse";
      total: number;
      hasMore: boolean;
      items: Array<{
        __typename?: "BatchOutput";
        id: number;
        organizationId: string;
        name: string;
        createdAt?: any | null;
        status: IVBillBatchStatus;
        budget?: string | null;
        batchDate?: string | null;
        batchDateDue?: string | null;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        createdByName?: string | null;
        createdByEmail?: string | null;
        createdByPhoneNumber?: string | null;
        invoicesAmount?: string | null;
        parentBatchId?: number | null;
        batchPath: Array<number>;
        invoicesAmountInSubBatches?: string | null;
        batchFilters?: {
          __typename?: "BillsFiltersOutput";
          organizationId?: string | null;
          companyIds?: Array<string> | null;
          ids?: Array<number> | null;
          contact_external_ref_ids?: Array<string> | null;
          search?: string | null;
          status?: IVBillInvoiceIncomingStatus | null;
          pushStatus?: IVBillInvoicePushStatus | null;
          integrationStatus?: IVBillInvoiceIntegrationStatus | null;
          date_unit_type?: IVBillBatchDateUnitType | null;
          date_unit_from?: number | null;
          date_unit_to?: number | null;
          date_unit_now?: string | null;
          date?: string | null;
          date_to?: string | null;
          date_due_from?: string | null;
          date_due_to?: string | null;
          amount_from?: string | null;
          amount_to?: string | null;
          free_amount_from?: string | null;
          free_amount_to?: string | null;
          vbill_number_sort?: IVBillSortOrderType | null;
          status_sort?: IVBillSortOrderType | null;
          amount_sort?: IVBillSortOrderType | null;
          date_sort?: IVBillSortOrderType | null;
          date_due_sort?: IVBillSortOrderType | null;
          related_to_invoice_id?: number | null;
          batch_id_in?: Array<number> | null;
          relativeReferenceDate?: {
            __typename?: "BillsFiltersReferenceDateOutput";
            now?: boolean | null;
            customDate?: string | null;
            batchDate?: boolean | null;
          } | null;
          relativeDateFrom?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          relativeDateTo?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          relativeDateDueFrom?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          relativeDateDueTo?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          mappings?: Array<{
            __typename?: "BillsFiltersMappingOutput";
            key: string;
            valuesIn: Array<{
              __typename?: "BillsFiltersMappingValueOutput";
              value?: string | null;
              source?: string | null;
            }>;
          }> | null;
        } | null;
        batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
        batchPayments: Array<{
          __typename?: "BatchPaymentOutput";
          batchId: number;
          paymentId: number;
          invoiceId: number;
          paymentAmountForBillInBatch: string;
          paymentPushStatus: IVBillPaymentPushStatus;
          paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
          paymentStatus: IVBillPaymentStatus;
          paymentErrors: Array<{
            __typename?: "BatchPaymentErrorOutput";
            type: IVBillBatchPaymentErrorType;
            messages: Array<string>;
          }>;
        }>;
        batchTreeDetails?: {
          __typename?: "BatchTreeDetails";
          batchIds: Array<number>;
          commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
          paymentsStats: Array<{
            __typename?: "PaymentsGroupStatsOutput";
            count: string;
            amount: string;
            groupingType: IVBillPaymentsGroupingType;
            minDate?: string | null;
            maxDate?: string | null;
            value: {
              __typename?: "PaymentsGroupingValue";
              ALL?: string | null;
              STATUS?: IVBillPaymentsGroupingStatus | null;
            };
          }>;
        } | null;
      }>;
      settings: { __typename?: "BatchSettingsOutput"; BATCH_SUBBATCHES_ALLOWED: boolean };
    } | null;
  };
};

export type IVBillVBillGetBatchListStatsQueryVariables = Exact<{
  filters: IVBillBatchListFiltersInput;
  grouping?: InputMaybe<IVBillBatchListStatsGroupingInput>;
}>;

export type IVBillVBillGetBatchListStatsQuery = {
  __typename?: "Query";
  getBatchListStats: {
    __typename?: "BatchStatsResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchStatsOutput";
      generalStats: { __typename?: "BatchGeneralStatsOutput"; count: string; amount: string };
      groupStats: Array<{
        __typename?: "BatchGroupStatsOutput";
        count: string;
        amount: string;
        groupingType?: IVBillBatchGroupingType | null;
        minDate?: string | null;
        maxDate?: string | null;
        minDateDue?: string | null;
        maxDateDue?: string | null;
        value?: {
          __typename?: "BatchGrouingValueOutput";
          ALL?: string | null;
          BATCH_STATUS?: IVBillBatchStatus | null;
          BATCH_PAYMENTS_STATUS?: IVBillPaymentsGroupingStatus | null;
        } | null;
      }>;
    } | null;
  };
};

export type IVBillVBillGetBatchMembersQueryVariables = Exact<{
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillGetBatchMembersQuery = {
  __typename?: "Query";
  getBatchMembers: {
    __typename?: "BatchMemberListStatusResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchMemberListResponse";
      items: Array<{
        __typename?: "BatchMemberSchema";
        id: number;
        coreOrgUserId: string;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        batchId: number;
      }>;
    } | null;
  };
};

export type IVBillVBillGetBatchTreeQueryVariables = Exact<{
  organizationId: Scalars["String"]["input"];
  batchIds: Array<Scalars["Float"]["input"]> | Scalars["Float"]["input"];
  parentBatchId: Scalars["Float"]["input"];
}>;

export type IVBillVBillGetBatchTreeQuery = {
  __typename?: "Query";
  batchData: {
    __typename?: "BatchListResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchListPaginatedResponse";
      total: number;
      hasMore: boolean;
      items: Array<{
        __typename?: "BatchOutput";
        id: number;
        organizationId: string;
        name: string;
        budget?: string | null;
        batchDateDue?: string | null;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        createdByName?: string | null;
        createdByEmail?: string | null;
        createdByPhoneNumber?: string | null;
        invoicesAmount?: string | null;
        parentBatchId?: number | null;
        batchPath: Array<number>;
        invoicesAmountInSubBatches?: string | null;
        batchFilters?: {
          __typename?: "BillsFiltersOutput";
          organizationId?: string | null;
          companyIds?: Array<string> | null;
          ids?: Array<number> | null;
          contact_external_ref_ids?: Array<string> | null;
          search?: string | null;
          status?: IVBillInvoiceIncomingStatus | null;
          pushStatus?: IVBillInvoicePushStatus | null;
          integrationStatus?: IVBillInvoiceIntegrationStatus | null;
          date_unit_type?: IVBillBatchDateUnitType | null;
          date_unit_from?: number | null;
          date_unit_to?: number | null;
          date_unit_now?: string | null;
          date?: string | null;
          date_to?: string | null;
          date_due_from?: string | null;
          date_due_to?: string | null;
          amount_from?: string | null;
          amount_to?: string | null;
          free_amount_from?: string | null;
          free_amount_to?: string | null;
          vbill_number_sort?: IVBillSortOrderType | null;
          status_sort?: IVBillSortOrderType | null;
          amount_sort?: IVBillSortOrderType | null;
          date_sort?: IVBillSortOrderType | null;
          date_due_sort?: IVBillSortOrderType | null;
          related_to_invoice_id?: number | null;
          batch_id_in?: Array<number> | null;
          relativeReferenceDate?: {
            __typename?: "BillsFiltersReferenceDateOutput";
            now?: boolean | null;
            customDate?: string | null;
            batchDate?: boolean | null;
          } | null;
          relativeDateFrom?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          relativeDateTo?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          relativeDateDueFrom?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          relativeDateDueTo?: {
            __typename?: "BillsFiltersRelativeDateOutput";
            value: number;
            unit: IVBillBatchDateUnitType;
            temporality: IVBillTemporality;
          } | null;
          mappings?: Array<{
            __typename?: "BillsFiltersMappingOutput";
            key: string;
            valuesIn: Array<{
              __typename?: "BillsFiltersMappingValueOutput";
              value?: string | null;
              source?: string | null;
            }>;
          }> | null;
        } | null;
      }>;
    } | null;
  };
  batchChildren: {
    __typename?: "BatchListResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchListPaginatedResponse";
      total: number;
      items: Array<{ __typename?: "BatchOutput"; id: number }>;
    } | null;
  };
};

export type IVBillVBillGetBillsQueryVariables = Exact<{
  pagination: IVBillInvoicePaginationInput;
  filters: IVBillBillsFiltersInput;
}>;

export type IVBillVBillGetBillsQuery = {
  __typename?: "Query";
  getBills: {
    __typename?: "BillsResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "InvoiceLightSchema";
      id: number;
      hashedId: string;
      coreOrgId: string;
      companyId?: string | null;
      createdAt: string;
      status: IVBillInvoiceIncomingStatus;
      pushStatus?: IVBillInvoicePushStatus | null;
      emailSubject?: string | null;
      emailDate?: string | null;
      emailUrl?: string | null;
      emailAttachmentUrls?: Array<string> | null;
      emailBodyText?: string | null;
      expenseType?: IVBillExpenseType | null;
      initialSenderName?: string | null;
      initialSenderEmail?: string | null;
      senderName?: string | null;
      senderAddress?: string | null;
      senderEmail?: string | null;
      senderEmails?: Array<string> | null;
      senderPhone?: string | null;
      recipientName?: string | null;
      recipientAddress?: string | null;
      recipientEmail?: string | null;
      recipientEmails?: Array<string> | null;
      recipientPhone?: string | null;
      invoiceNumber?: string | null;
      invoiceDate?: string | null;
      invoiceDateDue?: string | null;
      mainFileId?: string | null;
      suggestedMainFileId?: string | null;
      filesOcrState?: IVBillKInvoiceFilesOcrState | null;
      hasLineItems: boolean;
      approvalCount?: number | null;
      integrationRef?: string | null;
      integrationStatus?: IVBillInvoiceIntegrationStatus | null;
      actionRequiredStatus?: IVBillInvoiceActionRequiredStatus | null;
      amount: string;
      contactExternalRef?: string | null;
      canBePaid?: boolean | null;
      isPotentialDuplicate?: boolean | null;
      statusFlags: {
        __typename?: "InvoiceStatusFlagsSchema";
        isOCRComplete: boolean;
        isCompanyMappingComplete: boolean;
        isMappingComplete: boolean;
        canBeEdited: boolean;
      };
      expenseReport?: {
        __typename?: "AbstractExpenseReportOutput";
        expenseDate: string;
        employeeId?: string | null;
        reasonForExpense?: string | null;
        attachmentKeys?: Array<string> | null;
        amount: string;
        payee: {
          __typename?: "AbstractExpensePayeeOutput";
          email?: string | null;
          bankName?: string | null;
          bankAccountType?: IVBillBankAccountType | null;
          bankAccountNumber?: string | null;
          bankRoutingNumber?: string | null;
        };
      } | null;
      approvalStatus?: {
        __typename?: "ApprovalStatusSchema";
        potentialApprovers: Array<string>;
        approvedBy: Array<string>;
        potentialReviewers: Array<string>;
        reviewedBy: Array<string>;
        declinedBy: Array<string>;
        isApproved: boolean;
      } | null;
      additionalMappings?: Array<{
        __typename?: "AdditionalMappingSchema";
        key: string;
        value: string;
        type: IVBillStoreIoDataType;
        label?: string | null;
        label2?: string | null;
        parentLabel?: string | null;
      }> | null;
      payments?: Array<{
        __typename?: "InvoicePaymentOutput";
        paymentId: number;
        companyId: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentAmount: string;
        paymentDate: string;
        vCheckNumber?: string | null;
        vCheckId?: string | null;
        paymentExternalRef?: string | null;
        paymentExternalUrl?: string | null;
        batchId?: number | null;
        invoiceId: number;
        invoiceRelationType?: string | null;
        memo?: string | null;
        submittedByUserAccount?: {
          __typename?: "PaymentSubmittedByUserAccount";
          userId: string;
          organizationUserId: string;
          userName?: string | null;
          userEmail?: string | null;
          userPhoneNumber?: string | null;
        } | null;
        paymentFromGlAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentToVendorAccount: {
          __typename?: "PaymentAccount";
          key: string;
          settingsKey: string;
          storeType: IVBillStoreIoDataType;
          source?: string | null;
          label?: string | null;
          label2?: string | null;
          parentLabel?: string | null;
        };
        paymentError?: {
          __typename?: "ErrorOutput";
          message: string;
          type: IVBillGqlErrorTypes;
          codes?: Array<string> | null;
        } | null;
      }> | null;
      amounts?: {
        __typename?: "InvoiceAmounts";
        amount: string;
        amountInPayments: string;
        amountInBatches: string;
        amountFreeToPay: string;
        amountInBatchesBreakdown: Array<{
          __typename?: "InvoiceAmountInBatchDetail";
          batchId: number;
          invoiceId: number;
          batchAmountForInvoice: string;
          memo?: string | null;
        }>;
        paymentsStats: {
          __typename?: "InvoicePaymentsStats";
          successful: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          failed: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
          pending: {
            __typename?: "PaymentsStats";
            count: number;
            sum: string;
            minDate?: string | null;
            maxDate?: string | null;
          };
        };
      } | null;
      syncError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      displayError?: {
        __typename?: "ErrorOutput";
        message: string;
        type: IVBillGqlErrorTypes;
        codes?: Array<string> | null;
      } | null;
      nextUserActions: Array<{
        __typename?: "InvoiceUserActionSchema";
        type: IVBillInvoiceUserActionType;
        showIn: IVBillInvoiceUserActionShowIn;
        displayName: string;
        description: string;
      }>;
    }>;
  };
};

export type IVBillVBillGetBillsStatsQueryVariables = Exact<{
  filters: IVBillBillsFiltersInput;
  grouping?: InputMaybe<IVBillBillsStatsGroupingInput>;
}>;

export type IVBillVBillGetBillsStatsQuery = {
  __typename?: "Query";
  getBillsStats: {
    __typename?: "BillsStatsOutput";
    generalStats: { __typename?: "BillsGeneralStatsOutput"; count: number; amount: string; balance: string };
    groupStats: Array<{
      __typename?: "BillsGroupStatsOutput";
      count: number;
      amount: string;
      value?: string | null;
      source?: string | null;
      label?: string | null;
      minDate?: string | null;
      maxDate?: string | null;
      minDateDue?: string | null;
      maxDateDue?: string | null;
    }>;
  };
};

export type IVBillVBillGetReactionsListQueryVariables = Exact<{
  pagination: IVBillReactionPaginationInput;
  filters: IVBillReactionsFiltersInput;
}>;

export type IVBillVBillGetReactionsListQuery = {
  __typename?: "Query";
  getReactionsList: {
    __typename?: "ReactionsListResponse";
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: "ReactionsItemResponse";
      type: IVBillReactionType;
      changedByOrganizationUserId: string;
      changedByAccountId: string;
      changedByName: string;
      changedByEmailAddress: string;
    }>;
  };
};

export type IVBillVBillGetReactionsStatsQueryVariables = Exact<{
  filters: Array<IVBillReactionsFiltersInput> | IVBillReactionsFiltersInput;
}>;

export type IVBillVBillGetReactionsStatsQuery = {
  __typename?: "Query";
  getReactionsStats: {
    __typename?: "ReactionsStatsResponse";
    total: number;
    includesMyReaction: boolean;
    groups?: Array<{
      __typename?: "ReactionsGroupStatsSchema";
      parentType?: IVBillReactionParentType | null;
      parentKey?: string | null;
      includesMyReaction: boolean;
      stats: Array<{
        __typename?: "ReactionsGroupStatsGroupSchema";
        type?: IVBillReactionType | null;
        count?: number | null;
        includesMyReaction: boolean;
      }>;
    }> | null;
  };
};

export type IVBillVBillRemoveCompaniesFromBatchMutationVariables = Exact<{
  companyIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillRemoveCompaniesFromBatchMutation = {
  __typename?: "Mutation";
  removeCompaniesFromBatch: {
    __typename?: "BatchCompanyListStatusResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchCompanyListPaginatedResponse";
      items: Array<{
        __typename?: "BatchCompanySchema";
        id: number;
        coreId: string;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        batchId: number;
      }>;
    } | null;
  };
};

export type IVBillVBillRemoveInvoicesFromBatchMutationVariables = Exact<{
  invoiceIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillRemoveInvoicesFromBatchMutation = {
  __typename?: "Mutation";
  removeInvoicesFromBatch: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDate?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
      batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
      batchPayments: Array<{
        __typename?: "BatchPaymentOutput";
        batchId: number;
        paymentId: number;
        invoiceId: number;
        paymentAmountForBillInBatch: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentStatus: IVBillPaymentStatus;
        paymentErrors: Array<{
          __typename?: "BatchPaymentErrorOutput";
          type: IVBillBatchPaymentErrorType;
          messages: Array<string>;
        }>;
      }>;
      batchTreeDetails?: {
        __typename?: "BatchTreeDetails";
        batchIds: Array<number>;
        commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
        paymentsStats: Array<{
          __typename?: "PaymentsGroupStatsOutput";
          count: string;
          amount: string;
          groupingType: IVBillPaymentsGroupingType;
          minDate?: string | null;
          maxDate?: string | null;
          value: {
            __typename?: "PaymentsGroupingValue";
            ALL?: string | null;
            STATUS?: IVBillPaymentsGroupingStatus | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type IVBillVBillRemoveMembersFromBatchMutationVariables = Exact<{
  memberIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillRemoveMembersFromBatchMutation = {
  __typename?: "Mutation";
  removeMembersFromBatch: {
    __typename?: "BatchMemberListStatusResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchMemberListResponse";
      items: Array<{
        __typename?: "BatchMemberSchema";
        id: number;
        coreOrgUserId: string;
        createdByAccountId?: string | null;
        createdByOrgUserId?: string | null;
        batchId: number;
      }>;
    } | null;
  };
};

export type IVBillVBillStoreItemsForOrganizationQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  type: IVBillStoreIoDataType;
  organizationId: Scalars["String"]["input"];
  companyIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  keySourcePairs?: InputMaybe<Array<IVBillStoreKeySourcePair> | IVBillStoreKeySourcePair>;
}>;

export type IVBillVBillStoreItemsForOrganizationQuery = {
  __typename?: "Query";
  storeItemsForOrganization: Array<{
    __typename?: "StoreItemSchema";
    organizationId: string;
    companyId?: string | null;
    key: string;
    type: IVBillStoreIoDataType;
    label?: string | null;
    label2?: string | null;
    parentLabel?: string | null;
    source?: string | null;
    data?: {
      __typename?: "StoreIoDataOutput";
      contact?: {
        __typename?: "StoreIoDataContactOutput";
        name?: string | null;
        address?: string | null;
        email?: string | null;
        phone?: string | null;
        addressList?: Array<{
          __typename?: "StoreIoDataContactAddressOutput";
          type?: string | null;
          address?: string | null;
          address_line_2?: string | null;
          city?: string | null;
          country?: string | null;
          state?: string | null;
          zip_code?: string | null;
        }> | null;
        emailList?: Array<{
          __typename?: "StoreIoDataContactEmailOutput";
          type?: string | null;
          description?: string | null;
          email?: string | null;
        }> | null;
        phoneList?: Array<{
          __typename?: "StoreIoDataContactPhoneNumberOutput";
          type?: string | null;
          description?: string | null;
          number?: string | null;
        }> | null;
      } | null;
      glAccount?: {
        __typename?: "StoreIoDataGlAccountOutput";
        name?: string | null;
        accountNo?: string | null;
        type?: string | null;
      } | null;
    } | null;
    meta?: Array<{
      __typename?: "StoreIoMetaItemOutput";
      label?: string | null;
      order?: number | null;
      value?: string | null;
    }> | null;
  }>;
};

export type IVBillVBillUpdateBatchMutationVariables = Exact<{
  batchData: IVBillNullableBatchInput;
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillUpdateBatchMutation = {
  __typename?: "Mutation";
  updateBatch: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDate?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
      batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
      batchPayments: Array<{
        __typename?: "BatchPaymentOutput";
        batchId: number;
        paymentId: number;
        invoiceId: number;
        paymentAmountForBillInBatch: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentStatus: IVBillPaymentStatus;
        paymentErrors: Array<{
          __typename?: "BatchPaymentErrorOutput";
          type: IVBillBatchPaymentErrorType;
          messages: Array<string>;
        }>;
      }>;
      batchTreeDetails?: {
        __typename?: "BatchTreeDetails";
        batchIds: Array<number>;
        commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
        paymentsStats: Array<{
          __typename?: "PaymentsGroupStatsOutput";
          count: string;
          amount: string;
          groupingType: IVBillPaymentsGroupingType;
          minDate?: string | null;
          maxDate?: string | null;
          value: {
            __typename?: "PaymentsGroupingValue";
            ALL?: string | null;
            STATUS?: IVBillPaymentsGroupingStatus | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type IVBillVBillUpdateBatchStatusMutationVariables = Exact<{
  batchStatus: IVBillNullableBatchStatusInput;
  batchId: Scalars["Int"]["input"];
}>;

export type IVBillVBillUpdateBatchStatusMutation = {
  __typename?: "Mutation";
  updateBatchStatus: {
    __typename?: "BatchResponse";
    status: IVBillBatchResponseStatus;
    error?: string | null;
    data?: {
      __typename?: "BatchOutput";
      id: number;
      organizationId: string;
      name: string;
      createdAt?: any | null;
      status: IVBillBatchStatus;
      budget?: string | null;
      batchDate?: string | null;
      batchDateDue?: string | null;
      createdByAccountId?: string | null;
      createdByOrgUserId?: string | null;
      createdByName?: string | null;
      createdByEmail?: string | null;
      createdByPhoneNumber?: string | null;
      invoicesAmount?: string | null;
      parentBatchId?: number | null;
      batchPath: Array<number>;
      invoicesAmountInSubBatches?: string | null;
      batchFilters?: {
        __typename?: "BillsFiltersOutput";
        organizationId?: string | null;
        companyIds?: Array<string> | null;
        ids?: Array<number> | null;
        contact_external_ref_ids?: Array<string> | null;
        search?: string | null;
        status?: IVBillInvoiceIncomingStatus | null;
        pushStatus?: IVBillInvoicePushStatus | null;
        integrationStatus?: IVBillInvoiceIntegrationStatus | null;
        date_unit_type?: IVBillBatchDateUnitType | null;
        date_unit_from?: number | null;
        date_unit_to?: number | null;
        date_unit_now?: string | null;
        date?: string | null;
        date_to?: string | null;
        date_due_from?: string | null;
        date_due_to?: string | null;
        amount_from?: string | null;
        amount_to?: string | null;
        free_amount_from?: string | null;
        free_amount_to?: string | null;
        vbill_number_sort?: IVBillSortOrderType | null;
        status_sort?: IVBillSortOrderType | null;
        amount_sort?: IVBillSortOrderType | null;
        date_sort?: IVBillSortOrderType | null;
        date_due_sort?: IVBillSortOrderType | null;
        related_to_invoice_id?: number | null;
        batch_id_in?: Array<number> | null;
        relativeReferenceDate?: {
          __typename?: "BillsFiltersReferenceDateOutput";
          now?: boolean | null;
          customDate?: string | null;
          batchDate?: boolean | null;
        } | null;
        relativeDateFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueFrom?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        relativeDateDueTo?: {
          __typename?: "BillsFiltersRelativeDateOutput";
          value: number;
          unit: IVBillBatchDateUnitType;
          temporality: IVBillTemporality;
        } | null;
        mappings?: Array<{
          __typename?: "BillsFiltersMappingOutput";
          key: string;
          valuesIn: Array<{
            __typename?: "BillsFiltersMappingValueOutput";
            value?: string | null;
            source?: string | null;
          }>;
        }> | null;
      } | null;
      batchPathDetails: Array<{ __typename?: "BatchPathDetailsItem"; id: number; name: string; hasAccess: boolean }>;
      batchPayments: Array<{
        __typename?: "BatchPaymentOutput";
        batchId: number;
        paymentId: number;
        invoiceId: number;
        paymentAmountForBillInBatch: string;
        paymentPushStatus: IVBillPaymentPushStatus;
        paymentIntegrationStatus: IVBillPaymentIntegrationStatus;
        paymentStatus: IVBillPaymentStatus;
        paymentErrors: Array<{
          __typename?: "BatchPaymentErrorOutput";
          type: IVBillBatchPaymentErrorType;
          messages: Array<string>;
        }>;
      }>;
      batchTreeDetails?: {
        __typename?: "BatchTreeDetails";
        batchIds: Array<number>;
        commonStats: { __typename?: "BatchTreeCommonStats"; batchesCount?: number | null };
        paymentsStats: Array<{
          __typename?: "PaymentsGroupStatsOutput";
          count: string;
          amount: string;
          groupingType: IVBillPaymentsGroupingType;
          minDate?: string | null;
          maxDate?: string | null;
          value: {
            __typename?: "PaymentsGroupingValue";
            ALL?: string | null;
            STATUS?: IVBillPaymentsGroupingStatus | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type IVBillVBillUpsertReactionMutationVariables = Exact<{
  type: IVBillReactionType;
  parentKey: Scalars["String"]["input"];
  parentType: IVBillReactionParentType;
}>;

export type IVBillVBillUpsertReactionMutation = {
  __typename?: "Mutation";
  upsertReaction: {
    __typename?: "ReactionsItemResponse";
    type: IVBillReactionType;
    changedByOrganizationUserId: string;
    changedByAccountId: string;
    changedByName: string;
    changedByEmailAddress: string;
  };
};

export const InvoiceFragmentFragmentDoc = gql`
  fragment InvoiceFragment on InvoiceSchema {
    id
    hashedId
    coreOrgId
    companyId
    createdAt
    integrationStatus
    actionRequiredStatus
    company {
      id
      companyId
      orgId
      orgName
      emailHash
      name
    }
    expenseType
    expenseReport {
      expenseDate
      employeeId
      reasonForExpense
      attachmentKeys
      paymentMethod
      payee {
        email
        bankName
        bankAccountType
        bankAccountNumber
        bankRoutingNumber
        addressStreet
        addressCity
        addressState
        addressPostalCode
        uid
      }
      amount
      additionalFields {
        type
        key
        label
        value
        typeOfValue
        order
      }
      id
      createdAt
      expenseReportNumber
    }
    status
    statusFlags {
      isOCRComplete
      isMappingComplete
      canBeEdited
    }
    approvalStatus {
      isApproved
      potentialApprovers
      approvedBy
      potentialReviewers
      reviewedBy
      declinedBy
    }
    filesOcrState
    pushStatus
    emailSubject
    emailDate
    emailUrl
    emailAttachmentUrls
    emailBodyText
    hasLineItems
    lineItems {
      id
      invoiceId
      glMappings {
        id
        lineItemId
        qty
        additionalMappings {
          key
          value
          type
          label
          label2
          parentLabel
        }
      }
      description
      um
      qty
      rate
      amount
      type
    }
    initialSenderName
    initialSenderEmail
    senderName
    senderAddress
    senderEmail
    senderEmails
    senderPhone
    recipientName
    recipientAddress
    recipientEmail
    recipientEmails
    recipientPhone
    invoiceNumber
    invoiceDate
    invoiceDateDue
    payments {
      paymentId
      companyId
      paymentPushStatus
      paymentIntegrationStatus
      paymentAmount
      paymentDate
      submittedByUserAccount {
        userId
        organizationUserId
        userName
        userEmail
        userPhoneNumber
      }
      paymentFromGlAccount {
        key
        settingsKey
        storeType
        source
        label
        label2
        parentLabel
      }
      paymentToVendorAccount {
        key
        settingsKey
        storeType
        source
        label
        label2
        parentLabel
      }
      vCheckNumber
      vCheckId
      paymentExternalRef
      paymentExternalUrl
      batchId
      invoiceId
      invoiceRelationType
      memo
      paymentError {
        message
        type
        codes
      }
    }
    amounts {
      amount
      amountInPayments
      amountInBatches
      amountFreeToPay
      amountInBatchesBreakdown {
        batchId
        invoiceId
        batchAmountForInvoice
        memo
      }
      paymentsStats {
        successful {
          count
          sum
          minDate
          maxDate
        }
        failed {
          count
          sum
          minDate
          maxDate
        }
        pending {
          count
          sum
          minDate
          maxDate
        }
      }
    }
    files {
      id
      invoiceId
      movedToInvoice {
        id
        status
        invoiceNumber
      }
      fileOcrState
      fileIsEncrypted
      name
      size
      pageCount
      pages {
        id
        movedToInvoiceId
        pageNumber
        contentType
        size
      }
      metadata {
        OPENAI {
          amount
          invoiceNumber
          invoiceDate
          invoiceDateDue
          senderName
          senderAddress
          senderEmail
          senderPhone
          recipientName
          recipientAddress
          recipientEmail
          recipientPhone
          lineItems {
            type
            confidence
            amount {
              confidence
              value
            }
            description {
              confidence
              value
            }
            um {
              confidence
              value
            }
            qty {
              confidence
              value
            }
            rate {
              confidence
              value
            }
          }
          amount
        }
        GDAI {
          amount
          invoiceNumber
          invoiceDate
          invoiceDateDue
          senderName
          senderAddress
          senderEmail
          senderPhone
          recipientName
          recipientAddress
          recipientEmail
          recipientPhone
          lineItems {
            type
            confidence
            amount {
              confidence
              value
            }
            description {
              confidence
              value
            }
            um {
              confidence
              value
            }
            qty {
              confidence
              value
            }
            rate {
              confidence
              value
            }
          }
          amount
        }
        AOCR {
          invoiceNumber
          invoiceDate
          invoiceDateDue
          senderName
          senderAddress
          senderEmail
          senderPhone
          recipientName
          recipientAddress
          recipientEmail
          recipientPhone
          amount
        }
      }
    }
    mainFile {
      id
      name
    }
    actions {
      id
      createdAt
      key
      type
      path {
        key
        type
        label
      }
      related {
        key
        type
        label
      }
      changedBy {
        accountId
        organizationUserId
        name
      }
      initiator
      scope
      action
      level
      meta {
        content {
          type
          message
          template
          bindings {
            key
            value
          }
          related {
            key
            related {
              key
              type
              label
            }
          }
        }
        additionalContent {
          type
          message
          template
          bindings {
            key
            value
          }
          related {
            key
            related {
              key
              type
              label
            }
          }
          columns {
            icon
            message
            template
            bindings {
              key
              value
            }
          }
          rows {
            cells {
              icon
              message
              template
              bindings {
                key
                value
              }
            }
          }
        }
      }
    }
    integrationRef
    amount
    approvalCount
    approvals {
      id
      createdAt
      updatedAt
      orgUserId
      status
      invoiceId
    }
    additionalMappings {
      key
      value
      type
      label
      label2
      parentLabel
    }
    canBePaid
    isPotentialDuplicate
    suggestedMainFileId
    syncError {
      message
      type
      codes
    }
    displayError {
      message
      type
      codes
    }
    nextUserActions {
      type
      showIn
      displayName
      description
    }
  }
`;
export const InvoiceListFragmentFragmentDoc = gql`
  fragment InvoiceListFragment on InvoiceLightSchema {
    id
    hashedId
    coreOrgId
    companyId
    createdAt
    status
    statusFlags {
      isOCRComplete
      isCompanyMappingComplete
      isMappingComplete
      canBeEdited
    }
    pushStatus
    emailSubject
    emailDate
    emailUrl
    emailAttachmentUrls
    emailBodyText
    expenseType
    expenseReport {
      expenseDate
      employeeId
      reasonForExpense
      attachmentKeys
      payee {
        email
        bankName
        bankAccountType
        bankAccountNumber
        bankRoutingNumber
      }
      amount
    }
    initialSenderName
    initialSenderEmail
    senderName
    senderAddress
    senderEmail
    senderEmails
    senderPhone
    recipientName
    recipientAddress
    recipientEmail
    recipientEmails
    recipientPhone
    invoiceNumber
    invoiceDate
    invoiceDateDue
    mainFileId
    suggestedMainFileId
    filesOcrState
    hasLineItems
    approvalCount
    approvalStatus {
      potentialApprovers
      approvedBy
      potentialReviewers
      reviewedBy
      declinedBy
      isApproved
    }
    integrationRef
    integrationStatus
    actionRequiredStatus
    amount
    additionalMappings {
      key
      value
      type
      label
      label2
      parentLabel
    }
    contactExternalRef
    payments {
      paymentId
      companyId
      paymentPushStatus
      paymentIntegrationStatus
      paymentAmount
      paymentDate
      submittedByUserAccount {
        userId
        organizationUserId
        userName
        userEmail
        userPhoneNumber
      }
      paymentFromGlAccount {
        key
        settingsKey
        storeType
        source
        label
        label2
        parentLabel
      }
      paymentToVendorAccount {
        key
        settingsKey
        storeType
        source
        label
        label2
        parentLabel
      }
      vCheckNumber
      vCheckId
      paymentExternalRef
      paymentExternalUrl
      batchId
      invoiceId
      invoiceRelationType
      memo
      paymentError {
        message
        type
        codes
      }
    }
    amounts {
      amount
      amountInPayments
      amountInBatches
      amountFreeToPay
      amountInBatchesBreakdown {
        batchId
        invoiceId
        batchAmountForInvoice
        memo
      }
      paymentsStats {
        successful {
          count
          sum
          minDate
          maxDate
        }
        failed {
          count
          sum
          minDate
          maxDate
        }
        pending {
          count
          sum
          minDate
          maxDate
        }
      }
    }
    canBePaid
    isPotentialDuplicate
    syncError {
      message
      type
      codes
    }
    displayError {
      message
      type
      codes
    }
    nextUserActions {
      type
      showIn
      displayName
      description
    }
  }
`;
export const VBillAddCommentDocument = gql`
  mutation VBillAddComment($comment: String!, $invoiceId: Int!) {
    addInvoiceComment(comment: $comment, invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillCheckInvoiceForDuplicatesDocument = gql`
  query VBillCheckInvoiceForDuplicates($invoiceData: NullableInvoiceInput!, $invoiceId: Int!) {
    checkInvoiceForDuplicates(invoiceData: $invoiceData, invoiceId: $invoiceId) {
      invoice {
        ...InvoiceFragment
      }
      relation {
        fromInvoiceId
        toInvoiceId
        type
        status
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillCompaniesDocument = gql`
  query VBillCompanies($organizationId: String!) {
    companies(organizationId: $organizationId) {
      items {
        id
        companyId
        orgId
        orgName
        emailHash
        name
        organization {
          id
          coreOrgId
          organizationEmailHash
          organizationName
        }
      }
      total
      hasMore
    }
  }
`;
export const VBillCompleteInvoiceMappingDocument = gql`
  mutation VBillCompleteInvoiceMapping($invoiceId: Int!) {
    completeInvoiceMapping(invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillCompleteInvoiceOcrDocument = gql`
  mutation VBillCompleteInvoiceOCR($invoiceId: Int!) {
    completeInvoiceOCR(invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillCopyInvoiceDocument = gql`
  mutation VBillCopyInvoice($invoiceId: Int!) {
    copyInvoice(invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillCreateBatchPaymentsDocument = gql`
  mutation VBillCreateBatchPayments($payableBatch: PayableBatchInput!) {
    createBatchPayments(payableBatch: $payableBatch) {
      success
      batch {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDate
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        batchPathDetails {
          id
          name
          hasAccess
        }
        invoicesAmountInSubBatches
        batchPayments {
          batchId
          paymentId
          invoiceId
          paymentAmountForBillInBatch
          paymentPushStatus
          paymentIntegrationStatus
          paymentStatus
          paymentErrors {
            type
            messages
          }
        }
        batchTreeDetails {
          batchIds
          commonStats {
            batchesCount
          }
          paymentsStats {
            count
            amount
            groupingType
            value {
              ALL
              STATUS
            }
            minDate
            maxDate
          }
        }
      }
      payments {
        paymentId
        companyId
        paymentPushStatus
        paymentIntegrationStatus
        paymentStatus
        paymentAmount
        paymentDate
        submittedByUserAccount {
          userId
          organizationUserId
          userName
          userEmail
          userPhoneNumber
        }
        paymentFromGlAccount {
          coreOrgId
          coreCompanyId
          key
          settingsKey
          storeType
          source
          label
          label2
          parentLabel
        }
        paymentToVendorAccount {
          coreOrgId
          coreCompanyId
          key
          settingsKey
          storeType
          source
          label
          label2
          parentLabel
        }
        vCheckNumber
        vCheckId
        vCheckState
        vCheckPaymentType
        paymentExternalRef
        paymentExternalUrl
        batchId
        invoiceId
        invoiceRelationType
        memo
        paymentError {
          message
          type
          codes
        }
      }
    }
  }
`;
export const VBillCreateBillsPaymentsDocument = gql`
  mutation VBillCreateBillsPayments(
    $payableBills: [PayableInvoiceInput!]!
    $organizationId: String!
    $grouping: PaymentGrouping
  ) {
    createBillsPayments(payableBills: $payableBills, organizationId: $organizationId, grouping: $grouping) {
      id
      coreOrgId
      companyId
      status
      payments {
        paymentId
        companyId
        paymentPushStatus
        paymentIntegrationStatus
        paymentAmount
        paymentDate
        submittedByUserAccount {
          userId
          organizationUserId
          userName
          userEmail
          userPhoneNumber
        }
        paymentFromGlAccount {
          key
          settingsKey
          storeType
          source
          label
          label2
          parentLabel
        }
        paymentToVendorAccount {
          key
          settingsKey
          storeType
          source
          label
          label2
          parentLabel
        }
        vCheckNumber
        vCheckId
        paymentExternalRef
        paymentExternalUrl
        batchId
        invoiceId
        memo
        paymentError {
          message
          type
          codes
        }
      }
      amounts {
        amount
        amountInPayments
        amountInBatches
        amountFreeToPay
        amountInBatchesBreakdown {
          batchId
          invoiceId
          batchAmountForInvoice
          memo
        }
        paymentsStats {
          successful {
            count
            sum
            minDate
            maxDate
          }
          failed {
            count
            sum
            minDate
            maxDate
          }
          pending {
            count
            sum
            minDate
            maxDate
          }
        }
      }
      amount
    }
  }
`;
export const VBillCreateExpenseReportDocument = gql`
  mutation VBillCreateExpenseReport($token: String!, $input: ExpenseReportInput!) {
    createExpenseReport(token: $token, input: $input) {
      id
      expenseReportNumber
      amount
      createdAt
      expenseDate
      employeeId
      reasonForExpense
      attachmentKeys
      payee {
        email
        bankName
        bankAccountType
        bankAccountNumber
        bankRoutingNumber
        uid
      }
    }
  }
`;
export const VBillDeleteInvoicesDocument = gql`
  mutation VBillDeleteInvoices($invoiceIds: [Int!]!) {
    deleteInvoices(invoiceIds: $invoiceIds)
  }
`;
export const VBillFilesInvoiceDocument = gql`
  query VBillFilesInvoice($pagination: FilePaginationInput!, $filters: FileFiltersInput!) {
    files(pagination: $pagination, filters: $filters) {
      items {
        id
        pageNumber
        pageCount
        contentType
        movedToInvoiceId
      }
      total
      hasMore
    }
  }
`;
export const VBillFilesOcrDocument = gql`
  query VBillFilesOcr($pagination: FilePaginationInput!, $filters: FileFiltersInput!) {
    files(pagination: $pagination, filters: $filters) {
      items {
        id
        size
        pageNumber
        pageCount
        contentType
        movedToInvoiceId
        ocrText
        ocrTextItems {
          processor
          text
          direction
          fontName
          width
          height
          transform
          hasEOL
          fontSize
          isNumeric
          isBold
          isItalic
          isUnderlined
          isMonospace
          isSerif
          isSmallcaps
          baseLine {
            x0
            y0
            x1
            y1
            hasBaseLine
          }
          bBox {
            x0
            y0
            x1
            y1
          }
        }
      }
      total
      hasMore
    }
  }
`;
export const VBillGetExpenseDisplayInfoDocument = gql`
  query VBillGetExpenseDisplayInfo($input: ExpenseDisplayInfoParamsInput!) {
    getExpenseDisplayInfo(input: $input) {
      organization {
        type
        key
        name
        expensePublicUrl
        token
      }
      company {
        type
        key
        name
        expensePublicUrl
        token
      }
    }
  }
`;
export const VBillGetExpenseTokenDocument = gql`
  query VBillGetExpenseToken($input: ExpenseTokenParamsInput!) {
    getExpenseToken(input: $input) {
      type
      key
      name
      expensePublicUrl
      token
    }
  }
`;
export const VBillGetStoreItemDocument = gql`
  query VBillGetStoreItem(
    $key: String!
    $type: StoreIoDataType!
    $organizationId: String!
    $source: String
    $companyId: String
  ) {
    getStoreItem(key: $key, type: $type, organizationId: $organizationId, source: $source, companyId: $companyId) {
      data {
        organizationId
        companyId
        key
        type
        label
        label2
        parentLabel
        source
        data {
          contact {
            name
            address
            addressList {
              type
              address
              address_line_2
              city
              country
              state
              zip_code
            }
            email
            emailList {
              type
              description
              email
            }
            phone
            phoneList {
              type
              description
              number
            }
          }
          glAccount {
            name
            accountNo
            type
          }
        }
        meta {
          label
          order
          value
        }
      }
    }
  }
`;
export const VBillInvoiceDocument = gql`
  query VBillInvoice($invoiceId: Int!) {
    invoice(invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillInvoicesDocument = gql`
  query VBillInvoices($pagination: InvoicePaginationInput!, $filters: InvoicesFiltersInput!) {
    invoices(pagination: $pagination, filters: $filters) {
      items {
        ...InvoiceListFragment
      }
      total
      hasMore
      statusStats {
        QUEUED {
          count
          amount
        }
        NEW {
          count
          amount
        }
        MAPPED {
          count
          amount
        }
        PENDING_APPROVAL {
          count
          amount
        }
        APPROVED {
          count
          amount
        }
        DECLINED {
          count
          amount
        }
        QF_ALL {
          count
          amount
        }
        QF_PENDING_REVIEW {
          count
          amount
        }
        QF_CODING {
          count
          amount
        }
        QF_APPROVAL {
          count
          amount
        }
        QF_ACTION_REQUIRED {
          count
          amount
        }
        QF_COMPLETED {
          count
          amount
        }
      }
      generalStats {
        count
        amount
      }
    }
  }
  ${InvoiceListFragmentFragmentDoc}
`;
export const VBillInvoicesStatsDocument = gql`
  query VBillInvoicesStats($pagination: InvoicePaginationInput, $filters: InvoicesFiltersInput!) {
    invoices(pagination: $pagination, filters: $filters) {
      statusStats {
        QUEUED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        NEW {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        MAPPED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        PENDING_APPROVAL {
          count
          amount
        }
        APPROVED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        DECLINED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_ALL {
          count
          amount
        }
        QF_PENDING_REVIEW {
          count
          amount
        }
        QF_CODING {
          count
          amount
        }
        QF_APPROVAL {
          count
          amount
        }
        QF_ACTION_REQUIRED {
          count
          amount
        }
        QF_COMPLETED {
          count
          amount
        }
      }
      generalStats {
        count
        amount
        withoutCompanyCount
        withCompanyCount
      }
    }
  }
`;
export const VBillOrganizationsInvoicesDocument = gql`
  query VBillOrganizationsInvoices($organizationIds: [String!]) {
    organizationsInvoices(organizationIds: $organizationIds) {
      organizationId
      organizationName
      generalStats {
        count
        amount
        withoutCompanyCount
        withCompanyCount
      }
      statusStats {
        QUEUED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        NEW {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        MAPPED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        PENDING_APPROVAL {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        APPROVED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        DECLINED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_ALL {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_PENDING_REVIEW {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_CODING {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_APPROVAL {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_ACTION_REQUIRED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        QF_COMPLETED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
      }
    }
  }
`;
export const VBillPaymentDocument = gql`
  query VBillPayment($paymentId: Int!, $organizationId: String!) {
    payment(paymentId: $paymentId, organizationId: $organizationId) {
      paymentId
      companyId
      paymentPushStatus
      paymentIntegrationStatus
      paymentAmount
      paymentDate
      submittedByUserAccount {
        userId
        organizationUserId
        userName
        userEmail
        userPhoneNumber
      }
      paymentFromGlAccount {
        key
        settingsKey
        storeType
        source
        label
        label2
        parentLabel
      }
      paymentToVendorAccount {
        key
        settingsKey
        storeType
        source
        label
        label2
        parentLabel
      }
      vCheckNumber
      vCheckId
      paymentExternalRef
      paymentExternalUrl
      batchId
      paymentError {
        message
        type
        codes
      }
      payableInvoices {
        invoiceId
        paymentAmount
        invoiceRelationType
        memo
      }
      integrationInfo {
        storeSource
        active
        type
      }
    }
  }
`;
export const VBillPaymentsDocument = gql`
  query VBillPayments(
    $organizationId: String!
    $pagination: PaymentPaginationInput
    $filters: PaymentListFiltersInput
  ) {
    payments(organizationId: $organizationId, pagination: $pagination, filters: $filters) {
      stats {
        generalStats {
          count
          amount
        }
        groupStats {
          groupingType
          value {
            ALL
            STATUS
          }
          count
          amount
          minDate
          maxDate
        }
      }
      items {
        paymentId
        companyId
        paymentPushStatus
        paymentIntegrationStatus
        paymentAmount
        paymentDate
        submittedByUserAccount {
          userId
          organizationUserId
          userName
          userEmail
          userPhoneNumber
        }
        paymentFromGlAccount {
          coreOrgId
          coreCompanyId
          key
          settingsKey
          storeType
          source
          label
          label2
          parentLabel
        }
        paymentToVendorAccount {
          coreOrgId
          coreCompanyId
          key
          settingsKey
          storeType
          source
          label
          label2
          parentLabel
        }
        vCheckNumber
        vCheckId
        paymentExternalRef
        paymentExternalUrl
        batchId
        paymentError {
          message
          type
          codes
        }
      }
      total
      hasMore
    }
  }
`;
export const VBillPaymentsStatsDocument = gql`
  query VBillPaymentsStats($organizationId: String!) {
    payments(organizationId: $organizationId) {
      stats {
        groupStats {
          groupingType
          value {
            ALL
            STATUS
          }
          count
          amount
          minDate
          maxDate
        }
      }
    }
  }
`;
export const VBillPushInvoiceDocument = gql`
  mutation VBillPushInvoice($invoiceId: Int!) {
    pushInvoice(invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillQueuedInvoicesDocument = gql`
  query VBillQueuedInvoices(
    $filters: InvoicesFiltersInput!
    $withInvoices: Boolean!
    $pagination: InvoicePaginationInput!
  ) {
    queuedInvoices(filters: $filters, pagination: $pagination) {
      items @include(if: $withInvoices) {
        ...InvoiceListFragment
      }
      total
      hasMore
      statusStats {
        QUEUED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        NEW {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        MAPPED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        APPROVED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        DECLINED {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
        PENDING_APPROVAL {
          count
          amount
          withoutCompanyCount
          withCompanyCount
        }
      }
      generalStats {
        count
        amount
        withoutCompanyCount
        withCompanyCount
      }
    }
  }
  ${InvoiceListFragmentFragmentDoc}
`;
export const VBillRelatedInvoicesDocument = gql`
  query VBillRelatedInvoices($invoiceId: Int!, $filters: RelatedInvoiceFiltersInput) {
    relatedInvoices(invoiceId: $invoiceId, filters: $filters) {
      invoice {
        ...InvoiceFragment
      }
      relation {
        fromInvoiceId
        toInvoiceId
        type
        status
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillMarkInvoiceAsDuplicateDocument = gql`
  mutation VBillMarkInvoiceAsDuplicate($invoiceId: Int!) {
    markInvoiceAsDuplicate(invoiceId: $invoiceId)
  }
`;
export const VBillSessionDocument = gql`
  query VBillSession {
    session {
      account {
        id
        username
        name
        email
      }
      hasPermissions
    }
  }
`;
export const VBillSettingsDocument = gql`
  query VBillSettings($companyId: String, $invoiceId: Int) {
    settings(companyId: $companyId, invoiceId: $invoiceId) {
      info {
        type
        storeSource
      }
      vBill {
        additionalMappings {
          invoice {
            label
            placeholder
            tooltip
            key
            showAs
            showIn
            displayKeys
            displayMetaKeys
            required
            isContact
            isGlAccount
            storeDataType
            dataSource
            defaultValue
            typeOfValue
            staticSelectList {
              settingsKey
              value
              typeOfValue
              label
              label2
              parentLabel
            }
          }
          invoiceValidator
          line {
            label
            placeholder
            tooltip
            key
            showAs
            showIn
            displayKeys
            displayMetaKeys
            required
            isContact
            isGlAccount
            storeDataType
            dataSource
            defaultValue
            typeOfValue
            staticSelectList {
              settingsKey
              value
              typeOfValue
              label
              label2
              parentLabel
            }
          }
          lineValidator
        }
      }
      capabilities {
        available
        enabled
      }
    }
  }
`;
export const VBillSplitInvoiceAttachmentsDocument = gql`
  mutation VBillSplitInvoiceAttachments($options: InvoiceSplitAttachmentsInput!) {
    splitInvoiceAttachments(options: $options) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillSplitInvoiceFileDocument = gql`
  mutation VBillSplitInvoiceFile($invoiceId: Int!, $fileId: Int!) {
    splitInvoiceFile(invoiceId: $invoiceId, fileId: $fileId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillStartApprovalForInvoiceDocument = gql`
  mutation VBillStartApprovalForInvoice($invoiceId: Int!) {
    startApprovalForInvoice(invoiceId: $invoiceId) {
      ...InvoiceFragment
      validationErrors {
        invoice {
          fields {
            required
            custom {
              fields
              message
            }
          }
          mappings {
            required
            custom {
              fields
              message
            }
          }
        }
        lineItems {
          index
          key
          glMapping {
            fields {
              required
              custom {
                fields
                message
              }
            }
            mappings {
              required
              custom {
                fields
                message
              }
            }
          }
          fields {
            required
            custom {
              fields
              message
            }
          }
        }
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillStoreItemsForCompanyDocument = gql`
  query VBillStoreItemsForCompany(
    $organizationId: String!
    $typeKeyIn: [StoreItemTypeKeyInput!]
    $type: StoreIoDataType
    $settingsKey: String
    $limit: Int
    $offset: Int
    $search: String
    $companyId: String
  ) {
    storeItemsForCompany(
      organizationId: $organizationId
      typeKeyIn: $typeKeyIn
      type: $type
      settingsKey: $settingsKey
      limit: $limit
      offset: $offset
      search: $search
      companyId: $companyId
    ) {
      organizationId
      companyId
      key
      type
      label
      label2
      parentLabel
      source
      data {
        contact {
          name
          address
          addressList {
            type
            address
            address_line_2
            city
            country
            state
            zip_code
          }
          email
          emailList {
            type
            description
            email
          }
          phone
          phoneList {
            type
            description
            number
          }
        }
        glAccount {
          name
          accountNo
          type
        }
      }
      meta {
        label
        order
        value
      }
    }
  }
`;
export const VBillUpdateInvoiceAndMappingsDocument = gql`
  mutation VBillUpdateInvoiceAndMappings(
    $skipAdditionalMappingsValidations: Boolean!
    $invoiceData: NullableInvoiceInput!
    $invoiceId: Int!
    $saveDraft: Boolean
  ) {
    updateInvoiceAndMappings(
      skipAdditionalMappingsValidations: $skipAdditionalMappingsValidations
      invoiceData: $invoiceData
      invoiceId: $invoiceId
      saveDraft: $saveDraft
    ) {
      ...InvoiceFragment
      validationErrors {
        invoice {
          fields {
            required
            custom {
              fields
              message
            }
          }
          mappings {
            required
            custom {
              fields
              message
            }
          }
        }
        lineItems {
          index
          key
          glMapping {
            fields {
              required
              custom {
                fields
                message
              }
            }
            mappings {
              required
              custom {
                fields
                message
              }
            }
          }
          fields {
            required
            custom {
              fields
              message
            }
          }
        }
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillUpdateInvoiceCompanyDocument = gql`
  mutation VBillUpdateInvoiceCompany($updateData: InvoiceUpdateCompanyInput!) {
    updateInvoiceCompany(updateData: $updateData) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillUploadInvoiceDocument = gql`
  mutation VBillUploadInvoice($input: UploadInvoiceInput!) {
    uploadInvoice(input: $input) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillValidateInvoiceDocument = gql`
  query VBillValidateInvoice($invoiceData: NullableInvoiceInput!, $invoiceId: Int!) {
    validateInvoice(invoiceData: $invoiceData, invoiceId: $invoiceId) {
      ...InvoiceFragment
      validationErrors {
        invoice {
          fields {
            required
            custom {
              fields
              message
            }
          }
          mappings {
            required
            custom {
              fields
              message
            }
          }
        }
        lineItems {
          index
          key
          glMapping {
            fields {
              required
              custom {
                fields
                message
              }
            }
            mappings {
              required
              custom {
                fields
                message
              }
            }
          }
          fields {
            required
            custom {
              fields
              message
            }
          }
        }
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export const VBillAddAllInvoicesToBatchDocument = gql`
  mutation VBillAddAllInvoicesToBatch($batchId: Int!, $billsFilters: BillsFiltersInput!) {
    addAllInvoicesToBatch(batchId: $batchId, billsFilters: $billsFilters) {
      status
      data {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        invoicesAmountInSubBatches
      }
      error
    }
  }
`;
export const VBillAddCompaniesToBatchDocument = gql`
  mutation VBillAddCompaniesToBatch($companyIds: [String!]!, $batchId: Int!) {
    addCompaniesToBatch(companyIds: $companyIds, batchId: $batchId) {
      data {
        items {
          id
          coreId
          createdByAccountId
          createdByOrgUserId
          batchId
        }
      }
      status
      error
    }
  }
`;
export const VBillAddInvoicesToBatchDocument = gql`
  mutation VBillAddInvoicesToBatch($batchInvoices: [BatchInvoiceInput!]!, $batchId: Int!) {
    addInvoicesToBatch(batchInvoices: $batchInvoices, batchId: $batchId) {
      status
      data {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDate
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        batchPathDetails {
          id
          name
          hasAccess
        }
        invoicesAmountInSubBatches
        batchPayments {
          batchId
          paymentId
          invoiceId
          paymentAmountForBillInBatch
          paymentPushStatus
          paymentIntegrationStatus
          paymentStatus
          paymentErrors {
            type
            messages
          }
        }
        batchTreeDetails {
          batchIds
          commonStats {
            batchesCount
          }
          paymentsStats {
            count
            amount
            groupingType
            value {
              ALL
              STATUS
            }
            minDate
            maxDate
          }
        }
      }
      error
    }
  }
`;
export const VBillAddMembersToBatchDocument = gql`
  mutation VBillAddMembersToBatch($memberIds: [String!]!, $batchId: Int!) {
    addMembersToBatch(memberIds: $memberIds, batchId: $batchId) {
      status
      data {
        items {
          id
          coreOrgUserId
          createdByAccountId
          createdByOrgUserId
          batchId
        }
      }
      error
    }
  }
`;
export const VBillCompaniesSettingsDocument = gql`
  query VBillCompaniesSettings($organizationId: String!, $companyIds: [String!]) {
    companiesSettings(organizationId: $organizationId, companyIds: $companyIds) {
      vBill {
        additionalMappings {
          invoice {
            label
            placeholder
            tooltip
            key
            showAs
            showIn
            required
            isContact
            isGlAccount
            storeDataType
          }
          invoiceValidator
          line {
            label
            placeholder
            tooltip
            key
            showAs
            showIn
            required
            isContact
            isGlAccount
            storeDataType
          }
          lineValidator
        }
      }
      capabilities {
        available
        enabled
      }
    }
  }
`;
export const VBillCompaniesSettingsEnabledCapabilitiesDocument = gql`
  query VBillCompaniesSettingsEnabledCapabilities($organizationId: String!, $companyIds: [String!]) {
    companiesSettings(organizationId: $organizationId, companyIds: $companyIds) {
      capabilities {
        enabled
      }
    }
  }
`;
export const VBillCreateBatchDocument = gql`
  mutation VBillCreateBatch($batchData: NullableCreateBatchInput!) {
    createBatch(batchData: $batchData) {
      data {
        id
        organizationId
        name
        budget
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        invoicesAmountInSubBatches
      }
      status
      error
    }
  }
`;
export const VBillDeleteBatchDocument = gql`
  mutation VBillDeleteBatch($batchId: Int!) {
    deleteBatch(batchId: $batchId) {
      status
      data {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDate
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        batchPathDetails {
          id
          name
          hasAccess
        }
        invoicesAmountInSubBatches
        batchPayments {
          batchId
          paymentId
          invoiceId
          paymentAmountForBillInBatch
          paymentPushStatus
          paymentIntegrationStatus
          paymentStatus
          paymentErrors {
            type
            messages
          }
        }
        batchTreeDetails {
          batchIds
          commonStats {
            batchesCount
          }
          paymentsStats {
            count
            amount
            groupingType
            value {
              ALL
              STATUS
            }
            minDate
            maxDate
          }
        }
      }
      error
    }
  }
`;
export const VBillGetBatchCompaniesDocument = gql`
  query VBillGetBatchCompanies($batchId: Int!) {
    getBatchCompanies(batchId: $batchId) {
      data {
        items {
          id
          coreId
          createdByAccountId
          createdByOrgUserId
          batchId
        }
      }
      status
      error
    }
  }
`;
export const VBillGetBatchListDocument = gql`
  query VBillGetBatchList(
    $pagination: BatchPaginationInput!
    $filters: BatchListFiltersInput!
    $grouping: BatchListStatsGroupingInput
  ) {
    getBatchList(pagination: $pagination, filters: $filters, grouping: $grouping) {
      status
      data {
        items {
          id
          organizationId
          name
          createdAt
          status
          budget
          batchDate
          batchDateDue
          batchFilters {
            organizationId
            companyIds
            ids
            contact_external_ref_ids
            search
            status
            pushStatus
            integrationStatus
            relativeReferenceDate {
              now
              customDate
              batchDate
            }
            relativeDateFrom {
              value
              unit
              temporality
            }
            relativeDateTo {
              value
              unit
              temporality
            }
            relativeDateDueFrom {
              value
              unit
              temporality
            }
            relativeDateDueTo {
              value
              unit
              temporality
            }
            date_unit_type
            date_unit_from
            date_unit_to
            date_unit_now
            date
            date_to
            date_due_from
            date_due_to
            amount_from
            amount_to
            free_amount_from
            free_amount_to
            vbill_number_sort
            status_sort
            amount_sort
            date_sort
            date_due_sort
            related_to_invoice_id
            mappings {
              key
              valuesIn {
                value
                source
              }
            }
            batch_id_in
          }
          createdByAccountId
          createdByOrgUserId
          createdByName
          createdByEmail
          createdByPhoneNumber
          invoicesAmount
          parentBatchId
          batchPath
          batchPathDetails {
            id
            name
            hasAccess
          }
          invoicesAmountInSubBatches
          batchPayments {
            batchId
            paymentId
            invoiceId
            paymentAmountForBillInBatch
            paymentPushStatus
            paymentIntegrationStatus
            paymentStatus
            paymentErrors {
              type
              messages
            }
          }
          batchTreeDetails {
            batchIds
            commonStats {
              batchesCount
            }
            paymentsStats {
              count
              amount
              groupingType
              value {
                ALL
                STATUS
              }
              minDate
              maxDate
            }
          }
        }
        total
        hasMore
        settings {
          BATCH_SUBBATCHES_ALLOWED
        }
      }
      error
    }
  }
`;
export const VBillGetBatchListStatsDocument = gql`
  query VBillGetBatchListStats($filters: BatchListFiltersInput!, $grouping: BatchListStatsGroupingInput) {
    getBatchListStats(filters: $filters, grouping: $grouping) {
      status
      data {
        generalStats {
          count
          amount
        }
        groupStats {
          count
          amount
          groupingType
          value {
            ALL
            BATCH_STATUS
            BATCH_PAYMENTS_STATUS
          }
          minDate
          maxDate
          minDateDue
          maxDateDue
        }
      }
      error
    }
  }
`;
export const VBillGetBatchMembersDocument = gql`
  query VBillGetBatchMembers($batchId: Int!) {
    getBatchMembers(batchId: $batchId) {
      status
      data {
        items {
          id
          coreOrgUserId
          createdByAccountId
          createdByOrgUserId
          batchId
        }
      }
      error
    }
  }
`;
export const VBillGetBatchTreeDocument = gql`
  query VBillGetBatchTree($organizationId: String!, $batchIds: [Float!]!, $parentBatchId: Float!) {
    batchData: getBatchList(
      pagination: { page: 1 }
      filters: { organizationId: $organizationId, batchIds: $batchIds }
    ) {
      data {
        items {
          id
          organizationId
          name
          budget
          batchDateDue
          batchFilters {
            organizationId
            companyIds
            ids
            contact_external_ref_ids
            search
            status
            pushStatus
            integrationStatus
            relativeReferenceDate {
              now
              customDate
              batchDate
            }
            relativeDateFrom {
              value
              unit
              temporality
            }
            relativeDateTo {
              value
              unit
              temporality
            }
            relativeDateDueFrom {
              value
              unit
              temporality
            }
            relativeDateDueTo {
              value
              unit
              temporality
            }
            date_unit_type
            date_unit_from
            date_unit_to
            date_unit_now
            date
            date_to
            date_due_from
            date_due_to
            amount_from
            amount_to
            free_amount_from
            free_amount_to
            vbill_number_sort
            status_sort
            amount_sort
            date_sort
            date_due_sort
            related_to_invoice_id
            mappings {
              key
              valuesIn {
                value
                source
              }
            }
            batch_id_in
          }
          createdByAccountId
          createdByOrgUserId
          createdByName
          createdByEmail
          createdByPhoneNumber
          invoicesAmount
          parentBatchId
          batchPath
          invoicesAmountInSubBatches
        }
        total
        hasMore
      }
      status
      error
    }
    batchChildren: getBatchList(
      pagination: { page: 1 }
      filters: { organizationId: $organizationId, parentBatchId: $parentBatchId }
    ) {
      data {
        items {
          id
        }
        total
      }
      status
      error
    }
  }
`;
export const VBillGetBillsDocument = gql`
  query VBillGetBills($pagination: InvoicePaginationInput!, $filters: BillsFiltersInput!) {
    getBills(pagination: $pagination, filters: $filters) {
      items {
        ...InvoiceListFragment
      }
      total
      hasMore
    }
  }
  ${InvoiceListFragmentFragmentDoc}
`;
export const VBillGetBillsStatsDocument = gql`
  query VBillGetBillsStats($filters: BillsFiltersInput!, $grouping: BillsStatsGroupingInput) {
    getBillsStats(filters: $filters, grouping: $grouping) {
      generalStats {
        count
        amount
        balance
      }
      groupStats {
        count
        amount
        value
        source
        label
        minDate
        maxDate
        minDateDue
        maxDateDue
      }
    }
  }
`;
export const VBillGetReactionsListDocument = gql`
  query VBillGetReactionsList($pagination: ReactionPaginationInput!, $filters: ReactionsFiltersInput!) {
    getReactionsList(pagination: $pagination, filters: $filters) {
      items {
        type
        changedByOrganizationUserId
        changedByAccountId
        changedByName
        changedByEmailAddress
      }
      total
      hasMore
    }
  }
`;
export const VBillGetReactionsStatsDocument = gql`
  query VBillGetReactionsStats($filters: [ReactionsFiltersInput!]!) {
    getReactionsStats(filters: $filters) {
      groups {
        parentType
        parentKey
        stats {
          type
          count
          includesMyReaction
        }
        includesMyReaction
      }
      total
      includesMyReaction
    }
  }
`;
export const VBillRemoveCompaniesFromBatchDocument = gql`
  mutation VBillRemoveCompaniesFromBatch($companyIds: [String!]!, $batchId: Int!) {
    removeCompaniesFromBatch(companyIds: $companyIds, batchId: $batchId) {
      data {
        items {
          id
          coreId
          createdByAccountId
          createdByOrgUserId
          batchId
        }
      }
      status
      error
    }
  }
`;
export const VBillRemoveInvoicesFromBatchDocument = gql`
  mutation VBillRemoveInvoicesFromBatch($invoiceIds: [Int!]!, $batchId: Int!) {
    removeInvoicesFromBatch(invoiceIds: $invoiceIds, batchId: $batchId) {
      status
      data {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDate
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        batchPathDetails {
          id
          name
          hasAccess
        }
        invoicesAmountInSubBatches
        batchPayments {
          batchId
          paymentId
          invoiceId
          paymentAmountForBillInBatch
          paymentPushStatus
          paymentIntegrationStatus
          paymentStatus
          paymentErrors {
            type
            messages
          }
        }
        batchTreeDetails {
          batchIds
          commonStats {
            batchesCount
          }
          paymentsStats {
            count
            amount
            groupingType
            value {
              ALL
              STATUS
            }
            minDate
            maxDate
          }
        }
      }
      error
    }
  }
`;
export const VBillRemoveMembersFromBatchDocument = gql`
  mutation VBillRemoveMembersFromBatch($memberIds: [String!]!, $batchId: Int!) {
    removeMembersFromBatch(memberIds: $memberIds, batchId: $batchId) {
      status
      data {
        items {
          id
          coreOrgUserId
          createdByAccountId
          createdByOrgUserId
          batchId
        }
      }
      error
    }
  }
`;
export const VBillStoreItemsForOrganizationDocument = gql`
  query VBillStoreItemsForOrganization(
    $limit: Int!
    $offset: Int!
    $type: StoreIoDataType!
    $organizationId: String!
    $companyIds: [String!]
    $search: String
    $keySourcePairs: [StoreKeySourcePair!]
  ) {
    storeItemsForOrganization(
      limit: $limit
      offset: $offset
      type: $type
      organizationId: $organizationId
      companyIds: $companyIds
      search: $search
      keySourcePairs: $keySourcePairs
    ) {
      organizationId
      companyId
      key
      type
      label
      label2
      parentLabel
      source
      data {
        contact {
          name
          address
          addressList {
            type
            address
            address_line_2
            city
            country
            state
            zip_code
          }
          email
          emailList {
            type
            description
            email
          }
          phone
          phoneList {
            type
            description
            number
          }
        }
        glAccount {
          name
          accountNo
          type
        }
      }
      meta {
        label
        order
        value
      }
    }
  }
`;
export const VBillUpdateBatchDocument = gql`
  mutation VBillUpdateBatch($batchData: NullableBatchInput!, $batchId: Int!) {
    updateBatch(batchData: $batchData, batchId: $batchId) {
      status
      data {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDate
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        batchPathDetails {
          id
          name
          hasAccess
        }
        invoicesAmountInSubBatches
        batchPayments {
          batchId
          paymentId
          invoiceId
          paymentAmountForBillInBatch
          paymentPushStatus
          paymentIntegrationStatus
          paymentStatus
          paymentErrors {
            type
            messages
          }
        }
        batchTreeDetails {
          batchIds
          commonStats {
            batchesCount
          }
          paymentsStats {
            count
            amount
            groupingType
            value {
              ALL
              STATUS
            }
            minDate
            maxDate
          }
        }
      }
      error
    }
  }
`;
export const VBillUpdateBatchStatusDocument = gql`
  mutation VBillUpdateBatchStatus($batchStatus: NullableBatchStatusInput!, $batchId: Int!) {
    updateBatchStatus(batchStatus: $batchStatus, batchId: $batchId) {
      status
      data {
        id
        organizationId
        name
        createdAt
        status
        budget
        batchDate
        batchDateDue
        batchFilters {
          organizationId
          companyIds
          ids
          contact_external_ref_ids
          search
          status
          pushStatus
          integrationStatus
          relativeReferenceDate {
            now
            customDate
            batchDate
          }
          relativeDateFrom {
            value
            unit
            temporality
          }
          relativeDateTo {
            value
            unit
            temporality
          }
          relativeDateDueFrom {
            value
            unit
            temporality
          }
          relativeDateDueTo {
            value
            unit
            temporality
          }
          date_unit_type
          date_unit_from
          date_unit_to
          date_unit_now
          date
          date_to
          date_due_from
          date_due_to
          amount_from
          amount_to
          free_amount_from
          free_amount_to
          vbill_number_sort
          status_sort
          amount_sort
          date_sort
          date_due_sort
          related_to_invoice_id
          mappings {
            key
            valuesIn {
              value
              source
            }
          }
          batch_id_in
        }
        createdByAccountId
        createdByOrgUserId
        createdByName
        createdByEmail
        createdByPhoneNumber
        invoicesAmount
        parentBatchId
        batchPath
        batchPathDetails {
          id
          name
          hasAccess
        }
        invoicesAmountInSubBatches
        batchPayments {
          batchId
          paymentId
          invoiceId
          paymentAmountForBillInBatch
          paymentPushStatus
          paymentIntegrationStatus
          paymentStatus
          paymentErrors {
            type
            messages
          }
        }
        batchTreeDetails {
          batchIds
          commonStats {
            batchesCount
          }
          paymentsStats {
            count
            amount
            groupingType
            value {
              ALL
              STATUS
            }
            minDate
            maxDate
          }
        }
      }
      error
    }
  }
`;
export const VBillUpsertReactionDocument = gql`
  mutation VBillUpsertReaction($type: ReactionType!, $parentKey: String!, $parentType: ReactionParentType!) {
    upsertReaction(type: $type, parentKey: $parentKey, parentType: $parentType) {
      type
      changedByOrganizationUserId
      changedByAccountId
      changedByName
      changedByEmailAddress
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    VBillAddComment(
      variables: IVBillVBillAddCommentMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillAddCommentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillAddCommentMutation>(VBillAddCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillAddComment",
        "mutation",
      );
    },
    VBillCheckInvoiceForDuplicates(
      variables: IVBillVBillCheckInvoiceForDuplicatesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCheckInvoiceForDuplicatesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCheckInvoiceForDuplicatesQuery>(VBillCheckInvoiceForDuplicatesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCheckInvoiceForDuplicates",
        "query",
      );
    },
    VBillCompanies(
      variables: IVBillVBillCompaniesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCompaniesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCompaniesQuery>(VBillCompaniesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCompanies",
        "query",
      );
    },
    VBillCompleteInvoiceMapping(
      variables: IVBillVBillCompleteInvoiceMappingMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCompleteInvoiceMappingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCompleteInvoiceMappingMutation>(VBillCompleteInvoiceMappingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCompleteInvoiceMapping",
        "mutation",
      );
    },
    VBillCompleteInvoiceOCR(
      variables: IVBillVBillCompleteInvoiceOcrMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCompleteInvoiceOcrMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCompleteInvoiceOcrMutation>(VBillCompleteInvoiceOcrDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCompleteInvoiceOCR",
        "mutation",
      );
    },
    VBillCopyInvoice(
      variables: IVBillVBillCopyInvoiceMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCopyInvoiceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCopyInvoiceMutation>(VBillCopyInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCopyInvoice",
        "mutation",
      );
    },
    VBillCreateBatchPayments(
      variables: IVBillVBillCreateBatchPaymentsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCreateBatchPaymentsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCreateBatchPaymentsMutation>(VBillCreateBatchPaymentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCreateBatchPayments",
        "mutation",
      );
    },
    VBillCreateBillsPayments(
      variables: IVBillVBillCreateBillsPaymentsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCreateBillsPaymentsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCreateBillsPaymentsMutation>(VBillCreateBillsPaymentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCreateBillsPayments",
        "mutation",
      );
    },
    VBillCreateExpenseReport(
      variables: IVBillVBillCreateExpenseReportMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCreateExpenseReportMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCreateExpenseReportMutation>(VBillCreateExpenseReportDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCreateExpenseReport",
        "mutation",
      );
    },
    VBillDeleteInvoices(
      variables: IVBillVBillDeleteInvoicesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillDeleteInvoicesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillDeleteInvoicesMutation>(VBillDeleteInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillDeleteInvoices",
        "mutation",
      );
    },
    VBillFilesInvoice(
      variables: IVBillVBillFilesInvoiceQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillFilesInvoiceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillFilesInvoiceQuery>(VBillFilesInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillFilesInvoice",
        "query",
      );
    },
    VBillFilesOcr(
      variables: IVBillVBillFilesOcrQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillFilesOcrQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillFilesOcrQuery>(VBillFilesOcrDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillFilesOcr",
        "query",
      );
    },
    VBillGetExpenseDisplayInfo(
      variables: IVBillVBillGetExpenseDisplayInfoQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetExpenseDisplayInfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetExpenseDisplayInfoQuery>(VBillGetExpenseDisplayInfoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetExpenseDisplayInfo",
        "query",
      );
    },
    VBillGetExpenseToken(
      variables: IVBillVBillGetExpenseTokenQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetExpenseTokenQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetExpenseTokenQuery>(VBillGetExpenseTokenDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetExpenseToken",
        "query",
      );
    },
    VBillGetStoreItem(
      variables: IVBillVBillGetStoreItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetStoreItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetStoreItemQuery>(VBillGetStoreItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetStoreItem",
        "query",
      );
    },
    VBillInvoice(
      variables: IVBillVBillInvoiceQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillInvoiceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillInvoiceQuery>(VBillInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillInvoice",
        "query",
      );
    },
    VBillInvoices(
      variables: IVBillVBillInvoicesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillInvoicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillInvoicesQuery>(VBillInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillInvoices",
        "query",
      );
    },
    VBillInvoicesStats(
      variables: IVBillVBillInvoicesStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillInvoicesStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillInvoicesStatsQuery>(VBillInvoicesStatsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillInvoicesStats",
        "query",
      );
    },
    VBillOrganizationsInvoices(
      variables?: IVBillVBillOrganizationsInvoicesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillOrganizationsInvoicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillOrganizationsInvoicesQuery>(VBillOrganizationsInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillOrganizationsInvoices",
        "query",
      );
    },
    VBillPayment(
      variables: IVBillVBillPaymentQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillPaymentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillPaymentQuery>(VBillPaymentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillPayment",
        "query",
      );
    },
    VBillPayments(
      variables: IVBillVBillPaymentsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillPaymentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillPaymentsQuery>(VBillPaymentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillPayments",
        "query",
      );
    },
    VBillPaymentsStats(
      variables: IVBillVBillPaymentsStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillPaymentsStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillPaymentsStatsQuery>(VBillPaymentsStatsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillPaymentsStats",
        "query",
      );
    },
    VBillPushInvoice(
      variables: IVBillVBillPushInvoiceMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillPushInvoiceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillPushInvoiceMutation>(VBillPushInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillPushInvoice",
        "mutation",
      );
    },
    VBillQueuedInvoices(
      variables: IVBillVBillQueuedInvoicesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillQueuedInvoicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillQueuedInvoicesQuery>(VBillQueuedInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillQueuedInvoices",
        "query",
      );
    },
    VBillRelatedInvoices(
      variables: IVBillVBillRelatedInvoicesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillRelatedInvoicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillRelatedInvoicesQuery>(VBillRelatedInvoicesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillRelatedInvoices",
        "query",
      );
    },
    VBillMarkInvoiceAsDuplicate(
      variables: IVBillVBillMarkInvoiceAsDuplicateMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillMarkInvoiceAsDuplicateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillMarkInvoiceAsDuplicateMutation>(VBillMarkInvoiceAsDuplicateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillMarkInvoiceAsDuplicate",
        "mutation",
      );
    },
    VBillSession(
      variables?: IVBillVBillSessionQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillSessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillSessionQuery>(VBillSessionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillSession",
        "query",
      );
    },
    VBillSettings(
      variables?: IVBillVBillSettingsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillSettingsQuery>(VBillSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillSettings",
        "query",
      );
    },
    VBillSplitInvoiceAttachments(
      variables: IVBillVBillSplitInvoiceAttachmentsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillSplitInvoiceAttachmentsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillSplitInvoiceAttachmentsMutation>(VBillSplitInvoiceAttachmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillSplitInvoiceAttachments",
        "mutation",
      );
    },
    VBillSplitInvoiceFile(
      variables: IVBillVBillSplitInvoiceFileMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillSplitInvoiceFileMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillSplitInvoiceFileMutation>(VBillSplitInvoiceFileDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillSplitInvoiceFile",
        "mutation",
      );
    },
    VBillStartApprovalForInvoice(
      variables: IVBillVBillStartApprovalForInvoiceMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillStartApprovalForInvoiceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillStartApprovalForInvoiceMutation>(VBillStartApprovalForInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillStartApprovalForInvoice",
        "mutation",
      );
    },
    VBillStoreItemsForCompany(
      variables: IVBillVBillStoreItemsForCompanyQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillStoreItemsForCompanyQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillStoreItemsForCompanyQuery>(VBillStoreItemsForCompanyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillStoreItemsForCompany",
        "query",
      );
    },
    VBillUpdateInvoiceAndMappings(
      variables: IVBillVBillUpdateInvoiceAndMappingsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillUpdateInvoiceAndMappingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillUpdateInvoiceAndMappingsMutation>(
            VBillUpdateInvoiceAndMappingsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "VBillUpdateInvoiceAndMappings",
        "mutation",
      );
    },
    VBillUpdateInvoiceCompany(
      variables: IVBillVBillUpdateInvoiceCompanyMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillUpdateInvoiceCompanyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillUpdateInvoiceCompanyMutation>(VBillUpdateInvoiceCompanyDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillUpdateInvoiceCompany",
        "mutation",
      );
    },
    VBillUploadInvoice(
      variables: IVBillVBillUploadInvoiceMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillUploadInvoiceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillUploadInvoiceMutation>(VBillUploadInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillUploadInvoice",
        "mutation",
      );
    },
    VBillValidateInvoice(
      variables: IVBillVBillValidateInvoiceQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillValidateInvoiceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillValidateInvoiceQuery>(VBillValidateInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillValidateInvoice",
        "query",
      );
    },
    VBillAddAllInvoicesToBatch(
      variables: IVBillVBillAddAllInvoicesToBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillAddAllInvoicesToBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillAddAllInvoicesToBatchMutation>(VBillAddAllInvoicesToBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillAddAllInvoicesToBatch",
        "mutation",
      );
    },
    VBillAddCompaniesToBatch(
      variables: IVBillVBillAddCompaniesToBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillAddCompaniesToBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillAddCompaniesToBatchMutation>(VBillAddCompaniesToBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillAddCompaniesToBatch",
        "mutation",
      );
    },
    VBillAddInvoicesToBatch(
      variables: IVBillVBillAddInvoicesToBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillAddInvoicesToBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillAddInvoicesToBatchMutation>(VBillAddInvoicesToBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillAddInvoicesToBatch",
        "mutation",
      );
    },
    VBillAddMembersToBatch(
      variables: IVBillVBillAddMembersToBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillAddMembersToBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillAddMembersToBatchMutation>(VBillAddMembersToBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillAddMembersToBatch",
        "mutation",
      );
    },
    VBillCompaniesSettings(
      variables: IVBillVBillCompaniesSettingsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCompaniesSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCompaniesSettingsQuery>(VBillCompaniesSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCompaniesSettings",
        "query",
      );
    },
    VBillCompaniesSettingsEnabledCapabilities(
      variables: IVBillVBillCompaniesSettingsEnabledCapabilitiesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCompaniesSettingsEnabledCapabilitiesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCompaniesSettingsEnabledCapabilitiesQuery>(
            VBillCompaniesSettingsEnabledCapabilitiesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "VBillCompaniesSettingsEnabledCapabilities",
        "query",
      );
    },
    VBillCreateBatch(
      variables: IVBillVBillCreateBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillCreateBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillCreateBatchMutation>(VBillCreateBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillCreateBatch",
        "mutation",
      );
    },
    VBillDeleteBatch(
      variables: IVBillVBillDeleteBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillDeleteBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillDeleteBatchMutation>(VBillDeleteBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillDeleteBatch",
        "mutation",
      );
    },
    VBillGetBatchCompanies(
      variables: IVBillVBillGetBatchCompaniesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBatchCompaniesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBatchCompaniesQuery>(VBillGetBatchCompaniesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBatchCompanies",
        "query",
      );
    },
    VBillGetBatchList(
      variables: IVBillVBillGetBatchListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBatchListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBatchListQuery>(VBillGetBatchListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBatchList",
        "query",
      );
    },
    VBillGetBatchListStats(
      variables: IVBillVBillGetBatchListStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBatchListStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBatchListStatsQuery>(VBillGetBatchListStatsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBatchListStats",
        "query",
      );
    },
    VBillGetBatchMembers(
      variables: IVBillVBillGetBatchMembersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBatchMembersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBatchMembersQuery>(VBillGetBatchMembersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBatchMembers",
        "query",
      );
    },
    VBillGetBatchTree(
      variables: IVBillVBillGetBatchTreeQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBatchTreeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBatchTreeQuery>(VBillGetBatchTreeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBatchTree",
        "query",
      );
    },
    VBillGetBills(
      variables: IVBillVBillGetBillsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBillsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBillsQuery>(VBillGetBillsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBills",
        "query",
      );
    },
    VBillGetBillsStats(
      variables: IVBillVBillGetBillsStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetBillsStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetBillsStatsQuery>(VBillGetBillsStatsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetBillsStats",
        "query",
      );
    },
    VBillGetReactionsList(
      variables: IVBillVBillGetReactionsListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetReactionsListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetReactionsListQuery>(VBillGetReactionsListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetReactionsList",
        "query",
      );
    },
    VBillGetReactionsStats(
      variables: IVBillVBillGetReactionsStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillGetReactionsStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillGetReactionsStatsQuery>(VBillGetReactionsStatsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillGetReactionsStats",
        "query",
      );
    },
    VBillRemoveCompaniesFromBatch(
      variables: IVBillVBillRemoveCompaniesFromBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillRemoveCompaniesFromBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillRemoveCompaniesFromBatchMutation>(
            VBillRemoveCompaniesFromBatchDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "VBillRemoveCompaniesFromBatch",
        "mutation",
      );
    },
    VBillRemoveInvoicesFromBatch(
      variables: IVBillVBillRemoveInvoicesFromBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillRemoveInvoicesFromBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillRemoveInvoicesFromBatchMutation>(VBillRemoveInvoicesFromBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillRemoveInvoicesFromBatch",
        "mutation",
      );
    },
    VBillRemoveMembersFromBatch(
      variables: IVBillVBillRemoveMembersFromBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillRemoveMembersFromBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillRemoveMembersFromBatchMutation>(VBillRemoveMembersFromBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillRemoveMembersFromBatch",
        "mutation",
      );
    },
    VBillStoreItemsForOrganization(
      variables: IVBillVBillStoreItemsForOrganizationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillStoreItemsForOrganizationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillStoreItemsForOrganizationQuery>(VBillStoreItemsForOrganizationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillStoreItemsForOrganization",
        "query",
      );
    },
    VBillUpdateBatch(
      variables: IVBillVBillUpdateBatchMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillUpdateBatchMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillUpdateBatchMutation>(VBillUpdateBatchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillUpdateBatch",
        "mutation",
      );
    },
    VBillUpdateBatchStatus(
      variables: IVBillVBillUpdateBatchStatusMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillUpdateBatchStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillUpdateBatchStatusMutation>(VBillUpdateBatchStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillUpdateBatchStatus",
        "mutation",
      );
    },
    VBillUpsertReaction(
      variables: IVBillVBillUpsertReactionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IVBillVBillUpsertReactionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IVBillVBillUpsertReactionMutation>(VBillUpsertReactionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "VBillUpsertReaction",
        "mutation",
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
