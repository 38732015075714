import { normalizeIsoDateOnMidnight } from "common/helpers/dates";
import { CHDecimal } from "common/helpers/decimal";
import { IVBillInvoiceIncomingStatus, IVBillLineItemSchema, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { TAdditionalMappings, TVBillFormFields, TVBillFormLineFields } from "./types";

export const ENABLE_COMPUTED_LINE_ITEM_AMOUNTS = Boolean(
  Number(import.meta.env.VITE_APP_ENABLE_COMPUTED_LINE_ITEM_AMOUNTS),
);

export const VBILL_ENABLE_SUGGESTIONS_SELECTOR = Boolean(Number(window.env?.VBILL_ENABLE_SUGGESTIONS_SELECTOR));

export const __IS_ADMIN_VBILL__ = Boolean(Number(import.meta.env.VITE_APP_IS_ADMIN_VBILL));

export const vBillSuggestionsConfidenceQSKey = "sconf";

export function getVBillRouteRedirectByStatus(
  organizationId: string,
  vbillId: string,
  search: string,
  status?: IVBillInvoiceIncomingStatus,
) {
  const leadingPath = `/org/${organizationId}/vbill/${vbillId}`;
  let newPath = leadingPath;

  switch (status) {
    case IVBillInvoiceIncomingStatus.Queued:
      newPath += "/incoming";
      break;
    case IVBillInvoiceIncomingStatus.New:
      newPath += "/new";
      break;
    case IVBillInvoiceIncomingStatus.Mapped:
    case IVBillInvoiceIncomingStatus.Approved:
    case IVBillInvoiceIncomingStatus.Declined:
    case IVBillInvoiceIncomingStatus.Deleted:
    case IVBillInvoiceIncomingStatus.History:
    case IVBillInvoiceIncomingStatus.PendingApproval:
      newPath += "/summary";
      break;
    default:
      newPath += "/incoming";
  }

  if (!!search.length) {
    newPath += search;
  }

  return newPath;
}

export function getVBillFormIncomingDefaultValues(
  invoice?: IVBillVBillInvoiceQuery["invoice"],
): TVBillFormFields | undefined {
  if (!invoice) {
    return;
  }

  const suggestedMainFile =
    invoice.files?.find((file) => `${file.id}` === invoice.suggestedMainFileId) ?? invoice.files?.[0];
  const suggestedInvoiceData = suggestedMainFile?.metadata?.GDAI;
  const savedInvoiceData = invoice;
  const currentAmount = isNil(savedInvoiceData.amount)
    ? `${suggestedInvoiceData?.amount ?? "0"}`
    : `${savedInvoiceData.amount ?? "0"}`;

  return {
    amount: currentAmount,
    invoiceDate: isNil(savedInvoiceData.invoiceDate)
      ? isNil(suggestedInvoiceData?.invoiceDate)
        ? ""
        : normalizeIsoDateOnMidnight(suggestedInvoiceData.invoiceDate)
      : normalizeIsoDateOnMidnight(savedInvoiceData.invoiceDate),
    invoiceDateDue: isNil(savedInvoiceData.invoiceDateDue)
      ? isNil(suggestedInvoiceData?.invoiceDateDue)
        ? ""
        : normalizeIsoDateOnMidnight(suggestedInvoiceData.invoiceDateDue)
      : normalizeIsoDateOnMidnight(savedInvoiceData.invoiceDateDue),
    invoiceNumber: isNil(savedInvoiceData.invoiceNumber)
      ? suggestedInvoiceData?.invoiceNumber ?? ""
      : savedInvoiceData.invoiceNumber ?? "",
    senderName: isNil(savedInvoiceData.senderName)
      ? suggestedInvoiceData?.senderName ?? ""
      : savedInvoiceData.senderName ?? "",
    senderEmail: isNil(savedInvoiceData.senderEmail)
      ? suggestedInvoiceData?.senderEmail ?? ""
      : savedInvoiceData.senderEmail ?? "",
    senderAddress: isNil(savedInvoiceData.senderAddress)
      ? suggestedInvoiceData?.senderAddress ?? ""
      : savedInvoiceData.senderAddress ?? "",
    senderPhone: isNil(savedInvoiceData.senderPhone)
      ? suggestedInvoiceData?.senderPhone ?? ""
      : savedInvoiceData.senderPhone ?? "",
    mainFile: isNil(savedInvoiceData.mainFile)
      ? suggestedMainFile
        ? { text: suggestedMainFile.name ?? "", value: suggestedMainFile.id }
        : null
      : { text: savedInvoiceData.mainFile.name ?? "", value: savedInvoiceData.mainFile.id ?? 0 },
    hasLineItems: isNil(savedInvoiceData.hasLineItems) ? false : savedInvoiceData.hasLineItems, // false by default
    lineItems: savedInvoiceData.lineItems?.length
      ? savedInvoiceData.lineItems.map((lineItem) => lineItemToFormLineFields(lineItem))
      : suggestedInvoiceData?.lineItems?.length
        ? suggestedInvoiceData.lineItems.map((lineItem) => lineItemMetaToFormLineFields(lineItem))
        : [{ ...emptyOCRFormLineFields, amount: currentAmount }],
    companyId: invoice?.companyId ?? "",
  };
}

export function getVBillFormNewDefaultValues(
  invoice?: IVBillVBillInvoiceQuery["invoice"],
): TVBillFormFields | undefined {
  if (!invoice) {
    return;
  }

  const currentAdditionalMappings = invoiceAdditionalMappingsToFormFields(invoice.additionalMappings);
  const currentLineItems: TVBillFormLineFields[] = (invoice.lineItems ?? []).map((lineItem) => ({
    description: !isNil(lineItem.description) ? lineItem.description : emptyOCRFormLineFields.description,
    lineItemId: !isNil(lineItem.id) ? `${lineItem.id}` : "",
    qty: lineItem.qty?.length ? lineItem.qty : emptyOCRFormLineFields.qty,
    rate: lineItem.rate?.length ? lineItem.rate : emptyOCRFormLineFields.rate,
    amount: lineItem.amount?.length ? lineItem.amount : emptyOCRFormLineFields.amount,
    ...lineItemAdditionalMappingsToFormLineFields(lineItem),
  }));

  return {
    amount: invoice.amount ?? "",
    invoiceDate: invoice.invoiceDate ? normalizeIsoDateOnMidnight(invoice.invoiceDate) : "",
    invoiceDateDue: invoice.invoiceDateDue ? normalizeIsoDateOnMidnight(invoice.invoiceDateDue) : "",
    invoiceNumber: invoice.invoiceNumber ?? "",
    senderName: invoice.senderName ?? "",
    senderEmail: invoice.senderEmail ?? "",
    senderAddress: invoice.senderAddress ?? "",
    senderPhone: invoice.senderPhone ?? "",
    companyId: invoice.companyId ?? "",
    mainFile: invoice.mainFile ? { text: invoice.mainFile.name ?? "", value: invoice.mainFile.id } : null,
    hasLineItems: invoice.hasLineItems,
    lineItems: currentLineItems,
    ...currentAdditionalMappings,
  };
}

export const invoiceAdditionalMappingsToFormFields = (
  additionalMappings: IVBillVBillInvoiceQuery["invoice"]["additionalMappings"],
) =>
  (additionalMappings ?? [])
    .map(({ label, label2, parentLabel, type, value, key }) => ({
      addMappingSettingKey: key,
      key: value,
      type: type,
      label: label ?? "",
      label2: label2 ?? "",
      parentLabel: parentLabel ?? "",
    }))
    .reduce<TAdditionalMappings>((prev, curr) => ({ ...prev, [curr.type ?? ""]: curr }), {});

export const lineItemAdditionalMappingsToFormLineFields = (lineItem?: IVBillLineItemSchema) =>
  (lineItem?.glMappings?.[0]?.additionalMappings ?? [])
    .map(({ label, label2, parentLabel, type, value, key }) => ({
      addMappingSettingKey: key,
      key: value,
      type: type,
      label: label ?? "",
      label2: label2 ?? "",
      parentLabel: parentLabel ?? "",
    }))
    .reduce<TAdditionalMappings>((prev, curr) => ({ ...prev, [curr.type ?? ""]: curr }), {});

const getLineItemAmountsComputed = ({
  qty,
  rate = "1",
  amount,
}: Pick<IVBillLineItemSchema, "qty" | "amount" | "rate">) => {
  const qtyDecimal = new CHDecimal(qty ?? "1");
  const qtyFixed = qtyDecimal.decimalPlaces() === 0 ? qtyDecimal.toString() : qtyDecimal.toFixed(2);
  const rateDecimal = new CHDecimal(rate ?? "1");
  const rateFixed = rateDecimal.decimalPlaces() === 0 ? rateDecimal.toString() : rateDecimal.toFixed(2);
  const amountDecimal = new CHDecimal(amount ?? "1");
  const amountFixed = amountDecimal.decimalPlaces() === 0 ? amountDecimal.toString() : amountDecimal.toFixed(2);

  if (isNil(qty)) {
    const qtyDecimal = new CHDecimal(amountFixed).dividedBy(rateFixed);

    return {
      qty: qtyDecimal.decimalPlaces() === 0 ? qtyDecimal.toString() : qtyDecimal.toFixed(2),
      amount: amountFixed,
      rate: rateFixed,
    };
  }

  if (isNil(amount)) {
    const amountDecimal = new CHDecimal(qtyFixed).times(rateFixed);

    return {
      amount: amountDecimal.decimalPlaces() === 0 ? amountDecimal.toString() : amountDecimal.toFixed(2),
      rate: rateFixed,
      qty: qtyFixed,
    };
  }

  const defaultQtyDecimal = new CHDecimal(amountFixed).dividedBy(rateFixed);

  return {
    qty: defaultQtyDecimal.decimalPlaces() === 0 ? defaultQtyDecimal.toString() : defaultQtyDecimal.toFixed(2),
    amount: amountFixed,
    rate: rateFixed,
  };
};

const getLineItemAmountsDefault = ({ qty, rate, amount }: Pick<IVBillLineItemSchema, "qty" | "amount" | "rate">) => {
  // should match emptyOCRFormLineFields
  const amountDecimal = new CHDecimal(amount ?? emptyOCRFormLineFields.amount);

  return {
    qty: qty ?? emptyOCRFormLineFields.qty,
    rate: rate ?? emptyOCRFormLineFields.rate,
    amount: amountDecimal.decimalPlaces() === 0 ? amountDecimal.toString() : amountDecimal.toFixed(2),
  };
};

export const lineItemToFormLineFields = (lineItem: IVBillLineItemSchema): TVBillFormLineFields => ({
  description: !isNil(lineItem.description) ? lineItem.description : emptyOCRFormLineFields.description,
  lineItemId: !isNil(lineItem.id) ? `${lineItem.id}` : emptyOCRFormLineFields.lineItemId,
  ...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS ? getLineItemAmountsComputed(lineItem) : getLineItemAmountsDefault(lineItem)),
});

type TLineItemMetaToFormLineFieldsInput = NonNullable<
  NonNullable<NonNullable<IVBillVBillInvoiceQuery["invoice"]["files"]>[number]["metadata"]>["GDAI"]
>["lineItems"][number];

export const lineItemMetaToFormLineFields = (lineItem: TLineItemMetaToFormLineFieldsInput): TVBillFormLineFields => ({
  description: !isNil(lineItem.description?.value)
    ? `${lineItem.description?.value}`
    : emptyOCRFormLineFields.description,
  lineItemId: emptyOCRFormLineFields.lineItemId,
  ...(ENABLE_COMPUTED_LINE_ITEM_AMOUNTS
    ? getLineItemAmountsComputed({
        amount: !isNil(lineItem.amount?.value) ? `${lineItem.amount.value}` : "1",
        qty: !isNil(lineItem.qty?.value) ? `${lineItem.qty.value}` : null,
        rate: !isNil(lineItem.rate?.value) ? `${lineItem.rate.value}` : null,
      })
    : getLineItemAmountsDefault({
        amount: !isNil(lineItem.amount?.value) ? `${lineItem.amount.value}` : emptyOCRFormLineFields.amount,
        qty: !isNil(lineItem.qty?.value) ? `${lineItem.qty.value}` : emptyOCRFormLineFields.qty,
        rate: !isNil(lineItem.rate?.value) ? `${lineItem.rate.value}` : emptyOCRFormLineFields.rate,
      })),
});

export const emptyOCRFormLineFields: Pick<
  TVBillFormLineFields,
  "description" | "qty" | "rate" | "amount" | "lineItemId"
> = {
  description: "",
  qty: "1",
  rate: "0",
  amount: "0",
  lineItemId: "",
};

export const invoiceDateMoreThenFieldErrorMsg = "Bill Due should be more then Bill Date.";

export const mappingFieldShowAsCheckBoxCheckedValue = "1";
