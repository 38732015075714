import { format } from "date-fns";
import { processJsDateToIsoOnMidnight } from "./dates";
export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    currencyDisplay: "narrowSymbol",
  }).format(amount);
}
export function getPastTense(verb: string) {
  var exceptions = {
    are: "were",
    eat: "ate",
    go: "went",
    have: "had",
    inherit: "inherited",
    is: "was",
    run: "ran",
    sit: "sat",
    visit: "visited",
    Reminder: "reminded",
  };
  // @ts-ignore
  if (exceptions[verb]) {
    // @ts-ignore
    return exceptions[verb];
  }
  if (/e$/i.test(verb)) {
    return verb + "d";
  }
  if (/[aeiou]c/i.test(verb)) {
    return verb + "ked";
  }
  // for american english only
  if (/el$/i.test(verb)) {
    return verb + "ed";
  }
  if (/[aeio][aeiou][dlmnprst]$/.test(verb)) {
    return verb + "ed";
  }
  if (/[aeiou][bdglmnprst]$/i.test(verb)) {
    return verb.replace(/(.+[aeiou])([bdglmnprst])/, "$1$2$2ed");
  }
  return verb + "ed";
}
export const chatMessageMentionTemplateTest = /(\{\{[^}]+\}\})/g;

// start of day
// TODO: remove, is deprecated
export const getSODAsUTCIsoString = (date: Date) => processJsDateToIsoOnMidnight(date.toString(), true);

//Date Format to YYYY-MM-DD
export const formatDateYYYYMMDD = (date: Date) => {
  return format(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), "yyyy-MM-dd");
};

export const getDateAsUTC = (dateString: string) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const formatDateAsUTC = (date: Date, formatString: string) => {
  return format(
    new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ),
    formatString,
  );
};

// taken from https://stackoverflow.com/a/43467144/7349880
export const isValidHttpUrl = (val: string) => {
  let url;

  try {
    url = new URL(val);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const randomUUID = () => {
  // crypto.randomUUID() only over https
  if (import.meta.env.MODE === "development") {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
  }

  return window.crypto.randomUUID();
};

export const generateRandomNumber = (min: number, max: number) => (Math.random() * (max - min) + min).toFixed(2);
