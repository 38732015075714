import { format, isValid } from "date-fns";

// Dates V2

// 2024-01-01T02:00:00
// to 2024-01-01T00:00:00 | 2024-01-01T00:00:00Z
// on midnight (start of day)
export const normalizeIsoDateOnMidnight = (dateStr: string, utc?: boolean) => {
  // TODO: maybe i should just check for /^\d{4}-\d{2}-\d{2}$/
  const dateFormats = [
    /^\d{4}-\d{2}-\d{2}$/, // isoFormat "2022-11-22"
    // /^\d{2}\/\d{2}\/\d{4}$/, // usFormat "11/22/2022"
    // /^\d{2}\/\d{2}\/\d{2}$/, // usFormat "11/22/22"
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, // "2024-01-01T02:00:00.000Z"
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/, // "2024-01-01T02:00:00Z"
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, // "2024-01-01 02:00:00"
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/, // "2024-01-01T02:00:00"
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([+-]\d{2}:\d{2})?$/, // "2024-01-01T02:00:00-02:00"
  ];

  for (const format of dateFormats) {
    if (format.test(dateStr)) {
      return `${dateStr.slice(0, 10)}T00:00:00${utc ? "Z" : ""}`;
    }
  }

  // If no formats match
  return "";
};

// Fri Aug 09 2024 14:34:20 GMT+0300 (Eastern European Summer Time)
// to 2024-08-09T00:00:00 | 2024-08-09T00:00:00Z
// on midnight (start of day)
export const processJsDateToIsoOnMidnight = (dateStr: string, utc?: boolean) => {
  const localDate = new Date(dateStr);

  // If no formats match
  if (!isValid(localDate)) {
    return "-";
  }

  return format(localDate, `yyyy-MM-dd'T'00:00:00${utc ? "'Z'" : ""}`);
};

export const formatIsoDateOnMidnight = (dateStr: string, formatStr: string) => {
  const normalizedIsoDateOnMidnight = normalizeIsoDateOnMidnight(dateStr);

  if (normalizedIsoDateOnMidnight === "") {
    return "";
  }

  return format(new Date(normalizedIsoDateOnMidnight), formatStr);
};
